import { DefAjax } from '../service/ajax.js'
import Api from '@/api/Api.js'

/**
 * 通过区域code和基地id查询基地列表
 * @param {String,Number} ret.areaCode
 * @param {String} ret.cropCode
 * @returns
 */
export function Info_companyBaseList(ret) {
  let def = {
    areaCode: 5227,
    companyBaseIds: ''
  }
  let opt = Object.assign({}, def, ret)
  return DefAjax({
    method: 'get',
    url: '/source/api/dp/ids/companyBaseList',
    params: opt
  })
}

/**
 * @获取轮廓
 */
export function InfoMap_adminDivi(ret) {
  let areaCode = ret.areaCode
  if (areaCode == Api.areaCode) {
    let areaJson = require('@/assets/json/areaJson/' + areaCode + '/area.json')
    return new Promise((suc) => {
      suc(areaJson)
    })
  } else {
    return DefAjax({
      morePost: true,
      method: 'get',
      url: '/api-gis/DataService/getChildArea',
      params: { areaCode: areaCode }
    })
  }
}

/**
 * @获取当前areaCode的外轮廓
 */
export function InfoMap_adminDiviOuter(ret) {
  let areaCode = ret.areaCode
  if (areaCode == Api.areaCode) {
    let areaJson = require('@/assets/json/areaJson/' +
      areaCode +
      '/areaOuter.json')
    return new Promise((suc) => {
      suc(areaJson)
    })
  } else {
    return DefAjax({
      morePost: true,
      method: 'post',
      url: '/api-gis/DataService/queryAreaGeo',
      params: { areaCode: areaCode }
    })
  }
}

/**/

export function InfoMap_marker(ret) {
  let def = { regionName: '中国' }
  let opt = Object.assign({}, def, ret)
  return DefAjax({
    url: '/api/brand/map/data',
    method: 'get',
    params: opt
    //load: 'mock'
  })
}

/**
 * @获取内轮廓
 */
export function InfoMap_geojsonFeaturesInner(postData) {
  let areaJson = require('@/assets/json/qiannan-featurs-inner.json')
  return new Promise((suc) => {
    DefAjax({
      postType: 'formData',
      url: '/source/api/gis/shp/queryChildAreaGeo',
      method: 'post',
      data: postData
    })
      .then((ret) => {
        if (ret.success) {
          suc(ret.data)
        } else {
          suc(areaJson)
        }
      })
      .catch(() => {
        suc(areaJson)
      })
  })
}

/**
 * @获取外轮廓
 */
export function InfoMap_geojsonFeaturesOuter(postData) {
  let areaJson = require('@/assets/json/qiannan-featurs-outer.json')
  return new Promise((suc) => {
    DefAjax({
      postType: 'formData',
      url: '/source/api/gis/shp/queryAreaGeo',
      method: 'post',
      data: postData
    })
      .then((ret) => {
        if (ret.success) {
          suc(ret.data)
        } else {
          suc(areaJson)
        }
      })
      .catch(() => {
        suc(areaJson)
      })
  })
}

/**
 * @获取园区外轮廓
 */
export function InfoMap_geojsonGardenOuter() {
  let areaJson = require('@/assets/json/garden-chachang-outer.json')
  return new Promise((suc) => {
    suc(areaJson)
    /*DefAjax({
      morePost: true,
      url: '/WaterService/api/BasinService/queryBasinList',
      method: 'get'
    })
      .then((ret) => {
        if (ret.success) {
          suc(ret)
        } else {
          suc(areaJson)
        }
      })
      .catch(() => {
        suc(areaJson)
      })*/
  })
}

/**
 * 通过产品id查询分布图
 * @param {String} ret.id
 * @returns
 */
export function Info_technologyList(ret) {
  let def = {
    companyBaseId: '',
    varieties: ''
  }
  let opt = Object.assign({}, def, ret)
  return DefAjax({
    method: 'get',
    url: '/source/api/wx/product/product/technology/list',
    params: opt
  })
}

/**
 * 通过id查询点信息
 * @param {String} ret.id
 * @returns
 */
export function Info_markerInfo(ret) {
  let def = {
    id: ''
  }
  let opt = Object.assign({}, def, ret)
  return DefAjax({
    method: 'get',
    url: '/source/api/point/config/content/info',
    params: opt
  })
}
