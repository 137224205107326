// 获取原有属性 ie dom元素.currentStyle 火狐谷歌 window.getComputedStyle(dom元素, null);
export const getStyle = (function () {
  if (document.body.currentStyle) {
    // 在ie下兼容写法
    return (dom, attr) => dom.currentStyle[attr]
  }
  return (dom, attr) => getComputedStyle(dom, null)[attr]
})()

export function getDomPosition(Dom) {
  let styL = getStyle(Dom, 'left')
  // 为兼容ie
  if (styL === 'auto') styL = '0px'
  let styT = getStyle(Dom, 'top')
  // console.log(styL)
  // 注意在ie中 第一次获取到的值为组件自带50% 移动之后赋值为px
  if (styL.includes('%')) {
    styL = +document.body.clientWidth * (+styL.replace(/%/g, '') / 100)
    styT = +document.body.clientHeight * (+styT.replace(/%/g, '') / 100)
  } else {
    // 获取到的值带px 正则匹配替换
    styL = +styL.replace(/px/g, '')
    styT = +styT.replace(/px/g, '')
  }
  return { left: styL, top: styT }
}

export function getDomMaxPosition(dragDom) {
  const screenWidth = document.documentElement.clientWidth // body当前宽度
  const screenHeight = document.documentElement.clientHeight // 可见区域高度(应为body高度，可某些环境下无法获取)

  const dragDomWidth = dragDom.offsetWidth // 对话框宽度
  const dragDomheight = dragDom.offsetHeight // 对话框高度

  const minDragDomLeft = dragDom.offsetLeft
  const maxDragDomLeft =
    screenWidth - dragDom.getBoundingClientRect().left - dragDomWidth
  const minDragDomTop = dragDom.offsetTop
  const maxDragDomTop =
    screenHeight - dragDom.getBoundingClientRect().top - dragDomheight
  return {
    left: [minDragDomLeft, maxDragDomLeft],
    top: [minDragDomTop, maxDragDomTop]
  }
}
/**
 * 边界处理
 * @param {Number} ret.left //能拖动的Dom的left值
 * @param {Number} ret.top //能拖动的Dom的top值
 * @param {Array} limitPosition.left //数组，能拖动的最小值和最大值：[0,100]
 * @param {Array} limitPosition.top //数组，能拖动的最小值和最大值：[0,100]
 * @returns
 */
export function resizeLimitPosition(ret, limitPosition) {
  if (-ret.left > limitPosition.left[0]) {
    ret.left = -limitPosition.left[0]
  } else if (ret.left > limitPosition.left[1]) {
    ret.left = limitPosition.left[1]
  }
  if (-ret.top > limitPosition.top[0]) {
    ret.top = -limitPosition.top[0]
  } else if (ret.top > limitPosition.top[1]) {
    ret.top = limitPosition.top[1]
  }
  return ret
}
