<template>
  <div class="m-view-login">
    <div class="user-info" v-if="userInfo.name">
      <div class="name">
        您好，<span class="user-label">{{ userInfo.name }}</span>
      </div>
      <div class="other">
        <div v-if="false" class="button-manage button-text" @click="EventAdmin">
          【运营管理】
        </div>
        <div class="button-logout button-text" @click="EventLogout">
          【注销】
        </div>
      </div>
    </div>
    <form class="user-login" v-if="!userInfo.name">
      <div class="username text-wrap">
        <el-input v-model="userName" placeholder="请输入用户名" />
      </div>
      <div class="password text-wrap">
        <el-input
          v-model="password"
          type="password"
          placeholder="请输入密码"
          show-password
        />
      </div>
      <div class="other">
        <UiButton @click="EventClick">登录</UiButton>
      </div>
    </form>
  </div>
</template>
<script>
import { Info_login, Info_logout } from '@/info/infoUser.js'
import cookieCache from '@/utils/cookie'
export default {
  log: '',
  components: {},
  inject: ['reload'],
  props: {},
  data() {
    return {
      userName: 'admin',
      password: 'nqzx@2022@nqzx'
    }
  },
  watch: {},
  computed: {
    userInfo() {
      return this.$store.state.user.userInfo
    }
  },
  mounted() {},
  methods: {
    getForm() {
      return {
        userName: this.userName,
        password: this.password
      }
    },
    EventClick() {
      this.login()
    },
    login() {
      const form = this.getForm()
      Info_login(form).then((ret) => {
        if (ret.token) {
          cookieCache.delete('token')
          cookieCache.set('token', ret.token)
          this.$store.commit('user/setUserInfo', false)
          this.$store.dispatch('user/Action_userInfo')
          this.reload()
        } else {
          this.Api.msg(ret.msg)
        }
      })
    },
    EventLogout() {
      this.Api.alert('要退出登录吗？', (bool) => {
        if (bool) {
          Info_logout().then((ret) => {
            if (ret.success) {
              cookieCache.delete('token')
              this.$store.commit('user/setUserInfo', false)
              this.reload()
            }
          })
        }
      })
    },
    EventAdmin() {
      window.open(this.Api.getMGPath())
    }
  }
}
</script>
<style lang="scss" scoped>
.m-view-login {
  display: flex;
  align-items: center;
  font-size: 14px;
  .erwei-lump {
    flex-shrink: 0;
    width: 40px;
    height: 40px;
    margin-right: 20px;
    img {
      width: 100%;
    }
  }
  .user-info {
    display: flex;
    align-items: center;
    .name {
      margin-right: 10px;
    }
    .button-text {
      color: #499ef3;
      cursor: pointer;

      &:hover {
        color: #79bbff;
      }
    }
    .other {
      display: flex;
    }
  }
  .user-login {
    display: flex;
    .text-wrap {
      width: 150px;
      margin-right: 10px;
    }
    ::v-deep(.el-input__wrapper) {
      background: rgba(14, 29, 52, 0.9);
      .el-input__inner {
        color: #fff;
      }
    }
  }
}
.view-erwei {
  width: 100%;
  display: block;
}
</style>
