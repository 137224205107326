<template>
  <div class="box-service-product">
    <LayoutBoxTitle title="服务产品" class="mgb-15"></LayoutBoxTitle>
    <div
      class="service-product-main"
      @mouseenter="EventMouseenter"
      @mouseleave="EventMouseleave"
      v-show="nav.options?.length"
      v-loading="loading"
      element-loading-text="数据加载中..."
    >
      <CpnLabelRadio
        :options="nav.options"
        v-model="nav.value"
        @select="getData_cropProductType"
      ></CpnLabelRadio>
      <CpnIconTab
        ref="RCpnIconTab"
        :list="service.list"
        v-model="service.value"
        @change="EventChange_tab"
      ></CpnIconTab>
      <div class="lump-box" v-show="service.list?.length">
        <ScrollMoreServiceProduct
          ref="RScrollMoreServiceProduct"
          @initEnd="EventInitEnd"
        ></ScrollMoreServiceProduct>
      </div>
      <div v-if="!service.list?.length" class="ui-no-data">暂无产品数据</div>
    </div>
  </div>
</template>
<script>
import LayoutBoxTitle from '@/components/view/layout/LayoutBoxTitle.vue'
import CpnIconTab from '@/components/view/cpn/CpnIconTab.vue'
import CpnLabelRadio from '@/components/content/labelRadio/CpnLabelRadio.vue'
import ScrollMoreServiceProduct from './boxServiceProduct/ScrollMoreServiceProduct.vue'
import { Info_cropProductType } from '@/info/infoHome.js'
import { Info_dictOption } from '@/info/info.js'
export default {
  inject: ['AppRoot'],
  components: {
    LayoutBoxTitle,
    CpnIconTab,
    CpnLabelRadio,
    ScrollMoreServiceProduct
  },
  props: {},
  data() {
    return {
      loading: false,
      service: {
        list: [],
        value: ''
      },
      dict: {
        1: {
          name: '灾害',
          icon: 'icon-cb cb-qixiangzaihai2',
          sort: 3
        },
        2: {
          name: '农事活动',
          icon: 'icon-cb nongshihuodong',
          sort: 2
        },
        3: {
          name: '生长适宜度',
          sort: 1,
          icon: 'icon-cb shengchangshiyidu'
        },
        4: {
          name: '动态产量',
          icon: 'icon-cb dongtaichanliang',
          disabled: true,
          sort: 60
        },
        5: {
          name: '低温冻害',
          icon: 'icon-cb donghai',
          disabled: false,
          sort: 40
        },
        6: {
          name: '病害',
          icon: 'icon-cb ganhanjiance',
          disabled: false,
          sort: 50
        },
        7: {
          name: '虫害',
          icon: 'icon-cb nongyeqixiangbingchonghaibaogao',
          disabled: false,
          sort: 60
        },
        9: {
          name: '专题',
          icon: 'icon-cb renwuguanli',
          disabled: false,
          sort: 4
        },
        10: {
          name: '气象预报',
          icon: 'icon-cb yubaotianqifenxi',
          disabled: false,
          sort: 70
        },
        11: {
          name: '相似年景分析',
          icon: 'icon-cb niandugongzuojihua',
          disabled: false,
          sort: 80
        },
        12: {
          name: '气候适宜性区划',
          icon: 'icon-cb shengchangshiyidu',
          sort: 3
        },
        13: {
          name: '灾害',
          icon: 'icon-cb cb-qixiangzaihai2',
          sort: 3
        },
        14: {
          name: '溯源',
          icon: 'icon-cb shengchangsuyuan',
          sort: 4
        }
      },
      news: {
        list: [],
        currentIndex: ''
      },
      nav: {
        options: [],
        value: ''
      }
    }
  },
  watch: {
    'AppRoot.varietiesId': {
      handler() {
        this.getData_cropProductType()
      }
    }
  },
  computed: {},
  mounted() {
    this.getData_firstType()
  },
  methods: {
    getData_firstType() {
      Info_dictOption({
        dictKey: 'product_pre_type'
      }).then((ret) => {
        this.nav.options = []
        for (let item of ret.data) {
          this.nav.options.push({
            name: item.label,
            value: item.value
          })
        }
        this.nav.value = this.nav.options[1].value
        this.getData_cropProductType()
      })
    },
    getData_cropProductType() {
      if (this.AppRoot.varietiesId) {
        this.loading = true
        Info_cropProductType({
          areaCode: this.AppRoot.areaCode,
          varieties: this.AppRoot.varietiesId,
          preType: this.nav.value
        })
          .then((ret) => {
            this.loading = false
            if (this.AppRoot.varietiesId !== ret.KOReq.params.varieties) {
              return
            }
            let list = []
            this.service.value = ''
            if (ret?.data?.length) {
              for (let item of ret.data) {
                let obj = {
                  name: item.typeName,
                  value: item.type,
                  icon: this.dict[item.type]?.icon,
                  disabled: this.dict[item.type]?.disabled,
                  sort: this.dict[item.type]?.sort
                }
                list.push(obj)
              }
              list = list.sort((a, b) => {
                return a.sort - b.sort
              })
              this.service.value = list[0].value
            }
            this.service.list = list
            this.getData_cropProductList()
          })
          .catch((err) => {
            this.loading = false
            console.log(err)
          })
      } else {
        this.service.list = []
        this.service.value = ''
      }
    },
    getData_cropProductList() {
      this.$refs.RScrollMoreServiceProduct?.init({
        type: this.service.value,
        areaCode: this.AppRoot.areaCode,
        varieties: this.AppRoot.varietiesId,
        preType: this.nav.value
      })
    },
    EventChange_tab() {
      this.news.currentIndex = 0
      this.getData_cropProductList()
    },
    EventInitEnd() {
      this.$refs.RCpnIconTab?.slideAutoPlay()
    },
    EventMouseenter() {
      this.$refs.RCpnIconTab?.stopAutoPlay()
    },
    EventMouseleave() {
      this.$refs.RCpnIconTab?.slideAutoPlay()
    }
  }
}
</script>
<style lang="scss" scoped>
.box-service-product {
  padding: 10px 0;
  background: var(--ko-body-bg);
  .lump-box {
    margin: 10px 15px 0;
    height: 200px;
    padding: 20px 5px 20px 15px;
    box-sizing: border-box;
    background: rgba(0, 0, 0, 0.2);
    overflow: hidden;
  }
}
</style>
