<template>
  <ul class="cpn-label-radio">
    <li
      v-for="item in options"
      :key="item"
      :class="item.value == modelValue ? 'current' : ''"
      @click="EventClick(item)"
    >
      <div class="name">{{ item.name }}</div>
    </li>
  </ul>
</template>
<script>
export default {
  log: '',
  components: {},
  props: {
    options: {
      type: Array,
      default: () => []
    },
    modelValue: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    return {}
  },
  watch: {},
  computed: {},
  mounted() {},
  methods: {
    EventClick(item) {
      this.$emit('update:modelValue', item.value)
      this.$emit('select', item)
    }
  }
}
</script>
<style lang="scss" scoped>
.cpn-label-radio {
  width: 60%;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 10px;
  display: flex;
  flex-wrap: wrap;
  li {
    flex-basis: 30.5%;
    margin-right: 4%;
    cursor: pointer;
    .name {
      padding: 10px 0;
      text-align: center;
      border-radius: 3px;
    }
    &:nth-child(3n) {
      margin-right: 0;
    }
    &.current {
      .name {
        color: #0ff;
        background: url('@/assets/img/tab-bg.png') center bottom/contain
          no-repeat;
      }
    }
  }
}
</style>
