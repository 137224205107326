<template>
  <div class="cpn-suit-long">
    <div class="suit-key" v-if="false">
      <h2 class="suit-h">偏低<i class="icon icon-zhidi iconfont"></i></h2>
      <h3 class="suit-h">适宜<i class="icon icon-zhengque2 iconfont"></i></h3>
      <h4 class="suit-h">偏高<i class="icon icon-zhiding iconfont"></i></h4>
    </div>
    <div class="suit-value" v-for="item in suits" :key="item.name">
      <div class="name">{{ item.name }}</div>
      <div class="cont">
        <div class="long">
          <div
            v-for="index in 3"
            :key="index"
            :class="'i' + index"
            class="suit-i"
          >
            <div
              v-if="item.suitType == index"
              class="value"
              :style="getStyle(item)"
            >
              {{ Api.keep2(item.value, 1) + item.unit }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  inject: [],
  components: {},
  props: {
    suits: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {}
  },
  watch: {},
  computed: {},
  mounted() {
    this.initData()
  },
  methods: {
    initData() {},
    getStyle(item) {
      return { left: item.pt * 100 + '%' }
    }
  }
}
</script>
<style lang="scss" scoped>
.cpn-suit-long {
  padding: 0 20px 0 10px;
  --color-suit-1: #0174aa;
  --color-suit-2: #00baba;
  --color-suit-3: #c32d3b;
  .suit-key {
    display: box;
    display: flex;
    padding-left: 70px;
    margin: 10px 0;
    font-size: 14px;
    .suit-h {
      width: 0;
      flex-grow: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }
    h2 {
      color: var(--color-suit-1);
      .icon {
        background: #003455;
      }
    }
    h3 {
      color: var(--color-suit-2);
      .icon {
        background: #038ea0;
        color: #0ff;
      }
    }
    h4 {
      color: var(--color-suit-3);
      .icon {
        background: #65253d;
      }
    }
    .icon {
      height: 20px;
      width: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: 5px;
      border-radius: 50%;
      font-size: 12px;
    }
  }
  .suit-value {
    display: flex;
    align-items: center;
    padding: 25px 0 5px;
    .name {
      width: 70px;
      flex-shrink: 0;
      color: #fff;
      margin-right: 4px;
      font-size: 14px;
      text-align: center;
    }
    .cont {
      position: relative;
      width: 0;
      flex-grow: 1;
      .long {
        position: relative;
        height: 6px;
        background: #025a65;
        border-radius: 10px;
        .suit-i {
          position: absolute;
          content: '';
          display: block;
          width: 30%;
          top: 0;
          bottom: 0;
          z-index: 3;
          &.i1 {
            left: 0;
            background: var(--color-suit-1);
            border-top-left-radius: 10px;
            border-bottom-left-radius: 10px;
          }
          &.i2 {
            left: 30%;
            width: 40%;
            background: var(--color-suit-2);
          }
          &.i3 {
            left: 70%;
            background: var(--color-suit-3);
            border-top-right-radius: 10px;
            border-bottom-right-radius: 10px;
          }
        }
      }
      .value {
        position: absolute;
        left: 10%;
        bottom: 130%;
        width: 100px;
        transform: translate(-50%, 0);
        text-align: center;
        color: #00ffff;
        line-height: 2;
        z-index: 5;
        font-size: 12px;
        &:after {
          content: '';
          display: block;
          position: absolute;
          left: 50%;
          top: 100%;
          margin-left: -7px;
          margin-top: -4px;
          border-width: 7px;
          display: block;
          width: 0;
          height: 0;
          border-style: solid;
          border-color: #fff transparent transparent transparent;
        }
      }
    }
  }
}
</style>
