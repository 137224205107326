<template>
  <EChartPieArea
    v-drag="true"
    v-if="MapRoot.mapImageIsogram?.legend?.length"
    class="layer-chart-pie lump-2"
  ></EChartPieArea>
</template>
<script>
import EChartPieArea from './layerChartPie/EChartPieArea.vue'
export default {
  inject: ['MapRoot'],
  components: { EChartPieArea },
  props: {},
  data() {
    return {}
  },
  watch: {},
  computed: {},
  mounted() {},
  methods: {}
}
</script>
<style lang="scss" scoped>
.layer-chart-pie {
  width: 270px;
  padding: 0 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.3);
  background: var(--ko-body-bg);
}
</style>
