<template>
  <div class="box-inner box-handle">
    <el-popover
      popper-class="is-dark map-popover-layer"
      placement="top"
      :width="200"
      trigger="click"
      @show="EventVisible(true)"
      @hide="EventVisible()"
    >
      <template #reference
        ><div class="handle-button" :class="popShow ? 'current' : ''">
          <i class="icon icon-tuceng icon-lh"></i>图层
        </div></template
      >
      <template #default>
        <div v-if="options.length">
          <div class="title">
            <div class="name">图层叠加</div>
          </div>
          <ul class="cont">
            <li
              v-for="item in options"
              :key="item"
              :class="modelValue.includes(item.value) ? 'current' : ''"
              @click="EventClick(item)"
            >
              <div
                class="icon iconfont"
                :class="
                  modelValue.includes(item.value)
                    ? 'icon-xuanzhong1'
                    : 'icon-weixuanzhong1'
                "
              ></div>
              <div class="name">{{ item.name }}</div>
            </li>
          </ul>
        </div>
        <div v-else class="ui-no-data">暂无图层</div>
      </template>
    </el-popover>
  </div>
</template>
<script>
export default {
  log: '',
  components: {},
  inject: ['MapRoot'],
  props: {
    options: {
      type: Array,
      default: () => []
    },
    modelValue: {
      type: Array,
      default: () => []
    }
  },
  data() {
    return {
      popShow: false
    }
  },
  watch: {},
  computed: {},
  mounted() {},
  methods: {
    EventClick(item) {
      let selectedVals = this.modelValue
      let ind = selectedVals.indexOf(item.value)
      if (ind >= 0) {
        selectedVals.splice(ind, 1)
      } else {
        selectedVals.push(item.value)
      }
      this.$emit('update:modelValue', selectedVals)
    },
    EventVisible(bool) {
      this.popShow = bool
    }
  }
}
</script>
<style lang="scss">
.map-popover-layer {
  .el-radio {
    padding: 5px 0;
  }
}
</style>
<style lang="scss" scoped>
.m-popover-layer {
  i.icon {
    margin-right: 5px;
    font-size: 16px;
    display: inline-block;
  }
}
.handle-button {
  display: flex;
  align-items: center;
  justify-content: center;
  i {
    margin-right: 5px;
  }
}
.map-popover-layer {
  .title {
    margin-bottom: 10px;
    display: flex;
    color: var(--el-text-color-regular);
    .name {
      flex-grow: 1;
    }
    .other {
      flex-shrink: 0;
      color: #999;
      cursor: pointer;
      &:hover {
        color: #bbb;
      }
    }
  }
  .cont {
    li {
      display: flex;
      align-items: center;
      font-size: 14px;
      cursor: pointer;
      padding: 5px 10px;
      color: var(--el-text-color-regular);
      .icon {
        margin-right: 8px;
        font-size: 16px;
      }
      &.current {
        color: #0ff;
      }
      &:hover {
        background: rgba(255, 255, 255, 0.1);
      }
    }
  }
}
</style>
