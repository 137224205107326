<template>
  <div
    class="scroll-more-price-list"
    v-loading="loading"
    element-loading-text="数据加载中..."
    element-loading-background="rgba(0, 0, 0, 0.4)"
  >
    <PriceList
      v-if="list?.length"
      :list="list"
      @scrollEnd="EventScrollEnd"
    ></PriceList>
    <div v-else class="ui-no-data">暂无数据</div>
  </div>
</template>
<script>
import PriceList from '@/components/content/list/PriceList.vue'
import { Info_cropPrice } from '@/info/infoHome.js'
export default {
  inject: ['MapRoot', 'AppRoot'],
  components: { PriceList },
  props: {},
  data() {
    return {
      list: [],
      currentIndex: '-1',
      loading: false,
      params: {},
      pageNo: 1,
      pageSize: 10
    }
  },
  watch: {
    'AppRoot.varietiesId': {
      handler() {
        this.init()
      },
      immediate: true
    }
  },
  computed: {},
  mounted() {},
  methods: {
    init() {
      //初始化插件 ScrollMore并加载
      this.list = []
      this.loading = true
      this.FnSM_getData({
        cropCode: this.AppRoot.cropCode
      }).then((ret) => {
        this.loading = false
        this.EventSM_setList(ret)
      })
    },
    FnSM_getData(opt) {
      //把事件传入scrollMore组件内
      return new Promise((resolve) => {
        this.loading = true
        Info_cropPrice({
          ...opt,
          pageNo: this.pageNo,
          pageSize: this.pageSize
        }).then((ret) => {
          this.loading = false
          let list = []
          if (ret.page?.list?.length) {
            for (let item of ret.page.list) {
              list.push({
                name: item.varietiesName,
                city: item.market,
                date: item.datetime,
                price: item.prices,
                unit: item.unit ? item.unit : '元/公斤'
              })
            }
          } else {
            list = []
          }
          resolve({ list: list, count: ret.total })
        })
      })
    },
    EventSM_setList(ret) {
      this.list.push(...ret.list)
    },
    EventScrollEnd() {
      this.pageNo++
      this.loading = true
      this.FnSM_getData().then((ret) => {
        this.loading = false
        this.EventSM_setList(ret)
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.scroll-more-price-list {
  position: relative;
  height: 100%;
}
</style>
