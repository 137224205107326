<template>
  <el-dropdown class="m-select-text" trigger="hover">
    <div class="m-handler-text" type="text">
      切换
      <i class="icon icon-xiajiantou iconfont"></i>
    </div>
    <template #dropdown>
      <div class="m-select-area">
        <OptionAreas
          :list="option"
          :value="value"
          @select="EventSelect"
        ></OptionAreas>
      </div>
    </template>
  </el-dropdown>
</template>
<script>
import OptionAreas from './selectText/OptionAreas.vue'
export default {
  components: { OptionAreas },
  props: {
    option: {
      type: Array,
      default: () => {
        return []
      }
    },
    value: {
      type: [String, Number],
      default: () => {
        return ''
      }
    }
  },
  data() {
    return {}
  },
  watch: {},
  computed: {
    currentItem() {
      for (let item of this.option) {
        console.log(item, this.value)
        if (item && item.value == this.value) {
          return item
        }
      }
      return { name: '切换' }
    }
  },
  mounted() {},
  methods: {
    EventSelect(item) {
      this.$emit('change', item)
      this.$emit('update:value', item.value)
    }
  }
}
</script>
<style scoped lang="scss">
.m-handler-text {
  cursor: pointer;
  color: #fff;
  &:not(.is-disabled):hover {
    color: #0ff;
    border-color: transparent;
    background-color: transparent;
  }
  &:focus-visible {
    outline: 0 solid #fff;
    outline-offset: 0;
  }
}
.m-select-area {
  padding: 5px 20px;
  max-width: 400px;
}
</style>
