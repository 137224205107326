import { KOAjax } from './koajax.js'
import Api from '@/api/Api'

export function DefAjax(param) {
  if (process.env.NODE_ENV === 'development') {
    param.baseURL = '/ko'
  } else {
    param.load = 'newUrl'
    param.baseURL = Api.getHostPath()
  }
  return KOAjax(param)
}

export function WeaAjax(param) {
  if (process.env.NODE_ENV === 'development') {
    param.baseURL = '/ko'
  } else {
    param.load = 'newUrl'
    param.baseURL = Api.weaHost
  }
  return KOAjax(param)
}
