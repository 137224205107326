<template>
  <div class="m-popup-base">
    <div class="box">
      <div class="row-1"><KOimg :src="img"></KOimg></div>
      <div class="row-2">
        <div class="title">
          <div class="name">{{ data.name }}</div>
        </div>
        <div class="cont">
          <i class="icon icon-home3 iconfont"></i>
          {{ data.areaAddress }}
        </div>
      </div>
      <div class="row-line"></div>
      <div class="row-3">
        <div ref="RButton" class="btn j-btn-garden" link type="primary">
          进入
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  inject: ['MapRoot'],
  components: {},
  props: {
    data: {
      type: Object,
      default: () => {}
    }
  },
  data() {
    return {
      testImg: require('@/assets/img/test/pic-1.jpg')
    }
  },
  watch: {},
  computed: {
    img() {
      if (this.data?.logos) {
        return this.Api.host + this.data?.logos
      } else {
        return ''
      }
    }
  },
  mounted() {},
  methods: {}
}
</script>
<style lang="scss" scoped>
.m-popup-base {
  .box {
    display: flex;
    align-items: center;
    padding: 15px 0 15px 15px;
    .row-1 {
      margin-right: 10px;
      flex-shrink: 0;
    }
    .row-2 {
      flex-grow: 1;
      margin-right: 10px;
      .title {
        font-size: 14px;
        font-weight: bold;
        color: #d9e5fa;
        margin-bottom: 15px;
        display: flex;
        align-items: center;
        .name {
          word-break: keep-all;
          white-space: nowrap;
        }
      }
    }
    .cont {
      line-height: 1.6;
      word-break: keep-all;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-width: 400px;
      .icon {
        margin-right: 2px;
        display: inline-block;
      }
    }
    .row-line {
      margin-left: 10px;
      height: 50px;
      border-left: 1px solid rgba(255, 255, 255, 0.2);
    }
    .row-3 {
      width: 90px;
      flex-shrink: 0;
      display: flex;
      justify-content: center;
      .btn {
        padding: 0 20px;
        color: #0ff;
        font-size: 14px;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
  .ko-img {
    width: 80px;
    height: 60px;
    border-radius: 3px;
    margin-right: 8px;
  }
}
</style>
