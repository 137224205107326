// 2.根据process.env.NODE_ENV区分
// 开发环境: development
// 生成环境: production
// 测试环境: test

let BASE_URL
const TIME_OUT = 60000

if (process.env.NODE_ENV === 'development') {
  BASE_URL = process.env.VUE_APP_GATEWAY_SERVICE
} else if (process.env.NODE_ENV === 'production') {
  BASE_URL = process.env.VUE_APP_GATEWAY_SERVICE
}

export { BASE_URL, TIME_OUT }
