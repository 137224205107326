<template>
  <ul class="m-home-expert-list">
    <li
      v-for="(item, index) in list"
      :key="item"
      :class="currentIndex == index ? 'current' : ''"
      @click="EventClick(index)"
    >
      <div class="lump-1">
        <div class="row-1">
          <KOimg :src="Api.host + item.photo" />
        </div>
        <div class="row-2">
          <div class="r1">
            <div class="office">{{ item.office }}</div>
            <div class="name">{{ item.expert }}</div>
          </div>
          <div class="r2">
            <div class="m1">
              <div class="appellation">{{ item.designation }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="lump-2">
        <div class="expert-content">
          <div class="cont" v-html="replaceBr(item.content)"></div>
          <div class="date">
            发布时间：{{ item.dateTime?.Format('YYYY-MM-DD hh:mm') }}
          </div>
        </div>
      </div>
    </li>
  </ul>
</template>
<script>
import { Regn2Br } from '@/plug/regular/regular.js'
export default {
  log: '',
  components: {},
  props: {
    list: {
      type: Array,
      default: () => []
    },
    currentIndex: {
      type: [String, Number],
      default: -1
    }
  },
  data() {
    return {}
  },
  watch: {},
  computed: {},
  mounted() {},
  methods: {
    EventClick(index) {
      this.$emit('update:currentIndex', index)
      this.$emit('change', this.list[index])
    },
    replaceBr(str) {
      return Regn2Br(str)
    }
  }
}
</script>
<style lang="scss" scoped>
.m-home-expert-list {
  height: 100%;
  li {
    height: 100%;
    padding: 0 20px;
    box-sizing: border-box;
    margin-bottom: 15px;
    display: flex;
    flex-direction: column;
    .lump-1 {
      flex-shrink: 0;
      display: flex;
      align-items: center;
      margin-bottom: 10px;
      .row-2 {
        flex-grow: 1;
        .r1 {
          font-size: 16px;
          display: flex;
          align-items: center;
          margin-bottom: 10px;
          color: #c8d6e9;
          .office {
            margin-right: 10px;
          }
          .name {
            flex-shrink: 0;
          }
        }
        .r2 {
          display: flex;
          align-items: center;
          .m1 {
            flex-grow: 1;
          }
        }
      }
    }
    .lump-2 {
      height: 10px;
      padding: 10px 0;
      flex-grow: 1;
      box-sizing: border-box;
    }
    &.current {
      color: #0ff;
    }
    &:last-child {
      margin-bottom: 0;
    }
    .ko-img {
      width: 60px;
      height: 60px;
      margin-right: 15px;
      border-radius: 50%;
    }
    .company {
      color: #8797b1;
    }
    .appellation {
      display: inline-block;
      font-size: 14px;
      color: #fff;
      border-radius: 20px;
    }
    .expert-content {
      position: relative;
      text-align: justify;
      height: 100%;
      box-sizing: border-box;
      line-height: 2;
      padding: 10px 15px;
      background: rgba(0, 0, 0, 0.2);
      overflow: auto;
      &::after {
        position: absolute;
        top: -17px;
        left: 22px;
        content: '';
        display: block;
        width: 0;
        height: 0;
        overflow: hidden;
        font-size: 0;
        line-height: 0;
        border-width: 9px;
        border-style: solid;
        border-color: transparent transparent rgba(255, 255, 255, 0.1)
          transparent;
      }
    }
    .date {
      text-align: right;
      color: rgba(255, 255, 255, 0.6);
    }
  }
}
</style>
