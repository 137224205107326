<!-- 地图右下角图标按钮-功能组件-->
<template>
  <div class="map-zoom-buttons">
    <MapButton @EventClick="EventClick">
      <template v-slot:top>
        <div
          log="插槽:上部按钮"
          class="ui-button"
          v-if="MapRoot.mapPlug?.zoomHome"
          @click="EventClick_home"
        >
          <i class="jiachang icon-cb"></i>
        </div>
      </template>
    </MapButton>
  </div>
</template>
<script>
import MapButton from '@/components/common/map/base/MapButton.vue'

export default {
  components: { MapButton },
  inject: ['AppRoot', 'MapRoot'],
  props: {},
  data() {
    return {}
  },
  watch: {},
  computed: {},
  created() {},
  mounted() {
    //DOM初始化完成进行地图初始化
  },

  methods: {
    EventClick(ret) {
      if (this.MapRoot.mapPlug.zoomCtrl) {
        this.MapRoot.mapPlug.zoomCtrl(ret)
      }
    },
    EventClick_home() {
      if (this.MapRoot.mapPlug?.zoomHome) {
        this.MapRoot.mapPlug?.zoomHome()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.map-zoom-buttons {
  position: absolute;
  top: 20px;
  right: 430px;
  z-index: 50;
  &.map-zoom-MapStoreVideo {
    right: 740px;
  }
}
</style>
