import { getMarkerList } from './HooksMapConfig.js'
import Api from '@/api/Api.js'
import { Info_baseList, Info_stationList } from '@/info/infoHome.js'

/**
 * 请求接口绘制地图轮廓
 * @param {Object} mapPlug //地图plug
 * @returns
 */

/**
 * 获取地图展示所需要的数据列表
 * @param {String,Number} areaCode //区域code
 * @param {String,Number} varieties //品种code
 */
export function GetInfoMarkers(areaCode, varieties) {
  let markerList = []
  return new Promise((rej) => {
    let reAreaCode = Api.removeZero(areaCode)
    let postData = {
      areaCode: reAreaCode,
      varieties: varieties
    }
    Promise.all([
      Info_baseList(postData),
      Info_stationList({
        areaCode: reAreaCode,
        type: 6
      })
    ])
      .then((rets) => {
        let listBase = rets[0]?.data ?? []
        if (listBase) {
          let baseMarkers = getMarkerList({
            list: listBase,
            type: 'base',
            icon: require('@/assets/img/garden/marker-2.png')
          }) //格式化点列表
          markerList.push(...baseMarkers)
        }

        let listStation = rets[1]?.data ?? []
        if (listStation) {
          let stationMarkers = getMarkerList({
            list: listStation,
            nameKey: 'stationName',
            type: 'station',
            icon: require('@/assets/img/garden/marker-1.png')
          }) //格式化点列表
          markerList.push(...stationMarkers)
        }
        //initPlugMapMultMarker(MapRoot, markerList) //在地图上画点
        rej(markerList)
      })
      .catch((err) => {
        console.error(err?.msg)
      })
  })
}
