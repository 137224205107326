<template>
  <el-dropdown
    @command="handleCommand"
    popper-class="m-select-button is-dark"
    trigger="click"
  >
    <div class="select-label">
      <slot name="icon"
        ><div class="name">{{ value }}小时</div>
        <i class="icon icon-xiangxia-copy iconfont"></i
      ></slot>
    </div>
    <template #dropdown>
      <el-dropdown-menu>
        <el-dropdown-item
          :command="item"
          v-for="(item, index) in option"
          :key="item.name"
          :tabindex="index"
          ><div
            class="select-item"
            :class="item.value === value ? 'current' : ''"
          >
            {{ item.name }}
          </div>
        </el-dropdown-item>
      </el-dropdown-menu>
    </template>
  </el-dropdown>
</template>
<script>
export default {
  components: {},
  model: {
    prop: 'option'
  },
  props: {
    option: {
      type: Array,
      default: () => {
        return []
      }
    },
    value: {
      type: [String, Number],
      default: () => {
        return ''
      }
    }
  },
  data() {
    return {}
  },
  watch: {},
  computed: {},
  mounted() {},
  methods: {
    handleCommand(ret) {
      this.$emit('update:value', ret.value)
      this.$emit('select', ret)
    }
  }
}
</script>
<style scoped lang="scss">
.select-label {
  padding: 5px 0;
  width: 60px;
  text-align: center;
  color: #0ff;
  display: flex;
  align-items: center;
  .icon {
    margin-left: 5px;
    font-size: 12px;
  }
}
.m-select-button {
  .select-item.current {
    color: #0ff;
  }
}
.ui-button {
  .icon {
    font-size: 24px;
  }
}
</style>
