<template>
  <div class="legend-wrap" v-if="legend?.length">
    <div class="unit" v-if="name">{{ name }}</div>
    <ul class="m-map-legend">
      <li v-for="item in legend" :key="item" :style="getStyle(item)">
        {{ item.name }}
      </li>
    </ul>
  </div>
</template>
<script>
import { color2Hex } from '@/api/func/colorRibbon.js'
export default {
  components: {},
  props: {
    legend: {
      type: Array,
      default: () => []
    },
    name: {
      type: String,
      default: ''
    }
  },
  data() {
    return {}
  },
  watch: {},
  computed: {},
  mounted() {},
  methods: {
    getStyle(item) {
      return 'background-color:' + color2Hex(item.colors)
    }
  }
}
</script>
<style scoped lang="scss">
.legend-wrap {
  text-align: center;
  border-radius: 3px;
  overflow: hidden;
  color: #fff;
  .unit {
    background: var(--ko-box-bg);
    padding: 7px 0;
    line-height: 14px;
    font-size: 12px;
    vertical-align: middle;
  }
  .m-map-legend {
    background: #1460ab;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    li {
      font-size: 12px;
      padding: 4px 12px;
      text-shadow: 1px 1px 1px rgba(0, 0, 0, 1);
    }
  }
}
</style>
