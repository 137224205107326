const textColor = '#FFF'
const options = {
  darkMode: true,
  textStyle: {
    color: textColor
  },
  title: { left: 'center', top: 10 },
  legend: {
    type: 'scroll',
    top: 10,
    left: 'center',
    width: '90%',
    right: 20,
    textStyle: {
      color: textColor
    },
    pageTextStyle: {
      color: textColor
    },
    pageIconColor: textColor
  },
  grid: {
    //调整图表上下左右位置
    top: 10,
    left: 10,
    right: 10,
    bottom: 10,
    containLabel: true
  }
}
export default options
