<template>
  <div class="box-suitability">
    <LayoutBoxTitle title="生长适宜度监测" class="mgb-10"></LayoutBoxTitle>
    <BoxAddress></BoxAddress>
    <div
      class="lump-suitability"
      v-loading="loading"
      element-loading-background="rgba(0, 0, 0, 0.6)"
      element-loading-text="数据加载中..."
    >
      <template v-if="suitsVal">
        <CpnSuitDataView
          :suitsVal="suitsVal"
          :suitsName="suitsName"
          :suitColor="suitColor"
          :starNum="starNum"
        ></CpnSuitDataView>
        <div class="m-suitability-element" v-if="suits?.length">
          <CpnGrowthSuitability :suits="suits"></CpnGrowthSuitability>
        </div>
      </template>
      <div v-else class="ui-no-data">{{ msg }}</div>
    </div>
  </div>
</template>
<script>
import LayoutBoxTitle from '@/components/view/layout/LayoutBoxTitle.vue'
import BoxAddress from './BoxAddress.vue'
import CpnSuitDataView from '@/components/content/model/CpnSuitDataView.vue'
import CpnGrowthSuitability from '@/components/content/model/CpnGrowthSuitability.vue'
import {
  Info_cropProductList,
  Info_suitabilityByLonlat
} from '@/info/infoHome.js'
export default {
  inject: ['AppRoot', 'MapRoot'],
  components: {
    LayoutBoxTitle,
    BoxAddress,
    CpnSuitDataView,
    CpnGrowthSuitability
  },
  props: {},
  data() {
    return {
      suits: [],
      suitsVal: '',
      suitsName: '',
      suitColor: '',
      suitDate: '',
      starNum: 4,
      msg: '暂无适宜度指标',
      loading: false
    }
  },
  watch: {
    'MapRoot.mapEvent.selectLocat': {
      handler() {
        this.getData_suitablilityDate()
      }
    }
  },
  computed: {},
  mounted() {},
  methods: {
    getData_suitablilityDate() {
      let postData = {
        type: 3,
        areaCode: this.AppRoot.areaCode,
        cropCode: this.AppRoot.cropCode
      }
      this.loading = true
      Info_cropProductList(postData)
        .then((ret) => {
          this.loading = false
          if (ret.data?.list?.length) {
            for (let item of ret.data?.list) {
              if (item.fileType == 'img') {
                this.suitDate = item.dateTime
                this.getData_suit()
                break
              }
            }
          } else {
            this.suits = []
            this.suitsVal = ''
            this.suitsName = ''
            this.starNum = ''
            this.suitColor = ''
          }
        })
        .catch((err) => {
          this.loading = false
          console.log(err)
          this.suits = []
          this.suitsVal = ''
          this.suitsName = ''
          this.starNum = ''
          this.suitColor = ''
        })
    },
    getData_suit() {
      let selectLocat = this.MapRoot.mapEvent.selectLocat
      let postData = {
        lon: selectLocat?.lon,
        lat: selectLocat?.lat,
        areaCode: this.AppRoot.areaCode,
        cropCode: this.AppRoot.cropCode,
        dateTime: this.suitDate?.Format('YYYY-MM-DD'),
        varieties: this.AppRoot.varietiesId
      }
      if (selectLocat?.lon && selectLocat?.lat) {
        this.loading = true
        Info_suitabilityByLonlat(postData)
          .then((ret) => {
            this.loading = false
            this.suits = []
            this.suitsVal = ''
            this.suitsName = ''
            this.starNum = ''
            this.suitColor = ''
            if (ret?.data?.tem || ret?.data?.tem === 0) {
              let e = ret.data
              this.suitsVal = parseInt(e.suitabilityVal * 100)
              this.suitsName = e.suitabilityLevel.levelName
              this.starNum = parseInt(this.suitsVal / 20)
              this.suitColor = e.suitabilityLevel.color
              this.suits.push({
                name: '温度',
                unit: '℃',
                value: e.tem,
                ...this.getPt(e.tem, e.temIndexMax, e.temIndexMin)
              })
              this.suits.push({
                name: '降水',
                unit: 'mm',
                value: e.pre,
                ...this.getPt(e.pre, e.preIndexMax, e.preIndexMin)
              })
              this.suits.push({
                name: '日照',
                unit: 'h',
                value: e.ssh,
                ...this.getPt(e.ssh, e.sshIndexMax, e.sshIndexMin)
              })
            } else {
              this.msg = ret?.msg ?? '暂无适宜度指标'
            }
          })
          .catch((err) => {
            this.loading = false
            this.suits = []
            this.suitsVal = ''
            this.suitsName = ''
            this.starNum = ''
            this.suitColor = ''
            console.log(err)
          })
      } else {
        this.suits = []
        this.suitsVal = ''
        this.suitsName = ''
        this.starNum = ''
        this.suitColor = ''
        console.error('生长适宜度监测 没有传入经纬度')
      }
    },
    getPt(val, max, min) {
      let diff = max - min
      if (val <= max && val >= min) {
        return { suitType: 2, pt: (val - min) / diff }
      } else if (val > max) {
        let prop = (val - max) / diff > 1 ? 1 : (val - max) / diff
        return { suitType: 3, pt: prop }
      } else {
        let prop = (min - val) / diff < 0 ? 0 : (min - val) / diff
        return { suitType: 1, pt: prop }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.box-suitability {
  padding: 10px 0 15px 0;
  background: var(--ko-body-bg);
  .lump-suitability {
    height: 240px;
  }
  .ui-no-data {
    height: 255px;
    box-sizing: border-box;
  }
}
</style>
