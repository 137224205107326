<template>
  <div class="box-address" v-if="address">
    <i class="icon icon-map iconfont"></i>{{ address }}
  </div>
</template>
<script>
import { InfoAMap_reGeo } from '@/info/third/third.js'
export default {
  inject: ['MapRoot'],
  components: {},
  props: {},
  data() {
    return {
      address: ''
    }
  },
  watch: {
    'MapRoot.mapEvent.selectLocat': {
      handler() {
        this.getData_address()
      }
    }
  },
  computed: {},
  mounted() {},
  methods: {
    getData_address() {
      let locat = this.MapRoot.mapEvent.selectLocat
      if (locat?.lon && locat.lat) {
        InfoAMap_reGeo({
          center: [locat?.lon, locat.lat]
        }).then((ret) => {
          this.address = ''
          if (ret.regeocode?.formatted_address) {
            this.address = ret.regeocode?.formatted_address?.replace(
              '贵州省黔南布依族苗族自治州',
              '黔南州'
            )
          }
        })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.box-address {
  padding: 7px;
  margin: 0 10px 10px;
  color: #adb9ce;
  font-size: 14px;
  text-align: center;
  .icon {
    display: inline-block;
    margin-right: 5px;
  }
}
</style>
