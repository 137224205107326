<!--通用布局-地图标题-->
<template>
  <div class="layout-map-title" v-if="title || subTitle" v-tdrag>
    <div class="title" @mousedown.stop contenteditable="true">
      {{ AppRoot.areaName + title }}
    </div>
    <div
      class="sub-title"
      v-if="subTitle"
      @mousedown.stop
      contenteditable="true"
    >
      {{ subTitle }}
    </div>
  </div>
</template>
<script>
import { Tdrag } from '@/utils/directives/Tdrag.js'
export default {
  inject: ['AppRoot'],
  components: {},
  props: {
    title: {
      type: String,
      default: ''
    },
    subTitle: {
      type: String,
      default: ''
    }
  },
  directives: {
    tdrag: {
      // 指令的定义
      mounted: Tdrag
    }
  },
  data() {
    return {}
  },
  watch: {},
  computed: {},
  mounted() {},
  methods: {}
}
</script>
<style lang="scss" scoped>
.layout-map-title {
  position: absolute;
  left: 25%;
  top: 100px;
  line-height: 1.4;
  text-align: center;
  border-radius: 5px;
  color: #fff;
  user-select: none;
  border: 1px solid rgba(0, 0, 0, 0);
  border-top: 20px solid rgba(0, 0, 0, 0);
  cursor: move;
  .title {
    font-size: 22px;
    padding: 5px 5px 10px 5px;
    cursor: text;
    text-shadow: 1px 1px 0 rgba(0, 0, 0, 1), 0 -1px 0 rgba(0, 0, 0, 1),
      -1px 0 0 rgba(0, 0, 0, 1), 1px 0 0 rgba(0, 0, 0, 1);
  }
  .sub-title {
    padding: 0px 5px;
    font-size: 16px;
    cursor: text;
    text-shadow: 1px 1px 0 rgba(0, 0, 0, 1), 0 -1px 0 rgba(0, 0, 0, 1),
      -1px 0 0 rgba(0, 0, 0, 1), 1px 0 0 rgba(0, 0, 0, 1);
  }
  .handler {
    width: 30px;
    height: 30px;
    background: #000;
  }
  &:hover {
    border: 1px solid rgba(0, 0, 0, 0.5);
    border-top: 20px solid rgba(0, 0, 0, 0.5);
  }
}
</style>
