import { KOAjax } from '@/service/koajax.js'
import { Info_requestGet } from '@/info/req/infoReq.js'
let key = '3f65d019eaae4476c2787755f31b33fa'
/**
 * @通过经纬度获取高德地图地址
 */
export function InfoAMap_reGeo(ret) {
  var def = { center: [104.11, 30.49], radius: 3000, all: false }
  var opt = Object.assign({}, def, ret)
  const url = 'https://restapi.amap.com/v3/geocode/regeo'
  return Info_requestGet({
    url: url,
    params: {
      location: opt.center.join(','),
      key: key,
      radius: 1000,
      extensions: 'base'
    }
  })
}

/**
 * @通过经纬度获取高德地图poi地址
 */
export function InfoAMap_reGeoPoi(ret) {
  var def = { center: [104.11, 30.49], radius: 3000, all: false }
  var opt = Object.assign({}, def, ret)
  return KOAjax({
    morePost: true,
    url: '/source/api/caiyun/requestForwarding',
    params: {
      tokenKey: '',
      url:
        'https://restapi.amap.com/v3/place/around?location=' +
        opt.center.join(',') +
        '&key=' +
        key +
        '&radius=1000&extensions=all&offset=1'
    }
  })
}

/**
 * @获取地图_本地行政区划数据
 */
export function InfoValue_weather(ret) {
  let def = {}
  let opt = Object.assign({}, def, ret)
  return KOAjax({
    url: 'https://api.caiyunapp.com/v2.5/xc0BrbHfYBT0mPwB/121.6544,25.1552/realtime.json',
    params: opt
    //load: 'mock'
  })
}

/**
 * @获取经纬度和信息_地址搜索
 */
export function InfoAMap_Geo(ret) {
  let def = {
    keywords: '',
    areaCode: '' //522727
  }
  let opt = Object.assign({}, def, ret)
  return KOAjax({
    morePost: true,
    url: '/source/api/caiyun/requestForwarding',
    params: {
      tokenKey: '',
      url:
        'https://restapi.amap.com/v5/place/text?keywords=' +
        encodeURI(opt.keywords) +
        '&key=' +
        key +
        '&region=' +
        opt.areaCode +
        '&output=JSON&page_size=30'
    }
  })
}
