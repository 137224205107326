import Api from '@/api/Api.js'
import TypeIs from '@/api/TypeIs.js'
import DictLive from '@/api/dict/DictLive.js'
/**
 * @数据验证
 */
export function DataVer(data, key) {
  if (key) {
    for (let i in key) {
      if (typeof data[i] === 'undefined' || data[i] === '') {
        Api.msg(data[i] + '为必填(选)项')
        return false
      }
    }
  } else {
    for (let i in data) {
      if (data[i] === '') {
        Api.msg('表单未填写(选择)完整，请完善必填(选)项')
        return false
      }
    }
  }
  return true
}
/**
 * @时间纠错
 */
export function DataCorr(key) {
  let types = {
    datetime: '4',
    date: '3',
    xun: '5',
    month: '1',
    ji: '6',
    year: '2'
  }
  return types[key] ?? ''
}

/**
 * @时间查找
 */
export function DataFind(ret) {
  let def = {
    labels: [],
    data: {}
  }
  let opt = Object.assign({}, def, ret)
  let arr = []
  for (let label of opt.labels) {
    for (let item in opt.data) {
      if (label?.key?.indexOf(item) >= 0) {
        let obj = {
          prop: item,
          label: label.name,
          name: label.name
        }
        if (label.slotName) {
          obj.slotName = label.slotName
        }
        if (label.width) {
          obj.width = label.width
        }
        arr.push(obj)
      }
    }
    if (label.slotKey) {
      arr.push({
        ...label,
        label: label.name || label.label
      })
    }
  }
  return arr
}

/**
 * @时间查找
 */
export function reList_table(ret) {
  let def = {
    list: []
  }
  let opt = Object.assign({}, def, ret)
  let dataList = opt.list
  let list = []
  if (dataList?.length) {
    for (let item of dataList) {
      if (item.lat) {
        item.lat = Math.round(item.lat * 10000) / 10000
      } else if (item.lon) {
        item.lon = Math.round(item.lon * 10000) / 10000
      }
      list.push(item)
    }
  }
  return list
}

/**
 * @表数据head改为中文
 * [{key:'',name:''}]
 */
export function reHead_table(heads, list) {
  let reTable = []
  for (let item of list) {
    let obj = {}
    for (let head of heads) {
      if (typeof item[head.prop] !== 'undefined') {
        obj[head.name] = item[head.prop]
      }
    }
    reTable.push(obj)
  }
  return reTable
}

/**
 * @获取表数据
 */
export function getTableView(ret) {
  let def = {
    head: [],
    list: [],
    sortKey: 'stationNum'
  }
  let opt = Object.assign({}, def, ret)
  let list = []
  let head = []
  if (opt.list?.length) {
    list = reList_table({ list: opt.list })
    head = DataFind({
      labels: opt.head,
      data: opt.list[0]
    })
  }
  if (opt.sortKey) {
    list = list.sort((a, b) => {
      return a[opt.sortKey] - b[opt.sortKey]
    })
  }
  return { list, head }
}

/**
 * @数据纠错
 */
export function dataCorrection(item, defaultStr) {
  let defStr = defaultStr ?? '缺省'
  let obj = {}
  for (let key in item) {
    if (TypeIs.Number(item[key])) {
      let name = DictLive.nameDict(key)
      if (name) {
        if (item[key] > 9999 || item[key] < -9999) {
          obj[key] = defStr
        } else {
          obj[key] = item[key]
        }
      } else {
        obj[key] = item[key]
      }
    } else if (TypeIs.Object(item[key])) {
      if (TypeIs.Number(item[key].value)) {
        let name = DictLive.nameDict(key)
        if (name) {
          if (item[key].value > 9999 || item[key].value < -9999) {
            obj[key] = item[key]
            obj[key].value = defStr
          } else {
            obj[key] = item[key]
          }
        } else {
          obj[key] = item[key]
        }
      }
    } else {
      obj[key] = item[key]
    }
  }
  return obj
}
export function Tree2Dict(ret) {
  let opt = {
    tree: [],
    keys: ['areaName', 'areaCode'], //需要转换的key字段
    dictKey: 'areaCode', //作为字典的key
    parentKey: 'parent',
    childrenKey: 'children'
  }
  opt = Api.extend(opt, ret)
  let list = Tree2Arr(opt)
  let dict = {}
  for (let item of list) {
    dict[item[opt.dictKey]] = item
  }
  return dict
}
/**
 * @数据扁平化_传入一个区域树_返回扁平化的区域数组
 */
export function Tree2Arr(ret) {
  let opt = {
    tree: [],
    keys: ['areaName', 'areaCode'], //需要转换的key字段
    parentKey: 'parent',
    childrenKey: 'children'
  }
  opt = Api.extend(opt, ret)
  let resArr = []
  const fn = (arr, parent) => {
    for (let i = 0; i < arr.length; i++) {
      if (Array.isArray(arr[i].children) && arr[i][opt.childrenKey]?.length) {
        if (parent) {
          arr[i].parent = {}
          for (let key of opt.keys) {
            arr[i].parent[key] = parent[key]
          }
          arr[i].parent[opt.parentKey] = parent[opt.parentKey]
        }
        fn(arr[i][opt.childrenKey], arr[i])
      }
      let obj = { parent: parent }
      for (let key of opt.keys) {
        obj[key] = arr[i][key]
      }
      resArr.push(obj)
    }
  }
  fn(ret.tree)
  return resArr
}

/**
 * @数据扁平化
 */
export function DataCropFlat(ret) {
  let resArr = []
  const fn = (arr, parent) => {
    for (let i = 0; i < arr.length; i++) {
      if (Array.isArray(arr[i].children) && arr[i].children?.length) {
        if (parent) {
          arr[i].parent = {
            id: arr[i].id,
            isShow: arr[i].id,
            name: parent?.name,
            href: parent?.href,
            pid: parent?.id,
            parent: parent?.parent
          }
        }
        fn(arr[i].children, arr[i])
      }
      if (arr[i].type != 2) {
        resArr.push({
          id: arr[i].id,
          name: arr[i].name,
          href: arr[i].href,
          pid: parent?.id,
          isShow: arr[i].isShow,
          parent: parent
        })
      }
    }
  }
  fn(ret)
  return resArr
}

/**
 * 数组转树形结构
 * @param {any[]} data
 * @param {{id: string, pId: string}} config
 */
export const Arr2Tree = (data, config) => {
  const { id = 'id', pId = 'pid' } = config || {}

  const ids = data.map((_) => _[id])
  const result = []
  const waitChecked = []
  data.forEach((_) => (ids.includes(_[pId]) ? waitChecked : result).push(_))
  const flatData = [...result]

  while (waitChecked.length) {
    const flatIds = flatData.map((_) => _[id])
    const node = waitChecked.find((_) => flatIds.includes(_[pId]))
    const parNode = flatData.find((_) => _[id] === node[pId])
    waitChecked.splice(waitChecked.indexOf(node), 1)
    if (parNode.children) {
      parNode.children.push(node)
    } else {
      parNode.children = [node]
    }
    flatData.push(node)
  }

  return result
}

/**
 * 去重函数
 * @param {string} inputString - 待处理的字符串
 * @param {string} targetString - 需要去重的字符串
 * @returns {string} - 去重后的字符串
 */
export function removeSameString(inputString, targetString) {
  // 查找目标字符串第一次出现的位置
  const targetIndex = inputString.indexOf(targetString)
  if (targetIndex === -1) {
    return inputString // 如果待处理字符串中不存在目标字符串，则返回原字符串
  }

  // 截取字符串，保留第一个目标字符串，去掉后面的所有相同字符串
  const firstPart = inputString.substring(0, targetIndex + targetString.length)
  const secondPart = inputString.substring(targetIndex + targetString.length)
  const cleanedString =
    firstPart + secondPart.replace(new RegExp(targetString, 'g'), '')

  return cleanedString
}

/**
 * 向数组中特定对象后面插入另一个对象
 * @param {Array} arr - 目标数组
 * @param {*} objA - 要查找的对象
 * @param {*} objB - 要插入的对象
 */
export function insertObjectAfter(arr, objA, objB) {
  // 查找对象a在数组中的索引
  const index = arr.findIndex((item) => item === objA)

  // 如果找到了对象a，则在其后插入对象b
  if (index !== -1) {
    arr.splice(index + 1, 0, objB)
  } else {
    // 如果没有找到对象a，则将对象b添加到数组末尾
    arr.push(objB)
  }
}

/**
 * 根据数组中对象的success属性处理数据
 * @param {Array} arr - 包含对象的数组，对象需有success属性
 * @returns {boolean|Array} - 如果数组中全是{success:false}，返回false；如果有{success:true}，返回包含所有{success:true}的对象组成的数组
 */
export function processSuccessObjects(arr) {
  // 使用filter筛选出success为true的对象
  const successfulObjects = arr.filter((item) => item.success === true)

  // 判断筛选后的数组长度，决定返回值
  if (successfulObjects.length === 0) {
    return false
  } else {
    return successfulObjects
  }
}
