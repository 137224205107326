<template>
  <div class="home-box-expert-list">
    <LayoutBoxTitle class="m-title mgb-15">
      <div class="m-export-title">
        <div class="title">专家点评</div>
        <div class="link" @click="EventClick_link">
          农业专家知识库<i class="iconfont icon-iconright"></i>
        </div>
      </div>
    </LayoutBoxTitle>
    <Swiper
      class="m-swiper"
      v-if="list?.length"
      :slides-per-view="slidesView"
      @swiper="initSwiper"
      :modules="modules"
      :autoplay="{
        delay: 15000,
        disableOnInteraction: false
      }"
    >
      <SwiperSlide v-for="item in list" :key="item">
        <HomeExpertList :list="[item]"></HomeExpertList>
      </SwiperSlide>
    </Swiper>
    <div v-else class="ui-no-data">暂无专家点评</div>
  </div>
</template>
<script>
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Autoplay } from 'swiper'
import LayoutBoxTitle from '@/components/view/layout/LayoutBoxTitle.vue'
import HomeExpertList from '@/components/content/list/HomeExpertList.vue'
import { Info_expertList } from '@/info/info.js'
import 'swiper/css/autoplay'
export default {
  inject: ['AppRoot'],
  components: { LayoutBoxTitle, HomeExpertList, Swiper, SwiperSlide },
  props: {},
  data() {
    return {
      slidesView: 1,
      list: [],
      modules: [Autoplay]
    }
  },
  watch: {
    'AppRoot.areaCode': {
      handler() {
        this.getData_expert()
      }
    },
    'AppRoot.varietiesId': {
      handler() {
        this.getData_expert()
      }
    },
    list: {
      handler() {
        this.$nextTick(() => {
          this.swiper?.update()
        })
      }
    }
  },
  computed: {},
  mounted() {
    this.getData_expert()
  },
  methods: {
    getData_expert() {
      if (this.AppRoot.areaCode && this.AppRoot.cropCode) {
        Info_expertList({
          areaCode: this.Api.removeZero(this.AppRoot.areaCode),
          cropCode: this.AppRoot.cropCode,
          varieties: this.AppRoot.varietiesId
        }).then((ret) => {
          this.list = []
          if (ret?.data?.list?.length) {
            this.list = ret.data.list
          }
        })
      }
    },
    initSwiper(swiper) {
      this.swiper = swiper
    },
    EventClick_link() {
      window.open(
        'http://58.42.87.122:8088/#/expertOnline?typeIndex=eyJhbGciOiJIUzUxMiJ9.eyGVmLTQ2ZTE4NDg5NWY0NyJ9'
      )
    }
  }
}
</script>
<style lang="scss" scoped>
.home-box-expert-list {
  height: 100%;
  padding: 10px 0 0;
  box-sizing: border-box;
  background: var(--ko-body-bg);
  display: flex;
  flex-direction: column;
  .m-title {
    flex-shrink: 0;
  }
  .m-swiper {
    height: 10px;
    flex-grow: 1;
    width: 100%;
  }
  .m-export-title {
    display: flex;
    flex-grow: 1;
    .title {
      flex-grow: 1;
    }
    .link {
      flex-shrink: 0;
      color: #00f4f3;
      cursor: pointer;
    }
  }
}
</style>
