<template>
  <div class="cpn-marker-legend" v-if="MapRoot.markerLegend?.length">
    <div
      class="icon-hander fenbu icon-cb"
      @click="showLegend = !showLegend"
    ></div>
    <div class="legend-main" v-show="showLegend">
      <div class="top-lump" v-if="allRead && MapRoot.markerLegend?.length > 1">
        <div class="all-show link" @click="EventAllShow">全部显示</div>
      </div>
      <ul>
        <template v-for="(item, index) in MapRoot.markerLegend" :key="item">
          <li
            @click="EventClick(item, index)"
            :class="item.visible == false ? '' : 'point-visible'"
          >
            <KOimg
              class="icon icon-img"
              v-if="dict[item.key]"
              :src="dict[item.key]"
              fit="contain"
            ></KOimg>
            <div
              v-else
              class="icon icon-color"
              :style="{ background: item.color }"
            ></div>
            <div class="name">{{ item.name }}</div>
          </li>
        </template>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  log: '',
  components: {},
  inject: ['MapRoot'],
  props: {
    disabledClick: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      viewMarkerType: 'allShow',
      allRead: false,
      dict: {
        gardenBase: require('@/assets/img/garden/marker/marker-1.png'),
        camera: require('@/assets/img/garden/marker/marker-3.png'),
        wuyuanSensor: require('@/assets/img/garden/marker/marker-4.png'),
        farmlandSensor: require('@/assets/img/garden/marker/marker-2.png'),

        station: require('@/assets/img/garden/marker-1.png'),
        base: require('@/assets/img/garden/marker-2.png')
      },
      showLegend: false
    }
  },
  watch: {
    'MapRoot.markerLegend': {
      handler() {
        this.init()
      }
    }
  },
  computed: {
    markerType() {
      return this.MapRoot?.markerType
    }
  },
  mounted() {},
  methods: {
    init() {
      if (this.MapRoot.markerLegend?.length == 1) {
        this.viewMarkerType = this.MapRoot.markerLegend[0].key
      }
    },
    EventClick(ret) {
      if (this.disabledClick) {
        return
      }
      this.allRead = true
      if (this.viewMarkerType == ret.key) {
        this.viewMarkerType = ''
        ret.visible = false
        this.MapRoot.mapEvent.selectFeature = false
        return
      }
      for (let item of this.MapRoot.markerLegend) {
        if (item.key == ret.key) {
          this.viewMarkerType = ret.key
          item.visible = true
        } else {
          item.visible = false
        }
        //取消显示当前marker图例时，需要一并隐藏该marker的弹层
        if (
          this.MapRoot.mapEvent.selectFeature.markerType == item.key &&
          item.visible == false
        ) {
          this.MapRoot.mapEvent.selectFeature = false
        }
      }
    },
    EventAllShow() {
      this.allRead = false
      for (let item of this.MapRoot.markerLegend) {
        item.visible = true
        this.viewMarkerType = 'allShow'
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.cpn-marker-legend {
  position: absolute;
  right: 430px;
  bottom: 200px;
  z-index: 60;
  .legend-main {
    position: absolute;
    right: 0;
    top: 50px;
    width: 150px;
    padding: 15px;
    background: var(--ko-box-bg);
    border: 1px solid #104869;
    border-radius: 5px;
  }
  .icon-hander {
    width: 40px;
    height: 40px;
    background: var(--ko-box-bg);
    border: 1px solid #104869;
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    cursor: pointer;
  }
  .top-lump {
    margin-bottom: 12px;
    display: flex;
    .link {
      flex-grow: 1;
      text-align: center;
      color: #0cc;
      cursor: pointer;
      &:hover {
        color: #0ff;
      }
    }
  }

  li {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    color: rgba(255, 255, 255, 0.5);
    cursor: pointer;
    .icon {
      height: 24px;
      width: 24px;
      margin-right: 10px;
    }
    .icon-color {
      height: 14px;
      width: 14px;
      border-radius: 50%;
      opacity: 0.5;
    }
    &:hover {
      color: rgba(255, 255, 255, 0.7);
    }
    &:last-child {
      margin-bottom: 0;
    }
    &.point-visible {
      color: #fff;
      .icon {
        opacity: 1;
      }
    }
  }
}
</style>
