<template>
  <div class="cpn-echart-pie" v-show="option" ref="RChartParent">
    <ChartPieView class="pie-ganhan" ref="RChartView"></ChartPieView>
    <div class="pie-text" v-if="false">
      {{ MapRoot.mapImageIsogram?.date?.Format('MM月DD日') }}
    </div>
  </div>
</template>
<script>
import ChartPieView from '@/components/content/chart/ChartPieView.vue'
import TypeIs from '@/api/TypeIs.js'
import { nowSize } from '@/plug/chart/chartFn.js'
import { watchWidth } from '@/plug/dom/watchWidth.js'
import { arrToHex } from '@/api/func/colorRibbon.js'
export default {
  log: '',
  components: { ChartPieView },
  inject: ['MapRoot'],
  props: {},
  data() {
    return {
      testList: [],
      option: false
    }
  },
  watch: {
    'MapRoot.plantAreaChart': {
      handler() {
        this.initData()
      }
    }
  },
  computed: {},
  mounted() {
    this.watchDom()
  },
  methods: {
    watchDom() {
      watchWidth(this.$refs.RChartParent, () => {
        this.initData()
      })
    },
    initLegends() {
      this.legendNames = []
      this.legendColors = []
      if (this.MapRoot.mapImageIsogram?.legend?.length) {
        for (let item of this.MapRoot.mapImageIsogram?.legend) {
          this.legendNames.push(item.name)
          this.legendColors.push(arrToHex(item.colors))
        }
      }
    },
    initData() {
      this.initLegends()
      this.myData = []
      let list = this.MapRoot.plantAreaChart.sort((a, b) => b.size - a.size)
      //let colors = this.legendColors
      let sum = list.reduce((a, b) => {
        return a + b.size
      }, 0)

      for (let i in list) {
        let value = parseInt(list[i].size)
        this.myData.push({
          value: value,
          name:
            value +
            list[i].unit +
            '(' +
            this.Api.keep2((value / sum) * 100) +
            '%)',
          label: list[i].name,
          itemStyle: {
            color: arrToHex(list[i].color)
          }
        })
      }
      this.refresh()
    },
    getTestData() {
      let labels = this.legendColors
      let list = []
      for (let i in labels) {
        list.push({
          value: parseInt(Math.random() * 50),
          name: labels[i]
        })
      }
      this.testList = list
    },
    getData() {
      if (TypeIs.Array(this.myData) && this.myData?.length) {
        let opt = {
          tooltip: {
            trigger: 'item',
            formatter: (params) => {
              return params.marker + params.data.label + '：' + params.name
            }
          },
          legend: {
            orient: 'vertical',
            top: 'center',
            left: '43%',
            textStyle: {
              fontSize: nowSize(14)
            },
            itemHeight: 8,
            itemWidth: 12
          },
          series: [
            {
              name: '',
              type: 'pie',
              center: ['20%', '50%'],
              radius: ['0%', '60%'],
              label: {
                show: false
              },
              labelLine: {
                show: false
              },
              data: this.myData
            }
          ]
          //dispatchAction: {}
        }
        return opt
      } else {
        return false
      }
    },
    refresh() {
      this.option = this.getData()
      if (this.option && this.$refs.RChartView) {
        this.$refs.RChartView.initData(this.option)
      }
    },
    EventChange() {
      this.refresh()
    }
  }
}
</script>
<style lang="scss" scoped>
.cpn-echart-pie {
  position: relative;
  height: 140px;
  .pie-text {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
