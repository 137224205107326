import Api from '@/api/Api.js'
import { DefAjax } from '@/service/ajax.js'

/**
 * @接口转发GET
 */
export function Info_requestGet(ret) {
  let paramStr = Api.obj2para(ret.params)
  return Info_requestInfo({ url: ret.url + '?' + paramStr })
}

/**
 * @接口转发POST
 */
export function Info_requestPost(ret) {
  let def = {
    content: '',
    save: true
  }
  let opt = Object.assign({}, def, ret)
  let data = opt.params || opt.data
  const infoAjax = {
    postType: 'formData',
    morePost: true,
    save: opt.save,
    method: 'post',
    url: '/source/api/utils/forward/post',
    data: {
      url: Api.weaHost + opt.url,
      content: JSON.stringify(data)
    }
  }
  return DefAjax(infoAjax)
}

/**
 * @接口转发Get基础
 */
export function Info_requestInfo(ret) {
  let def = {
    url: '',
    save: true
  }
  let opt = Object.assign({}, def, ret)
  let postData = opt
  const infoAjax = {
    morePost: true,
    save: opt.save,
    method: 'get',
    url: '/source/api/utils/forward/get',
    params: postData
  }
  return DefAjax(infoAjax)
}
