class KOApi {
  unitDict(name) {
    let dict = {
      温度: '°',
      降水: 'mm',
      湿度: '%',
      纳米: 'nm',
      厘米: 'cm',
      米: 'm',
      热通量: 'w/m²',
      风速: 'm/s',
      风力: '级',
      大气湿度: '%RH',
      照度: 'LUX',
      光合作用: 'kls',
      辐射: 'k/m²',
      净辐射: 'wMJ/m²',
      时数: 'h',
      电压: 'v',
      气压: 'kPa',
      导电率: 'μS/cm',
      二氧化碳: 'ppm'
    }
    return dict[name] ?? ''
  }
  nameDict(key) {
    //注意：默认全小写
    let nameDict = {
      温度: ['temp', 'tem', 'temperature'],
      空气温度: ['airTemperature', 'airTemp'],
      平均温度: ['tem_avg', 'temavg', 'tempavg'],
      最高温度: ['tem_Max_24H', 'maxtemp', 'tem_max', 'tempmax', 'most_temp'],
      最低温度: ['tem_Min_24H', 'mintemp', 'tem_min', 'tempmin', 'least_temp'],
      第一层温度: ['tem_1st'],
      第二层温度: ['tem_2nd'],
      第三层温度: ['tem_3rd'],
      湿度: ['rhu'],
      地温: ['gst'],
      水汽压: ['vap', 'vapor_pres'],
      空气湿度: ['humidity'],
      土壤温度: ['soiltemp', 'soilTemperature'],
      土壤湿度: ['soilrhu', 'moisture'],
      土壤导电率: ['ec'],
      第一层湿度: ['rhu_1st'],
      第二层湿度: ['rhu_2nd'],
      第三层湿度: ['rhu_3rd'],
      二氧化碳: ['fico2'],
      光合有效: ['hiu'],
      光照强度: ['illuminance'],
      降水: ['prec', 'pre', 'pre_1h', 'bucket_rain_count'],
      平均湿度: ['rhu_avg', 'rela_humi'],
      日照: ['ssh'],
      风速: ['winds', 'win_s_avg_2mi', 'win_s_2mi_avg'],
      风向: ['windd'],
      风力: ['windlevel'],
      气压: ['prs', 'prs_min', 'pres']
    }
    let dict = {}
    for (let i in nameDict) {
      for (let item of nameDict[i]) {
        dict[item?.toLowerCase()] = i
      }
    }
    if (key) {
      key = key.toLowerCase()
      if (dict[key]) {
        //完全匹配
        return dict[key]
      } else {
        //模糊匹配
        for (let i in dict) {
          if (key.indexOf(i) >= 0) {
            return dict[i]
          }
        }
        return ''
      }
    } else {
      console.log('KOApi.nameDict(key)方法，key参数未定义或没有值')
    }
  }
  live(t) {
    let data = {
      气温: {
        name: '气温',
        icon: 'icon-live live-cb-dapingwendu',
        unit: this.unitDict('温度')
      },
      空气温度: {
        name: '空气温度',
        icon: 'icon-live live-kongqiwendu',
        unit: this.unitDict('温度')
      },
      最高温: {
        name: '最高温度',
        icon: 'icon-live live-cb-dapingwendu',
        unit: this.unitDict('温度')
      },
      最低温: {
        name: '最低温度',
        icon: 'icon-live live-cb-dapingwendu',
        unit: this.unitDict('温度')
      },
      平均气温: {
        name: '平均气温',
        icon: 'icon-live live-cb-dapingwendu',
        unit: this.unitDict('温度')
      },
      土壤温度: {
        name: '土壤温度',
        icon: 'icon-live live-cb20-tuwen',
        unit: this.unitDict('温度')
      },
      土壤湿度: {
        name: '土壤湿度',
        icon: 'icon-live live-cb-dapingturangshidu',
        unit: this.unitDict('湿度')
      },
      温度: {
        name: '温度',
        icon: 'icon-live live-cb-dapingwendu',
        unit: this.unitDict('温度')
      },
      地温: {
        name: '地温',
        icon: 'icon-live live-cb-tuwen',
        unit: this.unitDict('温度')
      },
      湿度: {
        name: '湿度',
        icon: 'icon-live live-cb-dapingshidu',
        unit: this.unitDict('湿度')
      },
      空气相对湿度: {
        name: '空气相对湿度',
        icon: 'icon-live live-kongqixiangduishidu',
        unit: this.unitDict('湿度')
      },
      土壤体积含水量: {
        name: '空气相对湿度',
        icon: 'icon-live live-turangtijihanshuiliang',
        unit: this.unitDict('湿度')
      },
      入射光: {
        name: '入射光',
        icon: 'icon-live live-rusheguang',
        unit: this.unitDict('纳米')
      },
      反射光: {
        name: '反射光',
        icon: 'icon-live live-rusheguang',
        unit: this.unitDict('纳米')
      },
      土壤热通量: {
        name: '土壤热通量',
        icon: 'icon-live live-turangretongliang',
        unit: this.unitDict('热通量')
      },
      高度: {
        name: '高度',
        icon: 'icon-live live-zhizhugaodu',
        unit: this.unitDict('厘米')
      },
      超声波风速: {
        name: '超声波风速',
        icon: 'icon-live live-wind_speed',
        unit: this.unitDict('风速')
      },
      大气温度: {
        name: '大气温度',
        icon: 'icon-live live-cb-dapingwendu',
        unit: this.unitDict('温度')
      },
      大气湿度: {
        name: '大气湿度',
        icon: 'icon-live live-cb-dapingshidu',
        unit: this.unitDict('大气湿度')
      },
      照度: {
        name: '照度',
        icon: 'icon-live live-cb-dapingguangzhao',
        unit: this.unitDict('照度')
      },
      溶解氧: {
        name: '溶解氧',
        icon: 'icon-live live-rongjieyangjiance',
        unit: ''
      },
      电导率: {
        name: '导电率',
        icon: 'icon-live live-diandaoshuai',
        unit: this.unitDict('导电率')
      },
      导电率: {
        name: '导电率',
        icon: 'icon-live live-diandaoshuai',
        unit: this.unitDict('导电率')
      },
      土壤电导率: {
        name: '土壤电导率',
        icon: 'icon-live live-diandaoshuai',
        unit: this.unitDict('导电率')
      },
      ph: { name: 'PH', icon: 'icon-live live-ph', unit: '' },
      pH: { name: 'PH', icon: 'icon-live live-ph', unit: '' },
      PH: { name: 'PH', icon: 'icon-live live-ph', unit: '' },
      浊度: { name: '浊度', icon: 'icon-live live-zhuodu', unit: '' },
      液位: { name: '液位', icon: 'icon-live live-yewei', unit: '' },
      雨雪感知: {
        name: '雨雪感知',
        icon: 'icon-live live-yuxueganzhi',
        unit: ''
      },
      水温: {
        name: '水温',
        icon: 'icon-live live-haishuiwendu',
        unit: this.unitDict('温度')
      },
      相对湿度: {
        name: '相对湿度',
        icon: 'icon-live live-cb-dapingshidu',
        unit: this.unitDict('湿度')
      },
      光照: {
        name: '光照',
        icon: 'icon-live live-cb-dapingguangzhao',
        unit: this.unitDict('照度')
      },
      光合作用: {
        name: '光合作用',
        icon: 'icon-live live-guanghezuoyong',
        unit: this.unitDict('光合作用')
      },
      总辐射: {
        name: '总辐射',
        icon: 'icon-live live-fushe',
        unit: this.unitDict('热通量')
      },
      空气湿度: {
        name: '空气湿度',
        icon: 'icon-live live-cb-dapingshidu',
        unit: this.unitDict('湿度')
      },
      土壤水分: {
        name: '土壤水分',
        icon: 'icon-live live-cb-dapingturangshidu',
        unit: this.unitDict('湿度')
      },
      大气压力: {
        name: '气压',
        icon: 'icon-live live-cb-qiya',
        unit: this.unitDict('气压')
      },
      气压: {
        name: '气压',
        icon: 'icon-live live-cb-qiya',
        unit: this.unitDict('气压')
      },
      水汽压: {
        name: '水汽压',
        icon: 'icon-live live-cb-qiya',
        unit: this.unitDict('气压')
      },
      降水: {
        name: '降水',
        icon: 'icon-live live-cb-dapingjiangyuliang',
        unit: this.unitDict('降水')
      },
      降水量: {
        name: '降水',
        icon: 'icon-live live-cb-dapingjiangyuliang',
        unit: this.unitDict('降水')
      },
      雨量: {
        name: '降水',
        icon: 'icon-live live-cb-dapingjiangyuliang',
        unit: this.unitDict('降水')
      },
      降雨量: {
        name: '降水',
        icon: 'icon-live live-cb-dapingjiangyuliang',
        unit: this.unitDict('降水')
      },
      累计降水量: {
        name: '累积降水量',
        icon: 'icon-live live-cb-dapingjiangyuliang',
        unit: this.unitDict('降水')
      },
      二氧化碳: {
        name: '二氧化碳',
        icon: 'icon-live live-eryanghuatan',
        unit: this.unitDict('二氧化碳')
      },
      电压: {
        name: '电压',
        icon: 'icon-live live-dianya',
        unit: this.unitDict('电压')
      },
      日照: {
        name: '日照',
        icon: 'icon-live live-cb-dapingguangzhao',
        unit: this.unitDict('时数')
      },
      日照百分率: {
        name: '日照百分率',
        icon: 'icon-live live-cb-dapingguangzhao',
        unit: this.unitDict('湿度')
      },
      日照时数: {
        name: '日照时数',
        icon: 'icon-live live-cb-dapingguangzhao',
        unit: this.unitDict('时数')
      },
      实照时数: {
        name: '实照时数',
        icon: 'icon-live live-cb-dapingguangzhao',
        unit: this.unitDict('时数')
      },
      可照时数: {
        name: '可照时数',
        icon: 'icon-live live-cb-dapingguangzhao',
        unit: this.unitDict('时数')
      },
      能见度: {
        name: '能见度',
        icon: 'icon-live live-cb-nengjiandu',
        unit: this.unitDict('米')
      },
      风向: { name: '风向', icon: 'icon-live live-cb-fengxiang', unit: '' },
      风速: {
        name: '风速',
        icon: 'icon-live live-wind_speed',
        unit: this.unitDict('风速')
      },
      风力风向: {
        name: '风力风向',
        icon: 'icon-live live-wind_speed',
        unit: this.unitDict('风力')
      },
      风力: {
        name: '风力',
        icon: 'icon-live live-wind_speed',
        unit: this.unitDict('风力')
      },
      昼夜温差: {
        name: '昼夜温差',
        icon: 'iconfont2 icon2-zhouyewencha',
        unit: this.unitDict('温度')
      },
      平均昼夜温差: {
        name: '平均昼夜温差',
        icon: 'iconfont2 icon2-zhouyewencha',
        unit: this.unitDict('温度')
      },
      辐射: {
        name: '辐射',
        icon: 'icon-live live-fushe',
        unit: this.unitDict('热通量')
      },
      净辐射: {
        name: '辐射',
        icon: 'icon-live live-fushe',
        unit: this.unitDict('净辐射')
      },
      太阳辐射: {
        name: '太阳辐射',
        icon: 'icon-live live-fushe',
        unit: this.unitDict('热通量')
      },
      光合有效: {
        name: '光合有效',
        icon: 'icon-live live-fushe',
        unit: this.unitDict('热通量')
      }
    }
    if (t) {
      if (data[t]) {
        //先完全匹配
        return data[t]
      } else {
        //完全匹配失败 再模糊匹配
        for (let i in data) {
          if (t.indexOf(i) >= 0) {
            return data[i]
          }
        }
        let name = this.nameDict(t)
        if (name) {
          if (data[name]) {
            return data[name]
          } else {
            for (let i in data) {
              if (name.indexOf(i) >= 0) {
                return data[i]
              }
            }
          }
        }
        return { name: t, icon: '', unit: '' }
      }
    } else {
      console.log('KOApi.live(name)方法，name参数未定义或没有值')
    }
  }
}

export default new KOApi()
