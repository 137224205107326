<template>
  <div
    class="layer-timeline station-map"
    v-if="MapRoot.timelineList?.length > 1"
    v-drag="true"
  >
    <SelectButton
      v-if="false"
      v-model:option="SelectDay.options"
      v-model:value="SelectDay.value"
      @select="EventSelect"
      class="m-select-day"
    ></SelectButton>
    <div class="row-line" v-if="false"></div>
    <MapTimelineDay
      :timelineList="MapRoot.timelineList"
      v-model:timelineIndex="MapRoot.timelineIndex"
      :allowPlay="MapRoot.loading == false"
      fmt="MM-DD"
    ></MapTimelineDay>
  </div>
</template>
<script>
import MapTimelineDay from '@/components/common/map/cpn/MapTimelineDay.vue'
import SelectButton from '@/components/common/map/cpn/mapTimelineDay/SelectButton.vue'
export default {
  inject: ['MapRoot'],
  components: { MapTimelineDay, SelectButton },
  props: {},
  data() {
    return {
      SelectDay: {
        options: [
          { name: '1小时', value: 1 },
          { name: '3小时', value: 3 },
          { name: '6小时', value: 6 },
          { name: '12小时', value: 12 },
          { name: '24小时', value: 24 }
        ],
        value: ''
      }
    }
  },
  watch: {},
  computed: {},
  mounted() {},
  methods: {
    EventSelect() {}
  }
}
</script>
<style lang="scss" scoped>
.layer-timeline {
  position: absolute;
  bottom: 50px;
  left: 50%;
  transform: translate(-50%, 0);
  display: flex;
  align-items: center;
  border-top: 1px solid #06605e;
  border-bottom: 1px solid #06605e;
  background: #071d40;
  .m-select-day {
    margin-right: 10px;
    cursor: pointer;
    display: flex;
  }
  .row-line {
    height: 30px;
    margin-right: 15px;
    border-left: 1px solid rgba(255, 255, 255, 0.4);
  }
}
</style>
