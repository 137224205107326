<template log="homeMap-包组件">
  <div class="cpn-map-home">
    <div
      :id="mapId"
      class="map-container"
      v-loading="mapLoadingArr?.length"
      :element-loading-text="mapLoadingText"
      element-loading-background="rgba(0, 0, 0, 0.5)"
    ></div>
    <!--基础-->
    <CNMapInit></CNMapInit>
    <CNMapZoomButton log="地图调整按钮"></CNMapZoomButton>
    <!--功能-->
    <div class="layer-map-left-bottom">
      <LayerImgLegend ref="RLayerImgLegend" log="地图图片贴图"></LayerImgLegend>
      <LayerChartPie></LayerChartPie>
    </div>
    <CNMapFooter :copyright="copyright" log="地图底部工具栏"></CNMapFooter>
    <!--通用图层-->
    <CNLayerMarkerPopup markerType="base"></CNLayerMarkerPopup>
    <CNLayerMarkerLegend></CNLayerMarkerLegend>
    <!--普通图层-->
    <LayerTopCenter></LayerTopCenter>
    <LayerTimeline></LayerTimeline>
    <LayerTitle></LayerTitle>
    <LayerLeft></LayerLeft>
    <LayerRight></LayerRight>
    <LayerViewPopupNone></LayerViewPopupNone>
    <LocatMarker v-if="false"></LocatMarker>
    <div class="m-top" v-if="false">
      {{ MapRoot.mapEvent.selectMultMarker }}
    </div>
  </div>
</template>
<script>
import CNMapZoomButton from '@/components/content/map/base/CNMapZoomButton.vue'
/*base*/
import CNMapInit from '@/components/content/map/base/CNMapInit.vue'
/*cpn*/
import CNMapFooter from '@/components/content/map/base/CNMapFooter.vue'
import LayerImgLegend from './groupMapHome/layer/LayerImgLegend.vue'
/*lay*/
import CNLayerMarkerPopup from '@/components/content/map/popup/CNLayerMarkerPopup.vue'
import CNLayerMarkerLegend from '@/components/content/map/popup/CNLayerMarkerLegend.vue'
/**/
import LayerTopCenter from './groupMapHome/layer/LayerTopCenter.vue'
import LayerTitle from './groupMapHome/layer/LayerTitle.vue'
import LayerTimeline from './groupMapHome/layer/LayerTimeline.vue'
import LayerLeft from './groupMapHome/layer/LayerLeft.vue'
import LayerRight from './groupMapHome/layer/LayerRight.vue'
import LayerChartPie from './groupMapHome/layer/LayerChartPie.vue'
import LayerViewPopupNone from '@/components/view/cpn/map/LayerViewPopupNone.vue'
import LocatMarker from './groupMapHome/setup/LocatMarker.vue'
import { getMapPlug } from '@/hooks/common/map/HooksMapInit.js'
import { HooksMapEvent } from '@/hooks/common/map/HooksMapEvent.js'
import { HooksHomeMapFeaturesAll } from '@/hooks/content/map/HooksHomeMapFeatures.js'
import MapMultMarker from '@/plug/map/openLayer/marker/MapMultMarker.js'
import { GetInfoMarkers } from '@/hooks/content/map/HooksHomeMapMultMarker.js'
import { getMarkerLegend } from '@/hooks/content/map/HooksMapConfig.js'
import { HooksSetMapLocatMarker } from '@/hooks/content/map/HooksHomeMapLocationMarker.js'
import { HooksHomeMapImgIsogram } from '@/hooks/content/map/img/HooksMapImgIsogram.js'
import { HooksHomeMapImgPlant } from '@/hooks/content/map/img/HooksMapImgPlant.js'
import { Info_baseCopyright } from '@/info/infoGarden.js'
export default {
  inject: ['AppRoot'],
  components: {
    CNMapInit,
    CNMapZoomButton,
    CNMapFooter,
    CNLayerMarkerPopup,
    CNLayerMarkerLegend,
    LayerImgLegend,
    LayerTopCenter,
    LayerTitle,
    LayerTimeline,
    LayerLeft,
    LayerRight,
    LayerChartPie,
    LayerViewPopupNone,
    LocatMarker
  },
  props: {},
  provide: function () {
    return {
      MapRoot: this.MapRoot
    }
  },
  data() {
    return {
      mapId: 'map-home-main',
      mapLoadingArr: ['地图加载中'],
      mapLoadingText: '地图加载中',
      markerList: [],
      satisticsInfo: false,
      MapRoot: {
        //地图共享数据集，子组件通过inject获取
        loading: false, //地图当前是否处于加载中
        mapPlug: false,
        areaCode: this.Api.areaCode,
        areaName: this.Api.areaName,
        dataRemark: {
          labelType: '站点',
          endText: '实况分布'
        },
        markerLegend: [],
        existMarkerType: [], //地图中存在的markerType
        checkView: {
          //显示设置，控制【分布图(流域)】中的弹层显示
          ImgPlant: false //图-种植分布
        },
        timelineList: [], //[{ name: '2023-03-13' }, { name: '2023-03-12' }]
        timelineIndex: 0,
        footer: {
          //底部配置
          layerView: {
            options: [{ name: '显示设置', value: 'viewSetup' }],
            values: []
          }
        },
        plantAreaChart: [], //种植区域面积统计
        mapImageIsogram: { imgUrl: false, bounds: [] }, //地图图片插值
        mapEvent: {
          //地图事件管理对象
          featureType: '', //当前点击的feature类别
          markerType: '', //当前点击的marker类别
          plottingScale: '', //地图分辨率
          mouseCoord: '', //鼠标移入地图停留的当前经纬度
          selectFeature: false, //地图点组件-当前点击的点
          selectMultMarker: false, //当前选中的MultMarker点
          mapViewType: '', // 地图组件预览类型：1.label
          selectLocat: false //当前点击的点经纬度
        },
        //方法
        onLoading: this.onLoading,
        offLoading: this.offLoading
      },
      copyright: ''
    }
  },
  watch: {
    'AppRoot.areaCode': {
      handler() {
        this.initMapMultMarker()
        this.initMapFeatures()
      }
    },
    'AppRoot.varietiesId': {
      handler() {
        this.MapRoot.mapEvent.selectMultMarker = false
        this.initMapMultMarker()
      }
    },
    'MapRoot.mapImageIsogram': {
      handler() {
        this.initMapImgIsogram()
      },
      deep: true
    },
    'MapRoot.mapEvent.selectLocat': {
      handler() {
        this.initMapLocatMarker()
      }
    },
    'MapRoot.markerLegend': {
      handler() {
        this.initPlugMapMultMarker()
      },
      deep: true
    }
  },
  computed: {},
  mounted() {
    this.init()
  },
  methods: {
    init() {
      //初始化地图。并获取地图plug
      this.MapRoot.mapPlug = getMapPlug({
        id: this.mapId,
        center: [this.AppRoot.center.lon, this.AppRoot.center.lat]
      })
      //定义地图事件
      this.MapRoot.mapEvent = HooksMapEvent(this.MapRoot.mapPlug)
      //绘制地图轮廓
      this.initMapFeatures()
      //hooks-绘制地图点
      this.initMapMultMarker()
      //hooks-绘制地图location点
      this.initMapLocatMarker()
      //图片插值
      this.initMapImgIsogram()
      //图片-种植区域
      this.initMapImgPlant()
      this.getData_copyright()
      this.offLoading()
      let viewMarkers = ['base', 'station']
      this.MapRoot.existMarkerType.push(...viewMarkers)
      //MapEvent(this.MapRoot) //这里处理map事件，并直接修改MapRoot中的值
    },
    initMapMultMarker() {
      //hooks-绘制地图点
      if (this.AppRoot.varietiesId) {
        GetInfoMarkers(this.AppRoot.areaCode, this.AppRoot.varietiesId).then(
          (markerList) => {
            this.markerList = markerList
            if (this.markerList?.length) {
              if (this.MapRoot.mapEvent.selectMultMarker === false) {
                this.MapRoot.mapEvent.markerType = 'base'
                this.MapRoot.mapEvent.selectMultMarker = this.markerList[0] //设置一个初始点
              }
              this.MapRoot.mapEvent.selectLocat = this.markerList[0]
            }
            this.MapRoot.markerLegend = getMarkerLegend(this.markerList, [
              'base'
            ]) //获取点列表的图例
            this.initPlugMapMultMarker(this.markerList)
          }
        )
      }
    },
    //绘制点到地图上
    initPlugMapMultMarker() {
      let mapPlug = this.MapRoot.mapPlug
      if (mapPlug?.map) {
        let options = {
          mapPlug: mapPlug,
          markerLegend: this.MapRoot.markerLegend,
          markerList: this.markerList,
          viewMarkerType: this.MapRoot.existMarkerType,
          markerOption: {
            textStyle: 'none',
            iconMarkerSize: [20, 23],
            labelOffsetY: -30
          }
        }
        if (mapPlug.MultMarker) {
          mapPlug.MultMarker.update(options)
        } else {
          mapPlug.MultMarker = new MapMultMarker(options)
        }
      }
    },
    //当前定位点
    initMapLocatMarker() {
      HooksSetMapLocatMarker(
        this.MapRoot.mapPlug,
        this.MapRoot.mapEvent.selectLocat
      )
    },
    initMapFeatures() {
      //绘制多边形特征
      //if (postData.areaCode.toString().length == 4) {
      HooksHomeMapFeaturesAll(this.MapRoot, this.AppRoot, this.AppRoot.areaCode)
      /*} else {
        HooksHomeMapFeaturesInner(this.MapRoot, postData)
      }*/
    },
    initMapImgIsogram() {
      //地图图片叠加
      HooksHomeMapImgIsogram(this.MapRoot)
      if (this.MapRoot.mapImageIsogram?.imgUrl) {
        this.$refs.RLayerImgLegend.init({
          colorCode: 'StageValue',
          name: '图例',
          contents: this.MapRoot.mapImageIsogram.legend
        })
      } else {
        this.$refs.RLayerImgLegend.init()
      }
    },
    initMapImgPlant() {
      //地图图片叠加-种植分布
      HooksHomeMapImgPlant(this.AppRoot, this.MapRoot)
    },
    onLoading(text) {
      this.MapRoot.loading = true
      this.mapLoadingArr.push(text)
      this.mapLoadingText = text ?? '数据加载中...'
    },
    offLoading() {
      this.MapRoot.loading = false
      this.mapLoadingArr.splice(0, 1)
      this.mapLoadingText = ''
    },
    getData_copyright() {
      Info_baseCopyright({
        region: this.AppRoot.areaCode
      }).then((ret) => {
        let copyImg = `<a
            target="_blank"
            href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=2023006902"
            class="global-copy-icon"
            ><img src="./img/copy.png" class="global-copy-icon" />京ICP备14045055号-3</a
          >`
        this.copyright = copyImg
        if (ret?.data?.list?.length) {
          this.copyright = copyImg + ret.data.list[0].contents
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.cpn-map-home {
  position: relative;
  height: 100%;
  .map-container {
    height: 100%;
    background: url('@/assets/img/home/map-bg.jpg') center/cover no-repeat;
  }
}
.m-top {
  position: absolute;
  top: 100px;
  left: 500px;
  background: #000;
}
.layer-map-left-bottom {
  position: absolute;
  left: 420px;
  bottom: 50px;
  display: flex;
  z-index: 10;
}
</style>
