<template>
  <div class="vpage-home">
    <div class="left-bar" v-if="menu?.length">
      <CpnMenu
        :menu="menu"
        v-model="AppRoot.varietiesId"
        @select="EventChange"
      ></CpnMenu>
    </div>
    <div class="right-main">
      <GroupHomeMap v-if="AppRoot.varietiesId"></GroupHomeMap>
      <div v-else class="ui-no-data">{{ msg }}</div>
    </div>
  </div>
</template>
<script>
import CpnMenu from '@/components/content/menu/CpnMenu.vue'
import GroupHomeMap from './pageHome/GroupHomeMap.vue'
import { Tree2Dict } from '@/api/func/DataPro.js'
import { Info_cropList } from '@/info/infoHome.js'
import { Info_areaTree } from '@/info/info.js'
export default {
  inject: ['AppRoot'],
  components: { CpnMenu, GroupHomeMap },
  data() {
    return {
      menu: [],
      areaTree: [],
      msg: '加载中...'
    }
  },
  computed: {},
  created() {
    this.init()
    this.getData()
  },
  watch: {
    'AppRoot.areaCode': {
      handler() {
        this.getData_areaTree()
        this.getData()
      }
    }
  },
  mounted() {},
  methods: {
    init() {
      this.AppRoot.areaCode = this.$route.query?.areaCode ?? '522700'
      this.getData_areaTree()
    },
    getData_areaTree() {
      Info_areaTree().then((ret) => {
        if (ret?.data?.length) {
          this.areaTree = ret.data
          this.setAreaTitle()
        }
      })
    },
    setAreaTitle() {
      const areaTree = this.areaTree
      let areaCode = this.AppRoot.areaCode
      let dict = Tree2Dict({
        tree: areaTree,
        dictKey: 'value',
        keys: ['name', 'value', 'remarks'] //需要转换的key字段
      })
      if (dict[areaCode]) {
        if (this.$route.path == '/homeCounty') {
          this.AppRoot.name = dict[areaCode].name + '智慧农业气象云平台'
        } else {
          this.AppRoot.name = dict[areaCode].name + '智慧农业气象云平台'
        }
        document.title = this.AppRoot.name
        this.AppRoot.areaName = dict[areaCode].name
        this.AppRoot.center = this.Api.jsonData(dict[areaCode].remarks)
      }
    },
    getData() {
      this.AppRoot.loading = true
      this.msg = '加载中...'
      Info_cropList({
        areaCode: this.Api.removeZero(this.AppRoot.areaCode)
      })
        .then((ret) => {
          this.AppRoot.loading = false
          let list = []
          if (ret?.data?.length) {
            for (let item of ret.data) {
              list.push({
                name: item.varietiesName,
                value: item.cropCode,
                varietiesId: item.varieties,
                disabled: !item.show,
                img: this.Api.getHostPath() + item.images
              })
            }
            let menu = list.sort((a, b) => {
              return a.disabled - b.disabled
            })
            this.menu = menu
            this.AppRoot.cropCode = list[0].value
            this.AppRoot.cropName = list[0].name
            this.AppRoot.varietiesId = list[0].varietiesId
          } else {
            this.menu = []
            this.AppRoot.cropCode = ''
            this.AppRoot.cropName = ''
            this.AppRoot.varietiesId = ''
            this.msg = '该区域暂无作物品种'
          }
        })
        .catch((err) => {
          this.Api.msg(err?.msg)
          this.msg = '该区域暂无作物品种'
        })
    },
    EventChange(item) {
      this.AppRoot.cropName = item.name
      this.AppRoot.cropCode = item.value
    }
  }
}
</script>
<style scoped lang="scss">
.vpage-home {
  display: flex;
  .left-bar {
    flex-shrink: 0;
    width: 100px;
    border-right: 1px solid #0f4766;
    background: #0c162c;
    overflow: auto;
  }
  .right-main {
    flex-grow: 1;
    width: 10px;
    .ui-no-data {
      height: 100%;
      font-size: 36px;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
</style>
