<template>
  <Swiper
    class="cpn-menu"
    :modules="modules"
    :slides-per-view="30"
    slidesPerView="auto"
    :freeMode="true"
    @swiper="initSwiper"
    :mousewheel="true"
    :scrollbar="{ draggable: true }"
    direction="vertical"
  >
    <template v-for="item in menu" :key="item">
      <SwiperSlide
        v-if="!item?.disabled"
        @click="EventClick_navLi(item)"
        class="li"
        :class="getClass(item)"
      >
        <div class="icon crop-item"><KOimg :src="item?.img"></KOimg></div>
        <div class="name">{{ item?.name }}</div>
      </SwiperSlide>
    </template>
    <SwiperSlide class="li">
      <div class="icon"><KOimg :src="moreCropImg"></KOimg></div>
    </SwiperSlide>
  </Swiper>
</template>
<script>
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Scrollbar, FreeMode, Mousewheel } from 'swiper'
import 'swiper/css/scrollbar'
import 'swiper/css/mousewheel'
export default {
  inject: [],
  components: { Swiper, SwiperSlide },
  props: {
    menu: {
      type: Array,
      default: () => []
    },
    modelValue: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    return {
      moreCropImg: require('@/assets/img/more.png'),
      modules: [Scrollbar, FreeMode, Mousewheel]
    }
  },
  watch: {},
  computed: {},
  mounted() {},
  methods: {
    getClass(item) {
      let cla = this.modelValue == item?.varietiesId ? 'current' : ''
      let dis = item?.disabled ? 'disabled' : ''
      return cla + ' ' + dis
    },
    EventClick_navLi(item) {
      this.$emit('update:modelValue', item.varietiesId)
      this.$emit('select', item)
    },
    initSwiper() {}
  }
}
</script>
<style lang="scss" scoped>
.cpn-menu {
  height: 100%;
  padding-top: 20px;
  box-sizing: border-box;
  user-select: none;
  .li {
    padding: 10px 0;
    margin-bottom: 20px;
    font-size: 16px;
    height: auto;
    text-align: center;
    .icon {
      &.crop-item {
        background: url('~@/assets/img/home/menu-bg.png') center bottom/80% auto
          no-repeat;
        cursor: pointer;
      }
      margin-bottom: 5px;
    }
    .ko-img {
      width: 50px;
      height: 50px;
      margin-left: auto;
      margin-right: auto;
    }
    &.disabled {
      filter: grayscale(100%);
      cursor: not-allowed;
      pointer-events: none;
    }
    &.current {
      .name {
        color: #0ff;
        font-weight: bold;
      }
      .icon {
        &.crop-item {
          background: url('~@/assets/img/home/menu-bg-hover.png') center
            bottom/80% auto no-repeat;
        }
      }
    }
  }
}
</style>
