import { ElMessage, ElMessageBox } from 'element-plus'
import overrideMessage from '../reFunc/element-message'
import TypeIs from '../TypeIs.js'

class KOApi {
  msg(ret) {
    if (ret?.debounce) {
      //显示第一个
      let doms =
        document.getElementsByClassName('el-message')[0] &&
        document.getElementsByClassName('el-message').length > 0
      if (doms === undefined) {
        // 判断它是不是存在
        if (TypeIs.String(ret)) {
          ElMessage({
            grouping: true,
            message: ret,
            type: 'warning'
          })
        } else {
          ElMessage(ret)
        }
      }
    } else {
      //显示最后一个
      if (TypeIs.String(ret)) {
        ElMessage({
          grouping: true,
          message: ret,
          type: 'warning'
        })
      } else if (TypeIs.Object(ret)) {
        ElMessage(ret)
      } else {
        //console.error('意料之外的msg类型，请传入String或Object类型', ret, this)
      }
    }
  }
  msgs(ret) {
    this.msg({
      message: ret,
      type: 'success'
    })
  }
  msge(ret) {
    this.msg({
      message: ret,
      type: 'error'
    })
  }
  error(ret) {
    if (ret.debounce) {
      //显示第一个
      let doms =
        document.getElementsByClassName('el-message')[0] &&
        document.getElementsByClassName('el-message').length > 0
      if (doms === undefined) {
        // 判断它是不是存在
        if (TypeIs.String(ret)) {
          ElMessage({
            message: ret,
            type: 'error'
          })
        } else {
          ElMessage(ret)
        }
      }
    } else {
      //显示最后一个
      if (TypeIs.String(ret)) {
        overrideMessage({
          message: ret,
          type: 'error'
        })
      } else {
        overrideMessage(ret)
      }
    }
  }
  alert(ret, callback) {
    if (TypeIs.String(ret) || TypeIs.Number(ret)) {
      ElMessageBox.confirm(ret, '', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'info'
      })
        .then(() => {
          callback(true)
        })
        .catch(() => {
          callback(false)
        })
    } else if (TypeIs.Object(ret)) {
      let def = {
        msg: '',
        alert: false,
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'info'
      }
      let opt = Object.assign({}, def, ret)
      if (opt.alert) {
        ElMessageBox.alert(opt.msg, '', opt)
          .then(() => {
            callback(true)
          })
          .catch(() => {
            callback(false)
          })
      } else {
        let message = ret.msg || ret.message
        ElMessageBox.confirm(message, '', opt)
          .then(() => {
            callback(true)
          })
          .catch(() => {
            callback(false)
          })
      }
    }
  }
  SortFun(attr, rev) {
    //用于element-table的sort排序
    //第一个参数传入info里的prop表示排的是哪一列，第二个参数是升还是降排序
    if (rev == undefined) {
      rev = 1
    } else {
      rev = rev ? 1 : -1
      //rev = rev>0 ? 1 : -1;
    }
    return (a, b) => {
      a = a[attr]
      b = b[attr]
      if (a < b) {
        return rev * -1
      }
      if (a > b) {
        return rev * 1
      }
      return 0
    }
  }
}
export default KOApi
