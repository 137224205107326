<template>
  <div class="m-select-wrap">
    <el-cascader
      class="m-select-area"
      v-model="selectCity_value"
      :props="cascaderProps"
      popper-class="is-dark"
      :filterable="true"
      size="default"
      :collapse-tags="true"
      :show-all-levels="false"
      :options="selectCity_options"
      @change="cascaderChange"
      ref="RCascaderArea"
    ></el-cascader>
  </div>
</template>
<script>
import Api from '@/api/Api.js'
import { Info_areaTree } from '@/info/info.js'
export default {
  components: {},
  props: {
    areaCode: {
      type: [Number, String],
      default: Api.getAreaCode()
    },
    areaName: {
      type: String,
      default: '河南省'
    }
  },
  data() {
    return {
      cascaderProps: {
        checkStrictly: true,
        label: 'areaName',
        value: 'areaCode',
        emitPath: false
      },
      selectCity_value: '',
      selectCity_options: []
    }
  },
  watch: {},
  computed: {},
  created() {
    this.initData()
  },
  mounted() {},
  methods: {
    initData() {
      this.selectCity_value = Api.getAreaCode() ?? Api.areaCode
      Info_areaTree({ areaCode: this.selectCity_value }).then((ret) => {
        if (ret && ret.data) {
          this.reData_tree(ret.data)
          this.selectCity_options = ret.data
          let item = this.Api.findItem({
            arr: ret.data,
            idKey: 'areaCode',
            value: this.selectCity_value
          })
          if (item) {
            this.$emit('update:areaCode', item.areaCode)
            this.$emit('update:areaName', item.areaName)
            this.$emit('change', {
              areaCode: item.areaCode,
              areaName: item.areaName
            })
          } else {
            console.error('区域option中未找到：', this.selectCity_value)
          }
        } else {
          Api.msg('数据获取失败')
        }
      })
    },
    reData_tree(ret) {
      for (let item of ret) {
        if (item.children && item.children.length) {
          this.reData_tree(item.children)
        } else {
          delete item.children
        }
      }
    },
    cascaderChange() {
      let nodes = this.$refs.RCascaderArea.getCheckedNodes()
      this.$refs.RCascaderArea.dropDownVisible = false
      this.$emit('update:areaCode', nodes[0].value)
      this.$emit('update:areaName', nodes[0].label)
      this.$emit('change', {
        areaCode: nodes[0].value,
        areaName: nodes[0].label
      })
    }
  }
}
</script>
<style scoped lang="scss">
.m-select-area {
  display: block;
}
</style>
