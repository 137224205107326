<template>
  <div class="layout-box-title">
    <slot>
      <div class="title">{{ title }}</div>
      <div class="sub-title">{{ subTitle }}</div>
    </slot>
  </div>
</template>
<script>
export default {
  inject: [],
  components: {},
  props: {
    title: {
      type: String,
      default: ''
    },
    subTitle: {
      type: String,
      default: ''
    }
  },
  data() {
    return {}
  },
  watch: {},
  computed: {},
  mounted() {},
  methods: {}
}
</script>
<style lang="scss" scoped>
.layout-box-title {
  position: relative;
  padding: 8px 15px;
  color: #d9e5fa;
  background: linear-gradient(
    90deg,
    #0a3165,
    rgba(217, 229, 250, 0),
    rgba(217, 229, 250, 0)
  );
  display: flex;
  .title {
    font-size: 16px;
    font-weight: bold;
    flex-grow: 1;
  }
  .sub-title {
    color: rgba(255, 255, 255, 0.7);
  }
  &::before {
    position: absolute;
    left: 0;
    top: 7px;
    bottom: 7px;
    border-left: 3px solid #00c6ff;
    content: '';
    display: block;
  }
}
</style>
