<!--timeline-->
<template>
  <ul class="cpn-timeline">
    <li
      v-for="(item, ind) in timeline"
      :class="ind == index ? 'current' : ''"
      :key="item"
      @click="EventClick_timeline(item, ind)"
    >
      <div class="box">
        <div class="top-date" v-if="false">
          {{ item.name?.Format('MM-DD hh:mm') }}
        </div>
        <div class="name">{{ reValue(item) }}</div>
        <div class="oben"></div>
        <div class="handler"></div>
      </div>
    </li>
  </ul>
</template>
<script>
export default {
  components: {},
  inject: ['MapRoot'],
  model: {
    prop: 'index'
  },
  props: {
    timeline: {
      type: Array,
      default: () => {
        return []
      }
    },
    index: {
      type: [String, Number],
      default: () => {
        return 0
      }
    },
    fmt: {
      type: String,
      default: 'MM-DD'
    }
  },
  data() {
    return {}
  },
  watch: {},
  computed: {
    mapStore() {
      return this.$store.state[this.StoreMap]
    },
    progressValue() {
      return (this.index / (this.timeline.length - 1)) * 100
    },
    legendDict() {
      return this.mapStore.currentTile.legendDict
    }
  },
  mounted() {},
  methods: {
    handleCommand(ret) {
      this.$emit('selected', ret)
    },
    EventClick_timeline(item, index) {
      this.$emit('update:index', index)
      this.$emit('change', item, index)
    },
    mousedown(event) {
      var startx = event.x
      var starty = event.y
      this.sb_bkx = startx - event.target.offsetLeft
      this.sb_bky = starty - event.target.offsetTop
      this.is_moving = true
    },
    mousemove(event) {
      let index = event.target.dataset.index
      if (this.is_moving && index >= 0) {
        this.$emit('update:index', parseInt(index))
        this.$emit('EventOut_change', this.timeline[index])
      }
    },
    mouseup() {
      this.is_moving = false
    },
    getStyle(item) {
      if (item?.ganhan && item.product == 1) {
        return 'background:' + this.legendDict[item.ganhan].color
      } else {
        return ''
      }
    },
    initSwiper() {},
    reValue(item) {
      let date = item.name?.Format(this.fmt)
      if (date == new Date().Format(this.fmt)) {
        return date + '(今天)'
      } else {
        return item.name?.Format(this.fmt)
      }
    }
  }
}
</script>
<style scoped lang="scss">
.cpn-timeline {
  position: relative;
  display: flex;
  li {
    width: 100px;
    cursor: pointer;
    .box {
      position: relative;
      .top-date {
        position: absolute;
        left: 50%;
        bottom: 25px;
        transform: translate(-50%, 0);
        background: #08376d;
        padding: 5px 8px;
        border-radius: 5px;
        display: none;
      }
      .name {
        height: 28px;
        line-height: 28px;
        font-size: 14px;
        text-align: center;
        word-break: keep-all;
        white-space: nowrap;
      }
      .line {
        position: absolute;
        top: 2px;
        left: 50%;
        bottom: 2px;
        border-right: 1px solid rgba(255, 255, 255, 0.6);
      }
      .oben {
        display: none;
      }
      .handler {
        position: absolute;
        height: 30px;
        top: 0;
        left: 0;
        right: 0;
      }
    }
    &:hover {
      .line {
        border-right: 1px solid rgba(0, 255, 255, 0.5);
      }
      .num {
        color: rgba(0, 255, 255, 0.8);
      }
    }
    &.current {
      color: #0ff;
      .box {
        &::before {
          display: block;
          position: absolute;
          bottom: -5px;
          content: '';
          width: 100%;
          height: 12px;
          background: url('~@/assets/img/icon/timeline-current.png')
            center/contain no-repeat;
        }
        .top-date {
          display: block;
          word-break: keep-all;
          white-space: nowrap;
          &:after {
            content: '';
            display: block;
            position: absolute;
            left: 50%;
            top: 100%;
            margin-left: -5px;
            margin-top: -1px;
            border-width: 5px;
            display: block;
            width: 0;
            height: 0;
            border-style: solid;
            border-color: #08376d transparent transparent transparent;
          }
        }
        .oben {
          position: absolute;
          left: 50%;
          top: -12px;
          display: block;
          width: 14px;
          height: 16px;
          background: url('~@/assets/img/icon/icon-timeline.png') center/contain
            no-repeat;
          border-radius: 50%;
          transform: translate(-50%, 0);
        }
      }
    }
  }
}
</style>
