<template>
  <div class="ui-close"></div>
</template>
<script>
export default {
  name: 'UiClose',
  inject: [],
  components: {},
  props: {},
  data() {
    return {}
  },
  watch: {},
  computed: {},
  mounted() {},
  methods: {}
}
</script>
<style lang="scss" scoped>
.ui-close {
  position: absolute;
  top: 10px;
  right: 10px;
  line-height: 1;
  font-size: 24px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  &:hover {
    color: #0ff;
    cursor: pointer;
  }
}
</style>
