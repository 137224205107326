import { DefAjax } from '../service/ajax.js'
//import Api from '@/api/Api.js'

/**
 * 查询所有作物
 * @param {Object} ret.areaCode
 * @returns
 */
export function Info_cropList(ret) {
  let def = {
    areaCode: 5227
  }
  let opt = Object.assign({}, def, ret)
  return DefAjax({
    morePost: true,
    method: 'get',
    url: '/source/api/dp/cropList',
    params: opt
  })
}

/**
 * 通过作物和areaCode查询基地
 * @param {String,Number} ret.areaCode
 * @param {String} ret.cropCode
 * @returns
 */
export function Info_baseList(ret) {
  let def = {
    areaCode: 5227,
    varieties: ''
  }
  let opt = Object.assign({}, def, ret)
  return DefAjax({
    morePost: true,
    method: 'get',
    url: '/source/api/dp/companyBaseList',
    params: opt
  })
}

/**
 * 通过作物和areaCode查询价格行情
 * @param {String,Number} ret.areaCode
 * @param {String} ret.cropCode
 * @param {String} ret.varieties
 * @returns
 */
export function Info_cropPrice(ret) {
  let def = {
    cropCode: 100001,
    varieties: ''
  }
  let opt = Object.assign({}, def, ret)
  return DefAjax({
    postType: 'formData',
    method: 'post',
    url: '/source/api/analysis/quotationList',
    data: opt
  })
}

/**
 * 通过作物和areaCode查询产品类别
 * @param {String,Number} ret.areaCode
 * @param {String} ret.cropCode
 * @returns
 */
export function Info_cropProductType(ret) {
  let def = {
    areaCode: 522700,
    cropCode: '',
    preType: ''
  }
  let opt = Object.assign({}, def, ret)
  return DefAjax({
    method: 'get',
    url: '/source/api/dp/crop/index/product/type',
    params: opt
  })
}

/**
 * 通过作物和areaCode、产品类别查询产品列表
 * @param {String,Number} ret.areaCode
 * @param {String} ret.varieties //品种id
 * @param {String,Number} ret.type //1:灾害，3：生长适宜度
 * @param {String,Number} ret.pageNo //1
 * @param {String,Number} ret.pageSize //10
 * @returns
 */
export function Info_cropProductList(ret) {
  let def = {
    areaCode: 522700,
    cropCode: '',
    varieties: '',
    type: 3,
    pageNo: 1,
    pageSize: 10
  }
  let opt = Object.assign({}, def, ret)
  return DefAjax({
    morePost: true,
    method: 'get',
    url: '/source/api/dp/crop/index/product/list',
    params: opt
  })
}

/**
 * 通过作物和areaCode、经纬度查询作物服务产品适宜
 * @param {String,Number} ret.areaCode
 * @param {String,Number} ret.cropCode
 * @param {String,Number} ret.lon
 * @param {String,Number} ret.lat
 * @param {String} ret.dateTime
 * @returns
 */
export function Info_suitabilityByLonlat(ret) {
  let def = {
    areaCode: 522700,
    cropCode: '',
    varieties: '',
    lon: 1,
    lat: 1,
    dateTime: '2023-03-01' //
  }
  let opt = Object.assign({}, def, ret)
  return DefAjax({
    morePost: true,
    method: 'get',
    url: '/source/api/dp/crop/index/product/suitability/info',
    params: opt
  })
}

/**
 * 通过作物和areaCode、经纬度查询作物信息和生育期信息
 * @param {String,Number} ret.areaCode
 * @param {String,Number} ret.cropCode
 * @param {String,Number} ret.varieties
 * @param {String,Number} ret.lon
 * @param {String,Number} ret.lat
 * @param {String} ret.dateTime
 * @returns
 */
export function Info_cropInfoByLonlat(ret) {
  let def = {
    areaCode: 522700,
    cropCode: '',
    varieties: '',
    lon: 1,
    lat: 1
  }
  let opt = Object.assign({}, def, ret)
  return DefAjax({
    morePost: true,
    method: 'get',
    url: '/source/api/dp/crop/growth',
    params: opt
  })
}

/**
 * 通过作物和areaCode、经纬度查询作物信息和生育期信息
 * @param {String,Number} ret.areaCode
 * @param {String} ret.type
 * @returns
 */
export function Info_stationList(ret) {
  let def = {
    areaCode: 522700,
    type: 6
  }
  let opt = Object.assign({}, def, ret)
  return DefAjax({
    method: 'get',
    url: '/source/api/station/all/list',
    params: opt
  })
}
