<template>
  <div class="layout-star-icon" :style="{ '--color-suit': color }">
    <i
      v-for="index of max"
      :key="index"
      class="icon"
      :class="getClass(index)"
    ></i>
  </div>
</template>
<script>
export default {
  inject: [],
  components: {},
  props: {
    max: {
      type: Number,
      default: 5
    },
    modelValue: {
      type: Number,
      default: 4
    },
    icon: {
      type: String,
      default: 'shengchangshiyidu icon-cb'
    },
    color: {
      type: String,
      default: '#97fd82'
    }
  },
  data() {
    return {}
  },
  watch: {},
  computed: {},
  mounted() {},
  methods: {
    getClass(index) {
      let iconClass = this.icon
      return iconClass + ' ' + (this.modelValue >= index ? 'select' : '')
    }
  }
}
</script>
<style lang="scss" scoped>
.layout-star-icon {
  display: flex;
  i.icon {
    margin: 0 3px;
    &.select {
      color: var(--color-suit);
    }
  }
}
</style>
