import { Info_cropInfoByLonlat } from '@/info/infoHome.js'

export function InterCropGrowth(areaCode, varietiesId, lon, lat) {
  return new Promise((res) => {
    let postData = {
      areaCode: areaCode,
      lon: lon,
      lat: lat,
      varieties: varietiesId
    }
    getData_cropInfo(postData).then((data) => {
      res(data)
    })
  })
}
function getData_cropInfo(postData) {
  return new Promise((res) => {
    Info_cropInfoByLonlat(postData)
      .then((ret) => {
        res(ret)
      })
      .catch((err) => {
        console.log(err)
      })
  })
}
