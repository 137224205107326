import LayerImg from '@/plug/map/openLayer/layer/LayerImg.js'
import { watch } from 'vue'
export function HooksHomeMapImgPlant(AppRoot, MapRoot) {
  let bounds = [106.2, 25.07, 108.3, 27.49]
  let img = require('@/assets/img/test/iso.png')
  if (MapRoot.mapPlug?.PlugImgLayersPlant) {
    MapRoot.mapPlug.PlugImgLayersPlant.addImgLayer(img)
  } else {
    MapRoot.mapPlug.PlugImgLayersPlant = new LayerImg({
      mapPlug: MapRoot.mapPlug,
      extent: bounds,
      imgs: []
    })
    if (MapRoot.checkView.ImgPlant) {
      MapRoot.mapPlug.PlugImgLayersPlant.addImgLayer(img)
    }
    watch(MapRoot.checkView, (val) => {
      if (val.ImgPlant) {
        MapRoot.mapPlug.PlugImgLayersPlant.addImgLayer(img)
      } else {
        MapRoot.mapPlug.PlugImgLayersPlant.hiddenLayers()
      }
    })
  }
}
