<template log="EChart-数据纠错和二次处理组件:用于对组装好的option进行纠错">
  <div class="m-chart-view">
    <NoData v-if="!hasData" :msg="msg" type="text"></NoData>
    <EchartsInit
      v-else-if="hasData"
      v-model:option="optionData"
      ref="REChart"
    ></EchartsInit>
  </div>
</template>
<script>
import EchartsInit from '@/components/common/chart/EchartsInit.vue'
import NoData from '@/components/common/other/NoData.vue'
import commonOpt from '@/plug/chart/pie/options.js'
import TypeIs from '@/api/TypeIs.js'
export default {
  components: {
    NoData,
    EchartsInit
  },
  props: {
    msg: {
      type: String
    }
  },
  data() {
    return {
      optionData: {},
      hasData: false
    }
  },
  computed: {},
  mounted() {},
  methods: {
    reData(option) {
      //数据纠错
      let ret = this.Api.extend(commonOpt, option)
      return ret
    },
    initData(option) {
      if (option) {
        let optionData = this.reData(option)
        let tempData = '-'
        if (optionData?.series?.length) {
          for (let serie of optionData.series) {
            tempData = this.getSerieValue(serie.data)
            break
          }
        }
        if (tempData === '-') {
          this.hasData = false
          return
        } else {
          this.hasData = true
        }
        if (this.hasData) {
          this.optionData = optionData
        }
      } else {
        console.error('ChartBarView.vue未传入chart-option')
      }
    },
    reOption(option) {
      return option
    },
    getSerieValue(serieData) {
      for (let item of serieData) {
        if (TypeIs.Object(item)) {
          if (item.value !== '-') {
            return item.value
          }
        } else {
          if (item !== '-') {
            return item
          }
        }
      }
      return '-'
    }
  }
}
</script>
<style lang="scss" scoped>
.m-chart-view {
  height: 100%;
}
</style>
