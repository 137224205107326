<!--timeline style-->
<template>
  <div class="m-timeline-play">
    <h2 @click="EventClick_to(-1)" class="m-button btn" v-if="false">
      <i class="icon-31fanhui1 iconfont"></i>
    </h2>
    <h2 @click="EventClick_play" class="m-button btn">
      {{ play ? '暂停' : '播放' }}
      <i
        v-if="false"
        class="iconfont"
        :class="play ? 'icon-zantingtingzhi' : 'icon-bofang'"
      ></i>
    </h2>
    <h2 @click="EventClick_to(1)" class="m-button btn" v-if="false">
      <i class="icon-31fanhui2 iconfont"></i>
    </h2>
  </div>
</template>
<script>
export default {
  components: {},
  model: {},
  props: {
    play: {
      type: Boolean,
      default() {
        return false
      }
    }
  },
  data() {
    return {}
  },
  watch: {},
  computed: {},
  mounted() {},
  methods: {
    EventClick_play() {
      this.$emit('update:play', !this.play)
      this.$emit('onPlay', this.play)
    },
    EventClick_to(ret) {
      this.$emit('go', ret)
    }
  }
}
</script>
<style scoped lang="scss">
.m-timeline-play {
  display: flex;
  .btn {
    i {
      font-size: 14px;
    }
  }
  .m-button {
    display: block;
    width: 34px;
    height: 30px;
    line-height: 30px;
    text-align: center;
    color: #0ff;
    cursor: pointer;
  }
  .m-button:hover {
    color: #00b5ff;
  }
}
</style>
