import { ref } from 'vue'
import OMU from '@/plug/map/openLayer/utils.js'
/**
 * ol地图所有事件
 * @param {*} MapPlug
 * @returns
 */
export function HooksMapEvent(MapPlug) {
  let map = MapPlug.map
  let plottingScale = ref('')
  let featureType = ref('')
  let markerType = ref('')
  let selectLocat = ref(false)
  let selectFeature = ref(false)
  let selectMultMarker = ref(false)
  let currentMarkerSensor = ref(false)
  let currentMarkerCamera = ref(false)

  let mouseCoord = ref([])
  MapPlug.view?.on('propertychange', (e) => {
    if (e.key == 'resolution') {
      //地图分辨率
      plottingScale.value = OMU.getMapScale(MapPlug.view)
    }
  })
  map?.on('pointermove', (e) => {
    //鼠标移入地图停留的当前经纬度
    mouseCoord.value = MapPlug.toLonLat(e.coordinate)
  })
  map?.on('click', (e) => {
    markerType.value = ''
    featureType.value = ''
    selectFeature.value = false
    map.forEachFeatureAtPixel(e.pixel, (feature) => {
      if (feature.featureType == 'featureMultMarker') {
        selectMultMarker.value = feature.data
      }
      markerType.value = feature.data?.markerType ?? ''
      featureType.value = feature.featureType
      selectFeature.value = feature.data
      let coord = MapPlug.toLonLat(e.coordinate)
      let sensorKeys = ['wuyuanSensor', 'farmlandSensor', 'sensor']
      if (sensorKeys.includes(feature.data?.markerType)) {
        currentMarkerSensor.value = feature.data
      }
      if (feature.data?.markerType == 'camera') {
        currentMarkerCamera.value = feature.data
      }
      if (feature.data?.markerType == 'locat') {
        selectLocat.value = feature.data
      } else if (!markerType.value) {
        selectLocat.value = { lon: coord[0], lat: coord[1] }
      }
      /*if (feature.data?.lon && feature.data?.lat) {
        MapPlug.panTo(feature.data)
      }*/
      //当前点击的点类型
      return true
    })
  })
  return {
    plottingScale,
    featureType,
    markerType,
    selectFeature,
    mouseCoord,
    selectLocat,
    selectMultMarker,
    currentMarkerSensor,
    currentMarkerCamera
  }
}
