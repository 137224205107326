import { DefAjax } from '../service/ajax.js'
/**
 *
 * @param dictKey //字典key
 * @returns
 */
export function Info_dictOption(ret) {
  let def = {
    dictKey: ''
  }
  let opt = Object.assign({}, def, ret)
  return DefAjax({
    morePost: true,
    url: '/source/api/dict/dictList',
    params: opt
    //load: 'mock'
  })
}

/**
 * @查询平台区域树
 */
export function Info_areaTree(ret) {
  let def = { areaCode: 5227 }
  let opt = Object.assign({}, def, ret)
  return DefAjax({
    morePost: true,
    postType: 'formData',
    method: 'post',
    url: '/source/api/area/arealist',
    data: opt
  })
}

/**
 * @接口转发_专家列表
 */
export function Info_expertList(ret) {
  let def = {
    areaCode: '',
    cropCode: '',
    companyBaseId: '',
    pageNo: 1,
    pageSize: 10
  }
  let opt = Object.assign({}, def, ret)
  let postData = opt
  const infoAjax = {
    method: 'get',
    url: '/source/api/expert/list',
    params: postData
  }
  return DefAjax(infoAjax)
}

/**
 * @接口转发_彩云天气
 */
export function Info_requestForwarding(ret) {
  let def = {
    tokenKey: '',
    url: '',
    save: true
  }
  let opt = Object.assign({}, def, ret)
  let postData = opt
  const infoAjax = {
    morePost: true,
    save: opt.save,
    method: 'get',
    url: '/source/api/caiyun/requestForwarding',
    params: postData
  }
  return DefAjax(infoAjax)
}
