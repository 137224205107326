import MapUtils from '@/plug/map/openLayer/utils.js'
import AddPolygon from './AddPolygon.js'
import FeatureLabel from '@/plug/map/openLayer/marker/FeatureLabel.js'
import {
  FeatureStyle,
  reData_featuresMarker
} from '@/hooks/content/map/HooksMapWork.js'

/**
 * 绘制孩子集合轮廓
 * @param {Object} mapPlug //mapPlug对象
 * @param {Object} geoJson //geoJson={features:[]}
 */
export function setMapInnerFeatures(mapPlug, geoJson, style) {
  let plug = mapPlug.FeatureInnerPlug
  if (geoJson?.features?.length) {
    //绘制内轮廓
    if (plug) {
      plug.update({ data: geoJson })
    } else {
      mapPlug.FeatureInnerPlug = new AddPolygon({
        mapPlug: mapPlug,
        data: geoJson,
        defaultStyle: style ? style : FeatureStyle.innerPoly,
        selectStyle: style ? style : FeatureStyle.innerPoly,
        zIndex: 21
      })
    }
  } else {
    plug?.update({ data: geoJson })
  }
}

/**
 * 绘制外轮廓
 * @param {Object} mapPlug //mapPlug对象
 * @param {Object} geoJson //geoJson={features:[]}
 */
export function setMapOuterFeatures(mapPlug, geoJson) {
  let plug = mapPlug.FeatureOuterPlug
  if (geoJson?.features?.length) {
    //绘制内轮廓
    if (plug) {
      plug.update({ data: geoJson })
    } else {
      mapPlug.FeatureOuterPlug = new AddPolygon({
        mapPlug: mapPlug,
        data: geoJson,
        defaultStyle: FeatureStyle.outerPoly,
        selectStyle: FeatureStyle.outerPoly,
        zIndex: 20
      })
    }
  } else {
    plug.update({ data: geoJson })
    //console.error('内轮廓数据为空', geoJson)
  }
}
/**
 * 绘制feature标签
 * @param {Object} geoJson //geoJson={features:[]}
 */
export function setFeatureLabel(mapPlug, geoJson) {
  //使用特征api展示label数据
  if (geoJson.features?.length) {
    MapUtils.setCenter_byFeatures(geoJson.features, true)
  }
  let options = {
    mapPlug: mapPlug,
    labels: reData_featuresMarker(geoJson?.features) //对象-数组：点经纬度数组，必有字段：lon，lat
  }
  if (mapPlug) {
    if (mapPlug.PlugFeatureName) {
      mapPlug.PlugFeatureName.update(options)
    } else {
      mapPlug.PlugFeatureName = new FeatureLabel(options)
    }
  }
}
