import Api from '@/api/Api.js'
//图例颜色库
export const colors = [
  '#FFAA00',
  '#5C7BD9',
  '#4BBE9E',
  '#F68E42',
  '#23C4E0',
  '#71AB2C',
  '#c23531',
  '#2f4554',
  '#61a0a8',
  '#d48265',
  '#91c7ae',
  '#749f83',
  '#ca8622',
  '#bda29a',
  '#6e7074',
  '#546570',
  '#c4ccd3'
]

/**
 * 获取点的图例
 * @param {Array} list //数据列表
 * @param {Array} viewTypes //显示列表,可选：如果传了，则只显示传入的markerType，否则全部显示
 * @returns
 */
export function getMarkerLegend(list, viewTypes) {
  let markerLegend = [],
    dict = {}
  let i = 0
  if (list?.length) {
    let visible = true
    for (let item of list) {
      if (typeof dict[item.markerType] == 'undefined') {
        dict[item.markerType] = true
        if (viewTypes?.length) {
          if (viewTypes.includes(item.markerType)) {
            visible = true
          } else {
            visible = false
          }
        }
        markerLegend.push({
          name: getMarkerName(item),
          key: item.markerType,
          color: item.color ? item.color : colors[i++],
          icon: item.icon,
          visible: visible
        })
      }
    }
  } else {
    console.error('未传入marker列表：', list)
  }

  return markerLegend
}

/**
 * 获取点的名称，这里需要配置所有可能会绘制到底图上的类别/名称
 * @param {Object} item //markerList循环出来的item对象
 * @returns
 */
export function getMarkerName(item) {
  let dict = {
    cropland: '示范田',
    camera: '高清视频监控',
    gardenBase: '农业示范基地',
    station: '站点',
    wuyuanSensor: '无源监测仪',
    farmlandSensor: '农田小气候仪',
    base: '基地',
    locat: '点'
  }
  if (dict[item.markerType]) {
    return dict[item.markerType]
  } else {
    console.log(dict, '中不存在的markerType', item.markerType)
    return ''
  }
}

/**
 * 重组marker列表，加入markerType和名称
 * @param {Array} ret.list //接口给的列表原数据
 * @param {String} ret.nameKey //需要用作markerLabel的key字段
 * @param {String} ret.type //需要加入的类别字段
 * @returns
 */
export function getMarkerList(ret) {
  let def = {
    list: [],
    type: 'base',
    nameKey: 'name',
    color: '', //图标颜色
    icon: '' //图标使用图片
  }
  let opt = Api.extend(def, ret)
  let markerList = []
  if (opt.list?.length) {
    for (let item of opt.list) {
      let obj = {
        ...item,
        markerType: opt.type,
        markerLabel: item[opt.nameKey]
      }
      if (opt.icon) {
        obj.icon = opt.icon
      }
      if (opt.color) {
        obj.color = opt.color
      }
      markerList.push(obj)
    }
  }
  return markerList
}
