import TypeIs from '@/api/TypeIs.js'
let Color = function Color(r, g, b) {
  this.r = Math.abs(r)
  this.g = Math.abs(g)
  this.b = Math.abs(b)
  if (typeof r === 'string') {
    var v = hexToArr(r)
    this.r = v[0]
    this.g = v[1]
    this.b = v[2]
  }
}
Color.prototype.diff = function (c) {
  return new Color(this.r - c.r, this.g - c.g, this.b - c.b)
}
Color.prototype.dividedBy = function (n) {
  return new Color(this.r / n, this.g / n, this.b / n)
}
Color.prototype.approach = function (c, c2) {
  return new Color(
    this.r > c.r ? this.r - c2.r : this.r + c2.r,
    this.g > c.g ? this.g - c2.g : this.g + c2.g,
    this.b > c.b ? this.b - c2.b : this.b + c2.b
  )
}
Color.prototype.toHex = function () {
  return (
    '#' +
    pad(Math.round(this.r).toString(16)) +
    pad(Math.round(this.g).toString(16)) +
    pad(Math.round(this.b).toString(16))
  )
}
// rgb转16进制
export function rgbToHex(color) {
  var arr = [],
    strHex
  if (/^(rgb|RGB)/.test(color)) {
    arr = color.replace(/(?:\(|\)|rgb|RGB)*/g, '').split(',')
    strHex = arrToHex(arr)
  } else {
    console.error(color + '不是标准的rgb颜色格式，无法转换为hex格式的颜色')
    strHex = color
  }
  return strHex
}

// arr转16进制
export function arrToHex(arr) {
  return (
    '#' +
    ((1 << 24) + (arr[0] << 16) + (arr[1] << 8) + parseInt(arr[2]))
      .toString(16)
      .substring(1)
  )
}
// 10进制转16进制
function hex(x) {
  return ('0' + parseInt(x).toString(16)).slice(-2)
}
// rgba转16进制
export function rgbaToHex(rgba) {
  var arr = rgba2Arr(rgba)
  return ('#' + hex(arr[0]) + hex(arr[1]) + hex(arr[2])).toUpperCase()
}
// rgba转数组
export function rgba2Arr(rgbStr) {
  var Arr = rgbStr.match(
    /^rgba\((\d{1,3}),\s*(\d{1,3}),\s*(\d{1,3}),\s*(\d*(?:\.\d+)?)\)$/
  )
  return Arr
    ? [Number(Arr[1]), Number(Arr[2]), Number(Arr[3]), Number(Arr[4])]
    : []
}
export function hex2Rgb(color) {
  //16进制转rgba
  let sColor = color.toLowerCase()
  //十六进制颜色值的正则表达式
  let reg = /^#([0-9a-fA-f]{3}|[0-9a-fA-f]{6})$/
  // 如果是16进制颜色
  if (sColor && reg.test(sColor)) {
    if (sColor.length === 4) {
      let sColorNew = '#'
      for (let i = 1; i < 4; i += 1) {
        sColorNew += sColor.slice(i, i + 1).concat(sColor.slice(i, i + 1))
      }
      sColor = sColorNew
    }
    //处理六位的颜色值
    let sColorChange = []
    for (let i = 1; i < 7; i += 2) {
      sColorChange.push(parseInt('0x' + sColor.slice(i, i + 2)))
    }
    return sColorChange
  }
  return sColor
}

//颜色转数组
export function colorToArr(color) {
  if (TypeIs.String(color)) {
    let sColor = color.toLowerCase()
    let reg = /^#([0-9a-fA-f]{3}|[0-9a-fA-f]{6})$/
    if (/^(rgb|RGB)/.test(color)) {
      return color.replace(/(?:\(|\)|rgb|RGB)*/g, '').split(',')
    } else if (sColor && reg.test(sColor)) {
      return hexToArr(color)
    } else if (/^(rgba|RGBA)/.test(color)) {
      return rgba2Arr(color)
    }
  } else {
    return color
  }
}

//颜色转hex
export function color2Hex(color) {
  if (TypeIs.String(color)) {
    let sColor = color.toLowerCase()
    let regHex = /^#([0-9a-fA-f]{3}|[0-9a-fA-f]{6})$/
    if (sColor && regHex.test(sColor)) {
      //hex
      return color
    } else if (/^(rgba|RGBA)/.test(color)) {
      //rgba
      return rgbaToHex(color)
    } else if (/^(rgb|RGB)/.test(color)) {
      //rgb
      return rgbToHex(color)
    }
  } else if (TypeIs.Array(color)) {
    //arr
    return arrToHex(color)
  } else {
    return color
  }
}

//纠正hex颜色，把3位的纠正为6位
export function modifyHex(hex) {
  if (hex) {
    if (hex.length == 4) {
      hex = hex.replace('#', '')
    }
    if (hex.length == 3) {
      return '#' + hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2]
    } else {
      return hex
    }
  } else {
    return hex
  }
}

//纠正hex颜色，把3位的纠正为6位，但是去掉#号
export function reModifyHex(hex) {
  if (hex) {
    if (hex.length == 4) {
      hex = hex.replace('#', '')
    }
    if (hex.length == 3) {
      hex = hex[0] + hex[0] + hex[1] + hex[1] + hex[2] + hex[2]
    }
    return hex
  } else {
    return hex
  }
}
//hex颜色转数组格式
export function hexToArr(hex) {
  let x = []
  hex = hex.replace('#', '')
  if (hex.length != 6) {
    hex = reModifyHex(hex)
  }
  x.push(parseInt(hex.slice(0, 2), 16))
  x.push(parseInt(hex.slice(2, 4), 16))
  x.push(parseInt(hex.slice(4, 6), 16))
  return x
}

export function pad(n) {
  var str = '' + n
  var pad = '00'
  return pad.substring(0, pad.length - str.length) + str
}

export function colorsBetween(c1, c2, n) {
  c1 = new Color(c1)
  c2 = new Color(c2)
  var diff = c1.diff(c2).dividedBy(n + 1)
  var out = [c1]
  for (var i = 0; i < n; i++) {
    out.push(out[i].approach(c2, diff))
  }
  out.push(c2)
  return out.map(function (c) {
    return c.toHex()
  })
}

//['#F09','#FFF'] 颜色数组转换成css渐变色的style
export function colors2css(colors) {
  let colorStr = ''
  let leng = colors.length
  for (let i = 0; i < leng; i++) {
    let colorVal = ''
    if (colors[i]) {
      colorVal = colors[i]
    } else {
      colorVal = 'transparent'
    }
    colorStr += colorVal + ' ' + (i / (leng - 1)) * 100 + '%'
    if (i < leng - 1) {
      colorStr += ', '
    }
  }
  let bgStyle = 'linear-gradient(90deg,' + colorStr + ')'
  return { 'background-image': bgStyle }
}

//颜色变亮
export function colorBrighten(color, val = 50) {
  let colorArr = colorToArr(color)
  let newColor = []
  for (let i in colorArr) {
    if (i < 3) {
      newColor.push(colorArr[i] + val > 255 ? 255 : colorArr[i] + val)
    } else {
      newColor.push(colorArr[i])
    }
  }
  return newColor
}

/**
 * @description: 调整颜色更亮更暗
 * @param {string} color 六位十六禁止颜色
 * @param {number} range 正负数决定颜色更改
 * @return {string} new color
 */
export function adjustColor(color, range) {
  let newColor = '#'
  for (let i = 0; i < 3; i++) {
    const hxStr = color.substr(i * 2 + 1, 2)
    let val = parseInt(hxStr, 16)
    val += range
    if (val < 0) val = 0
    else if (val > 255) val = 255
    newColor += val.toString(16).padStart(2, '0')
  }
  return newColor
}
