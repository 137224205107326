<template>
  <div class="cpn-map-img-legend" v-drag="true">
    <MapLegend
      v-if="legendType == 'StageValue'"
      :name="name"
      :legend="legend"
      class="map-legend"
    ></MapLegend>
    <MapRibbonLegend
      v-if="legendType == 'RibbonValue'"
      :name="name"
      :legend="legend"
      :maxValue="maxValue"
      :minValue="minValue"
      class="map-legend"
    ></MapRibbonLegend>
  </div>
</template>
<script>
import MapLegend from '@/components/common/map/cpn/MapLegend.vue'
import MapRibbonLegend from '@/components/common/map/cpn/MapRibbonLegend.vue'

export default {
  log: '',
  components: { MapLegend, MapRibbonLegend },
  inject: [],
  props: {},
  data() {
    return {
      legend: [],
      name: '',
      legendType: 'StageValue',
      maxValue: '',
      minValue: ''
    }
  },
  watch: {},
  computed: {},
  mounted() {},
  methods: {
    init(leg) {
      if (leg?.colorCode) {
        this.legendType = leg.colorCode
        if (leg.colorCode == 'StageValue') {
          this.setStageLegend(leg)
        } else if (leg.colorCode == 'RibbonValue') {
          this.setRibbonLegend(leg)
        } else {
          console.error('意料之外的图例类别：' + leg.colorCode)
        }
      } else {
        this.legendType = ''
      }
    },
    setStageLegend(leg) {
      this.legend = this.Api.jsonData(leg.contents)
      this.name = leg.name
    },
    setRibbonLegend(leg) {
      let ribbon = this.Api.jsonData(leg.ribbonContent)
      this.legend = ribbon.content.split(',')
      this.name = leg.name
      this.maxValue = leg.ribbonLimit.max
      this.minValue = leg.ribbonLimit.min
    }
  }
}
</script>
<style lang="scss" scoped>
.cpn-map-img-legend {
  margin-right: 10px;
}
</style>
