<template>
  <div class="cpn-suitability">
    <div class="lump-1">
      <div class="number">{{ suitsVal }}</div>
    </div>
    <div class="lump-2">
      <div class="suit-text" :style="{ color: suitColor }">
        {{ suitsName }}
      </div>
      <div class="suit-icon">
        <LayoutStarIcon
          :modelValue="starNum"
          :color="suitColor"
        ></LayoutStarIcon>
      </div>
    </div>
  </div>
</template>
<script>
import LayoutStarIcon from '@/components/view/layout/LayoutStarIcon.vue'
export default {
  inject: [],
  components: { LayoutStarIcon },
  props: {
    suitsVal: {
      type: [Number, String],
      default: ''
    },
    suitColor: {
      type: String,
      default: ''
    },
    suitsName: {
      type: String,
      default: ''
    },
    starNum: {
      type: [Number, String],
      default: ''
    }
  },
  data() {
    return {}
  },
  watch: {},
  computed: {},
  mounted() {},
  methods: {}
}
</script>
<style lang="scss" scoped>
.cpn-suitability {
  background: url('~@/assets/img/suit.png') center bottom/contain no-repeat;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-bottom: 10px;
  .lump-1 {
    margin-bottom: 15px;
    .number {
      font-size: 34px;
      text-align: center;
      color: #00ffff;
    }
  }
  .lump-2 {
    margin-top: 10px;
    display: flex;
    justify-content: center;
    align-items: baseline;
    .suit-text {
      color: #97fd82;
      font-size: 20px;
      margin-right: 10px;
    }
  }
}
</style>
