import { DefAjax } from '../service/ajax.js'
/**
 * @通过基地id获取基地_园区详情
 */
export function InfoMap_baseInfo(postData) {
  let areaJson = require('@/assets/json/garden-chachang-outer.json')
  return new Promise((suc) => {
    DefAjax({
      morePost: true,
      url: '/source/api/dp/companyBase/' + postData?.id,
      method: 'get',
      params: {}
    })
      .then((ret) => {
        if (ret.success) {
          suc(ret)
        } else {
          suc(areaJson)
        }
      })
      .catch(() => {
        suc(areaJson)
      })
  })
}

/**
 * @通过基地id获取示范田详情
 */
export function InfoMap_gardenInfo(postData) {
  return new Promise((suc) => {
    DefAjax({
      morePost: true,
      url: '/source/api/dp/companyBase/' + postData?.id, // '/source/api/dp/station/list/' + postData?.id,
      method: 'get',
      params: {}
    }).then((ret) => {
      if (ret.success) {
        suc(ret)
      }
    })
  })
}

/**
 * @通过areaCode获取精准农技指导信息
 */
export function Info_gardenAgriculturalInfo(ret) {
  let def = {
    areaCode: '',
    varieties: '',
    pageNo: 1,
    pageSize: 10
  }
  let opt = Object.assign({}, def, ret)
  return DefAjax({
    url: '/source/api/wx/product/product/technology/list',
    method: 'get',
    params: opt
  })
}

/**
 * 通过作物id查询所有品种
 * @param {String} id
 * @returns
 */
export function Info_varietiesDetail(id) {
  return DefAjax({
    method: 'get',
    url: '/source/api/dp/varieties/info/' + id
  })
}

/**
 * 通过基地id查询各种marker站点列表
 * @param {Object} ret.baseId
 * @returns
 */
export function Info_view360StationList(ret) {
  let def = {
    baseId: '',
    category: 'companyBase'
  }
  let opt = Object.assign({}, def, ret)
  return DefAjax({
    method: 'get',
    url: '/source/api/point/config/list',
    params: opt
  })
}

/**
 * 通过基地id查询作物产量列表
 * @param {Object} ret.areaCode
 * @param {Object} ret.varieties
 * @returns
 */
export function Info_cropProductionList(ret) {
  let def = {
    areaCode: '',
    varieties: ''
  }
  let opt = Object.assign({}, def, ret)
  return DefAjax({
    method: 'get',
    url: '/source/api/dp/business/crop/tCropProduction/list',
    params: opt
  })
}

/**
 * 通过基地id查询版权信息
 * @param {Object} ret.companyBase
 * @returns
 */
export function Info_baseCopyright(ret) {
  let def = {
    companyBase: '',
    varieties: ''
  }
  let opt = Object.assign({}, def, ret)
  return DefAjax({
    method: 'get',
    url: '/source/api/dp/company/regionTechnicalSupport/list',
    params: opt
  })
}

/**
 * 通过产品id和定位查询点位详情
 * @param {String} ret.dateTime
 * @param {String} ret.lat
 * @param {String} ret.lon
 * @param {String} ret.productId
 * @returns
 */
export function Info_cropProductCoordDetail(ret) {
  let def = {
    dateTime: '',
    lat: '',
    lon: '',
    productId: ''
  }
  let opt = Object.assign({}, def, ret)
  return DefAjax({
    morePost: true,
    method: 'get',
    url: '/source/api/dp/crop/index/product/index/info',
    params: opt
  })
}

/**
 * 通过指标数据查询灾害对策措施列表
 * @param {String} ret.indexId
 * @param {String} ret.levelVal
 * @returns
 */
export function Info_disasterOperList(ret) {
  let def = {
    indexId: '',
    levelVal: ''
  }
  let opt = Object.assign({}, def, ret)
  return DefAjax({
    morePost: true,
    method: 'get',
    url: '/source/api/dp/measures/tMeasuresDisaster/list',
    params: opt
  })
}

/**
 * 通过指标数据查询生长发育对策措施列表
 * @param {String} ret.indexId
 * @param {String} ret.levelVal
 * @returns
 */
export function Info_measuresOperList(ret) {
  let def = {
    indexId: '',
    levelVal: ''
  }
  let opt = Object.assign({}, def, ret)
  return DefAjax({
    method: 'get',
    url: '/source/api/dp/measures/tMeasuresDisaster/list',
    params: opt
  })
}
