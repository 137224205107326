import initMap from '@/plug/map/openLayer/initMap.js'
import MapBaseImg from '@/plug/map/openLayer/MapBaseImg.js'
import Store from '@/store'

//组装流域feature的字典数据，key为流域code
export function getMapPlug(ret) {
  let def = {
    id: 'map-plug',
    center: Store.state.info.KOStore.mapCenter, //[117, 32],
    zoom: 8,
    minZoom: 2,
    maxZoom: 18
  }
  let config = Object.assign({}, def, ret)
  let mapPlug = new initMap(config)
  mapPlug.mapBaseImg = new MapBaseImg({
    mapPlug: mapPlug,
    opacity: 1,
    ...ret.mapBaseOption
  })
  return mapPlug
}
