import axios from 'axios'

class KORequest {
  instance
  interceptors
  showLoading
  loading

  constructor(config) {
    // 创建axios实例
    this.instance = axios.create(config)

    // 保存基本信息
    this.interceptors = config.interceptors

    // 使用拦截器
    // 1.从config中取出的拦截器是对应的实例的拦截器
    this.instance.interceptors.request.use(
      this.interceptors?.requestInterceptor,
      this.interceptors?.requestInterceptorCatch
    )
    this.instance.interceptors.response.use(
      this.interceptors?.responseInterceptor,
      this.interceptors?.responseInterceptorCatch
    )

    // 2.添加所有的实例都有的拦截器
    this.instance.interceptors.request.use(
      (config) => {
        //console.log('所有的实例都有的拦截器: 请求成功拦截')
        return config
      },
      (err) => {
        //console.log('所有的实例都有的拦截器: 请求失败拦截')
        return err
      }
    )

    this.instance.interceptors.response.use(
      (res) => {
        //console.log('所有的实例都有的拦截器: 响应成功拦截')
        const data = res?.data
        if (data) {
          if (data?.success === false) {
            //console.error('请求失败, 错误信息', data)
            return data
          } else {
            return data
          }
        } else {
          //console.error('请求失败', res)
          return res
        }
      },
      (err) => {
        //console.log('所有的实例都有的拦截器: 响应失败拦截')
        // 例子: 判断不同的HttpErrorCode显示不同的错误信息
        if (err?.response?.status === 404) {
          console.log('404的错误~')
        }
        return err
      }
    )
  }

  request(config) {
    return new Promise((resolve, reject) => {
      // 1.单个请求对请求config的处理
      if (config.interceptors?.requestInterceptor) {
        config = config.interceptors.requestInterceptor(config)
      }
      this.instance
        .request(config)
        .then((res) => {
          // 单个请求对数据的处理
          if (config.interceptors?.responseInterceptor) {
            res = config.interceptors.responseInterceptor(res)
          }
          // 将结果resolve返回出去
          resolve(res)
        })
        .catch((err) => {
          reject(err)
          return err
        })
    })
  }

  get(config) {
    return this.request({ ...config, method: 'GET' })
  }

  post(config) {
    return this.request({ ...config, method: 'POST' })
  }

  delete(config) {
    return this.request({ ...config, method: 'DELETE' })
  }

  patch(config) {
    return this.request({ ...config, method: 'PATCH' })
  }
}

export default KORequest
