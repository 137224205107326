import {
  getDomPosition,
  getDomMaxPosition,
  resizeLimitPosition
} from '@/plug/dom/getStyle.js'
export function Tdrag(el) {
  //const dragDomWidth = el.offsetWidth // 对话框宽度
  const dragDom = el
  dragDom.onmousedown = (e) => {
    // 算出鼠标相对元素的位置
    let disX = e.clientX
    let disY = e.clientY
    const limitPosition = getDomMaxPosition(dragDom)
    const pos = getDomPosition(dragDom)
    const domHalfWidth = dragDom.offsetWidth / 2
    limitPosition.left[0] = limitPosition.left[0] - domHalfWidth
    limitPosition.left[1] = limitPosition.left[1] - domHalfWidth
    document.onmousemove = (e) => {
      // 用鼠标的位置减去鼠标相对元素的位置，得到元素的位置
      let dragPotition = {}
      dragPotition.left = e.clientX - disX
      dragPotition.top = e.clientY - disY
      // 边界处理
      resizeLimitPosition(dragPotition, limitPosition)
      dragDom.style.cssText += `;left:${dragPotition.left + pos.left}px;top:${
        dragPotition.top + pos.top
      }px;bottom:auto;right:auto;`
    }
    document.onmouseup = () => {
      document.onmousemove = null
      document.onmouseup = null
    }
  }
}
