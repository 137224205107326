import {
  setMapInnerFeatures,
  setMapOuterFeatures,
  setFeatureLabel
} from '@/plug/map/openLayer/polygon/DrawMapFeatures.js'
import {
  InfoMap_geojsonFeaturesInner,
  InfoMap_geojsonFeaturesOuter
} from '@/info/infoMap.js'
import Api from '@/api/Api.js'
/**
 * 请求接口绘制地图轮廓
 * @param {Object} mapPlug //地图plug
 * @returns
 */
//let postId = '' //只用最后一次请求的id
export function HooksHomeMapFeaturesAll(MapRoot, AppRoot, areaCode) {
  MapRoot.onLoading()
  let postData = { areaCode: Api.removeZero(areaCode) }
  let pmsArr = [
    InfoMap_geojsonFeaturesOuter(postData),
    InfoMap_geojsonFeaturesInner(postData)
  ]
  Promise.all(pmsArr).then((rets) => {
    MapRoot.offLoading()
    if (AppRoot.areaCode == areaCode) {
      let geoJsonOuter = rets[0]
      let geoJsonInner = rets[1]
      //setMapOuterFeatures(MapRoot.mapPlug, geoJsonOuter)
      setMapInnerFeatures(MapRoot.mapPlug, geoJsonInner)
      setFeatureLabel(MapRoot.mapPlug, geoJsonInner) //绘制feature标签
      //显示当前轮廓区域,并设置中心点
      MapRoot.mapPlug.viewFeature({
        geoJson: geoJsonOuter
      })
      MapRoot.mapPlug.zoomHome = () => {
        MapRoot.mapPlug.viewFeature({
          geoJson: geoJsonOuter
        })
      }
    }
  })
}

/**
 * 请求接口绘制地图轮廓
 * @param {Object} mapPlug //地图plug
 * @returns
 */
export function HooksHomeMapFeaturesInner(MapRoot, postData) {
  MapRoot.onLoading()
  Promise.all([InfoMap_geojsonFeaturesOuter(postData)]).then((rets) => {
    MapRoot.offLoading()
    let geoJsonInner = rets[0]
    setMapInnerFeatures(MapRoot.mapPlug, {})
    setMapOuterFeatures(MapRoot.mapPlug, geoJsonInner)
    setFeatureLabel(MapRoot.mapPlug, geoJsonInner) //绘制feature标签
    //显示当前轮廓区域,并设置中心点
    MapRoot.mapPlug.viewFeature({
      geoJson: geoJsonInner
    })
  })
}
