<template>
  <div class="cpn-map-footer is-dark">
    <ul class="m-map-footer">
      <li v-if="false" class="fg">
        <div class="text box-inner box-fixed">
          <i class="icon icon-fenbianshuai icon-lh"></i>比例尺：
          <div class="bilichi" ref="RCtrl"></div>
        </div>
      </li>
      <li class="fg">
        <div class="text box-inner box-fixed">
          <i class="icon icon-fenbianshuai icon-lh"></i>分辨率：{{
            plottingScale
          }}
        </div>
      </li>
      <li class="fg">
        <div class="text box-inner box-fixed">
          <i class="icon icon-jingweidu icon-lh"></i>经纬度：{{ mapCoord }}
        </div>
      </li>
      <li class="li-handle fg">
        <MapBaseSelect></MapBaseSelect>
      </li>
      <li class="li-handle fg">
        <PopOverLayerCheckbox
          v-model="MapRoot.footer.layerView.values"
          :options="MapRoot.footer.layerView.options"
        ></PopOverLayerCheckbox>
      </li>
      <li class="li-handle fg" v-if="false">
        <div class="box-handle box-inner box-fixed">
          <i class="icon icon-tuli icon-lh"></i>图例
        </div>
      </li>
      <li class="li-handle fg">
        <div
          class="box-handle box-inner box-fixed"
          @click="DialogSetupVisible = !DialogSetupVisible"
        >
          <i class="icon icon-cb20-shezhi icon-lh"></i>设置
        </div>
      </li>
      <li class="copyright">
        <div class="text box-inner box-fixed">
          <div class="copy-name" v-html="copyright"></div>
        </div>
      </li>
    </ul>
    <DialogSetup v-model="DialogSetupVisible"></DialogSetup>
  </div>
</template>
<script>
import MapBaseSelect from './mapSetup/MapBaseSelect.vue'
import PopOverLayerCheckbox from './mapSetup/PopOverLayerCheckbox.vue'
import DialogSetup from './mapSetup/DialogSetup.vue'
//import ScaleLine from 'ol/control/ScaleLine'
export default {
  log: '',
  components: { MapBaseSelect, PopOverLayerCheckbox, DialogSetup },
  inject: ['MapRoot'],
  props: {
    copyright: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      DialogSetupVisible: false
    }
  },
  watch: {
    'MapRoot.mapPlug': {
      handler() {
        this.initMap()
      }
    }
  },
  computed: {
    mapCoord() {
      if (this.MapRoot?.mapEvent?.mouseCoord?.length == 2) {
        return (
          this.MapRoot.mapEvent?.mouseCoord[0].toFixed(4) +
          ' | ' +
          this.MapRoot.mapEvent?.mouseCoord[1].toFixed(4)
        )
      } else {
        return '-'
      }
    },
    plottingScale() {
      return this.MapRoot?.mapEvent?.plottingScale ?? '-'
    }
  },
  mounted() {
    //DOM初始化完成进行地图初始化
  },
  methods: {
    initMap() {
      //初始化地图
    }
  }
}
</script>
<style lang="scss" scoped>
.cpn-map-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: #fff;
  &.is-dark {
    background: var(--ko-body-bg);
  }
  .m-map-footer {
    display: flex;
    font-size: 14px;
    li {
      border-right: 1px solid rgba(255, 255, 255, 0.3);
      &.fg {
        width: 10px;
        flex-grow: 1;
      }
      &:last-child {
        border-right: 0 solid #000;
      }
      .bilichi {
        ::v-deep(.ol-scale-line) {
          position: relative;
          left: 0;
          bottom: 0;
          line-height: 14px;
        }
      }
      .box-inner {
        height: 38px;
        line-height: 38px;
        flex-grow: 1;
        padding-right: 10px;
      }
      .box-fixed {
        align-items: center;
        justify-content: center;
        display: flex;
      }
      .box-handle {
        cursor: pointer;
        &:hover {
          background: #061838;
        }
      }
      i.icon {
        margin-right: 5px;
        font-size: 16px;
        display: inline-block;
      }
      i.icon-right {
        margin-left: 5px;
        font-size: 12px;
      }
      .el-dropdown {
        display: block;
      }
    }
  }
}
</style>
