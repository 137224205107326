/*用于接口请求临时存储返回值的modules */
import localCache from '@/utils/cache.js'
export default {
  namespaced: true,
  state() {
    return {
      projectName: 'scgzsl',
      KOData: {},
      KOStore: {
        //会存入storeage的对象
        mapCenter: [107.22, 26.14], //[117, 32],
        mapZoom: 6,
        GArea: {}
      }
    }
  },
  getters: {},
  mutations: {
    saveState(state, ret) {
      state.KOData[ret.key] = ret.value
    },
    initStore(state) {
      let st = localCache.get(state.projectName + 'KOStore')
      for (let i in st) {
        state.KOStore[i] = st[i]
      }
    },
    saveStore(state, ret) {
      state.KOStore[ret.key] = ret.value
      localCache.set(state.projectName + 'KOStore', state.KOStore)
    },
    setArea(state, ret) {
      state.KOStore.GArea = ret
      localCache.set(state.projectName + 'KOStore', state.KOStore)
      this.commit('global/initData')
      this.commit('MapStore/initData')
    },
    setAreaName(state, areaName) {
      state.KOStore.GArea.name = areaName
      localCache.set(state.projectName + 'KOStore', state.KOStore)
    },
    clear(state) {
      state.KOData = {}
    }
  },
  actions: {}
}
