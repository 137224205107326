<template>
  <div class="layer-view-popup" v-if="viewSetup">
    <div class="m-main-content" v-if="viewCheckboxList.length">
      <!--实况-站点监测 S-->
      <div class="item" v-for="item in viewCheckboxList" :key="item.key">
        <el-checkbox
          v-if="hasFormItem(item.key)"
          v-model="MapRoot.checkView[item.key]"
          :label="item.name"
        />
      </div>
    </div>
    <div v-else class="ui-no-data">请先查询数据</div>
  </div>
</template>
<script>
export default {
  inject: ['MapRoot'],
  components: {},
  props: {},
  data() {
    return {
      keyDict: {
        ImgGarden: '园区分布', //插值图
        ImgPlant: '种植分布', //插值图
        ImgGardenBase: '基地底图'
      }
    }
  },
  watch: {},
  computed: {
    viewSetup() {
      return this.MapRoot.footer.layerView.values.includes('viewSetup')
    },
    viewCheckboxList() {
      let list = []
      for (let i in this.MapRoot.checkView) {
        list.push({
          key: i,
          value: this.MapRoot.checkView[i],
          name: this.keyDict[i]
        })
      }
      return list
    }
  },
  mounted() {},
  methods: {
    EventHide() {
      this.Api.removeArrItem(this.MapRoot.footer.layerView.values, 'viewSetup')
    },
    hasFormItem(item) {
      if (typeof item == 'undefined') {
        return false
      } else {
        return true
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.layer-view-popup {
  position: absolute;
  top: 20px;
  left: 420px;
  ::v-deep(.el-checkbox__label),
  ::v-deep(.el-checkbox__input) {
    --el-checkbox-checked-text-color: #0ff;
    --el-checkbox-checked-bg-color: #0ff;
    --el-checkbox-checked-input-border-color: #0ff;
  }
}
</style>
