export default {
  namespaced: true,
  state() {
    return {
      storeName: 'global',
      cropList: [],
      mapScreen: true //地图显示状态
    }
  },
  getters: {},
  mutations: {
    initData() {},
    saveState(state, ret) {
      state[ret.key] = ret.value
    },
    setCropList(state, ret) {
      state.cropList = ret
    }
  },
  actions: {}
}
