<template>
  <div class="cpn-icon-tab" v-if="list?.length">
    <swiper
      class="swiper-tab"
      slides-per-view="3"
      @swiper="onSwiper"
      ref="RSwiper"
    >
      <SwiperSlide
        class="ko-slide"
        v-for="(item, index) in list"
        :key="item"
        :class="getClass(item, index)"
        @click="EventClick(item, index)"
      >
        <div class="box">
          <div class="icon-wrap">
            <div class="icon" :class="item.icon"></div>
            <div class="ani-border"></div>
          </div>
          <div class="name">{{ item.name }}</div>
        </div>
      </SwiperSlide>
    </swiper>
  </div>
</template>
<script>
import { Swiper, SwiperSlide } from 'swiper/vue'
export default {
  inject: [],
  components: {
    Swiper,
    SwiperSlide
  },
  props: {
    list: {
      type: Array,
      default: () => []
    },
    modelValue: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      currentIndex: 0
    }
  },
  watch: {
    list: {
      handler() {
        this.$nextTick(() => {
          this.swiper?.update()
        })
      },
      immediate: true
    }
  },
  computed: {},
  mounted() {
    this.init()
  },
  methods: {
    init() {
      for (let i of this.list) {
        if (this.list[i]?.value == this.modelValue) {
          this.currentIndex = i
        }
      }
    },
    slideAutoPlay() {
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = ''
      }
      this.timer = setTimeout(() => {
        if (this.list?.length) {
          this.currentIndex++
          if (this.currentIndex >= this.list.length) {
            this.currentIndex = 0
          }
          this.swiper.slideTo(this.currentIndex, 500)
          this.emitItem(this.list[this.currentIndex])
        }
        this.slideAutoPlay()
      }, 60000)
    },
    onSwiper(swiper) {
      this.swiper = swiper
    },
    getClass(item) {
      let cur = item.value == this.modelValue ? 'current' : ''
      let dis = item.disabled ? 'disabled' : ''
      let isnew = item.new ? 'new' : ''
      return cur + ' ' + dis + ' ' + isnew
    },
    EventClick(item, index) {
      this.emitItem(item)
      this.currentIndex = index
    },
    emitItem(item) {
      this.$emit('update:modelValue', item.value)
      this.$emit('change', item)
    },
    stopAutoPlay() {
      if (this.timer) {
        clearTimeout(this.timer)
        this.timer = ''
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.swiper-tab {
  display: flex;
  .swiper-slide {
    color: #adb9ce;
    .box {
      cursor: pointer;
    }
    .icon-wrap {
      position: relative;
      padding: 5px;
      height: 46px;
      width: 46px;
      margin-left: auto;
      margin-right: auto;
      margin-bottom: 8px;
    }
    .icon {
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      border: 1px solid #0c5a98;
      border-radius: 50%;
      box-sizing: border-box;
      font-size: 22px;
    }
    .ani-border {
      position: absolute;
      left: 0;
      right: 0;
      top: 0;
      bottom: 0;
      border: 1px dashed transparent;
      border-radius: 50%;
    }
    .name {
      text-align: center;
      line-height: 1.2;
    }
    &.current {
      color: #00f4f3;
      .icon {
        border: 1px solid #00f4f3;
      }
      .ani-border {
        border: 1px dashed #00f4f3;
      }
    }
    &.disabled {
      .box {
        cursor: not-allowed;
      }
      color: #465978;
      .icon {
        border: 1px solid #465978;
      }
      .name {
        color: #465978;
      }
    }
    &.new {
      .icon {
        position: relative;
        &::after {
          position: absolute;
          right: 0;
          top: 0;
          content: '';
          width: 8px;
          height: 8px;
          background: #ff3166;
          border-radius: 50%;
          z-index: 2;
        }
      }
    }
  }
}
</style>
