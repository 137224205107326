<template>
  <div class="box-view360-info" v-if="MapRoot.mapEvent?.selectFeature">
    <div class="m-station-label">
      <div class="name">{{ MapRoot.mapEvent?.selectFeature.name }}</div>
    </div>
  </div>
</template>
<script>
export default {
  inject: ['MapRoot'],
  components: {},
  props: {},
  data() {
    return {
      img360: require('@/assets/img/garden/icon-view360.png')
    }
  },
  watch: {},
  computed: {},
  mounted() {},
  methods: {
    EventClick() {
      this.$router.push({
        path: '/view360',
        query: {
          name: this.MapRoot.mapEvent?.selectFeature.name
        }
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.box-view360-info {
  background: var(--ko-body-bg);
  .m-station-label {
    padding: 15px;
    background: rgba(13, 29, 55, 0.4);
    box-shadow: 0 0 10px rgba(0, 255, 255, 0.8);
    word-break: keep-all;
    white-space: nowrap;
    border-radius: 5px;
    display: flex;
    align-items: center;
    .name {
      font-size: 16px;
    }
    .line {
      margin: 0 20px;
      height: 25px;
      width: 1px;
      border-left: 1px solid rgba(255, 255, 255, 0.3);
    }
    .button-view360 {
      width: 20px;
      height: 25px;
      cursor: pointer;
    }
  }
}
</style>
