<template>
  <div></div>
</template>
<script>
export default {
  inject: ['MapRoot'],
  components: {},
  props: {},
  data() {
    return {}
  },
  watch: {
    'MapRoot.mapEvent.selectLocat': {
      handler(val) {
        console.log(val)
      }
    }
  },
  computed: {},
  mounted() {},
  methods: {}
}
</script>
<style lang="scss" scoped></style>
