<template>
  <div class="legend-wrap" v-if="legend?.length">
    <div class="unit" v-if="name">{{ name }}</div>
    <div class="m-ribbon-legend" :style="getColorStyle()">
      <div class="max-value">{{ maxValue }}</div>
      <div class="min-value">{{ minValue }}</div>
    </div>
  </div>
</template>
<script>
import { colors2css } from '@/api/func/colorRibbon.js'
export default {
  components: {},
  props: {
    legend: {
      type: Array,
      default: () => []
    },
    name: {
      type: String,
      default: ''
    },
    maxValue: {
      type: [String, Number],
      default: ''
    },
    minValue: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    return {}
  },
  watch: {},
  computed: {},
  mounted() {},
  methods: {
    getColorStyle() {
      return colors2css(this.legend, 0)
    }
  }
}
</script>
<style scoped lang="scss">
.legend-wrap {
  text-align: center;
  border-radius: 3px;
  overflow: hidden;
  color: #fff;
  .unit {
    background: var(--ko-box-bg);
    padding: 4px 10px;
    line-height: 14px;
    font-size: 12px;
    vertical-align: middle;
  }
  .m-ribbon-legend {
    position: relative;
    height: 300px;
    min-width: 60px;
    border-bottom-left-radius: 3px;
    border-bottom-right-radius: 3px;
    .max-value {
      position: absolute;
      top: 5px;
      left: 0;
      right: 0;
    }
    .min-value {
      position: absolute;
      bottom: 5px;
      left: 0;
      right: 0;
    }
  }
}
</style>
