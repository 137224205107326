<template>
  <div class="layer-top-center" v-loading="loading">
    <div class="m-service-number">
      <template v-for="(item, index) in list" :key="item">
        <div class="line" v-if="index > 0"></div>
        <dl class="box">
          <dd>
            {{ item.name }}
            <div class="num">{{ item.value }}</div>
            {{ item.unit }}
          </dd>
        </dl>
      </template>
    </div>
  </div>
</template>
<script>
import { Info_baseList } from '@/info/infoHome.js'
export default {
  inject: ['AppRoot'],
  components: {},
  props: {},
  data() {
    return {
      list: [],
      satisticsInfo: {},
      loading: false
    }
  },
  watch: {
    'AppRoot.varietiesId': {
      handler() {
        this.getData()
      }
    }
  },
  computed: {},
  created() {
    this.getData()
  },
  mounted() {},
  methods: {
    getData() {
      this.loading = true
      Info_baseList({
        areaCode: this.Api.removeZero(this.AppRoot.areaCode),
        varieties: this.AppRoot.varietiesId
      })
        .then((ret) => {
          this.loading = false
          this.satisticsInfo = ret.satisticsInfo
          this.initData()
        })
        .catch((err) => {
          console.log(err)
        })
    },
    initData() {
      let data = {
        garden: this.satisticsInfo.baseCount,
        plantArea: this.satisticsInfo.measures,
        population: this.satisticsInfo.basePeopleCount,
        service: this.satisticsInfo.productCount
      }
      let label = [
        { name: '园区', key: 'garden', unit: '个' },
        {
          name: '种植面积',
          key: 'plantArea',
          unit: this.satisticsInfo.measuresUnit
        },
        { name: '服务用户', key: 'population', unit: '人' },
        { name: '服务次数', key: 'service', unit: '次' }
      ]
      this.list = this.Api.Obj2Arr({
        data: data,
        label: label
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.layer-top-center {
  position: absolute;
  top: 20px;
  left: 50%;
  transform: translate(-50%, 0);
  padding: 10px 30px;
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.7),
    rgba(0, 0, 0, 0.7),
    rgba(0, 0, 0, 0.7),
    rgba(0, 0, 0, 0)
  );
  &::before,
  &::after {
    position: absolute;
    left: 0;
    content: '';
    display: block;
    height: 1px;
    width: 100%;
    background: linear-gradient(
      90deg,
      rgba(0, 255, 255, 0),
      rgba(0, 255, 255, 0.4),
      rgba(0, 255, 255, 0.4),
      rgba(0, 255, 255, 0.4),
      rgba(0, 255, 255, 0)
    );
  }
  &::before {
    top: 0;
  }
  &::after {
    bottom: 0;
  }
  .m-service-number {
    display: flex;
    .box {
      dd {
        display: flex;
        align-items: baseline;
        word-break: keep-all;
        white-space: nowrap;
        padding: 0 20px;
        font-size: 16px;
        .num {
          padding: 0 10px;
          font-size: 24px;
          color: #0ff;
        }
      }
    }
    .line {
      margin: 8px 0;
      border-left: 1px solid rgba(0, 255, 255, 0.4);
    }
  }
}
</style>
