import LayerImg from '@/plug/map/openLayer/layer/LayerImg.js'
export function HooksHomeMapImgIsogram(MapRoot) {
  let bounds = MapRoot.mapImageIsogram.bounds
    ? MapRoot.mapImageIsogram.bounds
    : [106.2, 25.07, 108.3, 27.49]
  if (MapRoot.mapPlug?.PlugImgLayers) {
    MapRoot.mapPlug.PlugImgLayers.update({
      extent: bounds,
      img: MapRoot.mapImageIsogram.imgUrl
    })
  } else {
    MapRoot.mapPlug.PlugImgLayers = new LayerImg({
      mapPlug: MapRoot.mapPlug,
      extent: bounds,
      imgs: []
    })
  }
}
