import KOPost from './index.js'
import store from '@/store'
import TypeIs from '@/api/TypeIs.js'
import Api from '@/api/Api.js'
export function KOAjax(param) {
  //本地运行时不使用缓存
  let save = true
  /*if (process.env.NODE_ENV === 'development') {
    save = false;
  }*/
  let def = {
    timestampId: '',
    morePost: false, //用于区分某些接口需要同时多次请求的情况，这时候就需要在data中传入true
    save: save, //是否把数据缓存到store的Data
    model: 'info' //是否是store中的home模块
  }
  let opt = Object.assign({}, def, param)
  let postData = rePost(opt)
  let timestamp = new Date().getTime()
  let dataKey = JSON.stringify(postData)
  setStoreTimestamp(opt, timestamp) //更新请求的当前时间戳
  let dataObj = getStoreData(opt)
  let state = dataObj[dataKey]
  let pms = new Promise((res, rej) => {
    if (state && opt.save) {
      //库中存在-直接给
      if (TypeIs.Number(opt.save)) {
        let now = new Date().Format('time')
        let savetime = state.KOSaveTime.DateAdd('s', opt.save)
        if (now < savetime) {
          res(state)
        } else {
          postServerData({
            opt,
            timestamp,
            rej,
            res,
            dataKey
          })
        }
      } else {
        res(state)
      }
    } else {
      //库中没有
      postServerData({
        opt,
        timestamp,
        rej,
        res,
        dataKey
      })
    }
  })
  return pms
}
function postServerData({ opt, timestamp, rej, res, dataKey }) {
  KOPost(opt)
    .then((sucData) => {
      if (sucData) {
        let dataObj = getStoreData(opt)
        if (opt.morePost == false) {
          if (dataObj['timestamp' + opt.timestampId + opt.url] != timestamp) {
            if (process.env.NODE_ENV === 'development') {
              console.log('[没有catch]丢弃过期的请求：', opt.url)
            }
            rej()
            return false
          }
        }
        if (TypeIs.Object(sucData)) {
          dataObj[dataKey] = sucData
          sucData.KOReq = opt
          sucData.KOSaveTime = new Date().Format('YYYY-MM-DD hh:mm:ss')
          if (
            sucData.code === 200 ||
            sucData.success ||
            sucData.status == '1' ||
            sucData.status == '0' ||
            sucData.resultFlag == 'true'
          ) {
            if (opt.save) {
              store.commit(opt.model + '/saveState', {
                key: dataKey,
                value: sucData
              })
            }
            res(sucData)
          } else {
            const msg = sucData?.msg ?? ''
            if (sucData?.code == 'timeout') {
              rej(sucData)
              console.error(msg + ' 请求地址：' + opt.url)
            } else {
              res(sucData)
              if ([401, 402].includes(sucData.code)) {
                console.log('需要登录权限，请求地址' + opt.url)
              } else {
                console.error(msg + ' 请求地址：' + opt.url)
              }
            }
          }
        } else {
          if (TypeIs.Array(sucData)) {
            let text = '注意：接口返回数据为非标准json对象(已自动改为data字段)'
            let reSucData = {
              data: sucData
            }
            reSucData.KOReq = opt
            reSucData.type = 'Array'
            reSucData.warning = text + '，类型为：数组'
            reSucData.KOSaveTime = new Date().Format('YYYY-MM-DD hh:mm:ss')
            res(reSucData)
            dataObj[dataKey] = reSucData
            store.commit(opt.model + '/saveState', {
              key: dataKey,
              value: reSucData
            })
          } else if (TypeIs.String(sucData)) {
            rej(sucData)
            console.error(opt.url + '。错误信息：' + sucData)
          } else {
            rej(sucData)
            console.error(opt.url + '。错误信息：' + sucData)
          }
        }
      } else {
        const msg = '接口请求成功但未收到任何返回值，请联系管理员检查接口：'
        Api.msg(msg + opt.url)
        console.error(msg + opt.url)
        rej()
      }
    })
    .catch((err) => {
      console.log(err)
      rej(err)
      if (err.code == 400 || err.code == 401) {
        Api.msg('请先登录')
      } else {
        const msg =
          err?.msg ?? '接口请求失败，可能服务器正在维护，或者接口存在问题：'
        Api.msg(msg)
        console.error(msg + ',请求链接：' + opt.url, err)
      }
    })
}

function rePost(ret) {
  if (ret.postType === 'formData') {
    //表单提交方式
    ret.headers = {
      'Content-Type': 'application/x-www-form-urlencoded'
    }
    ret.transformRequest = [
      function (params) {
        let ret = Object.keys(params)
          .map((v) => `${v}=${encodeURI(params[v])}`)
          .join('&')
        return ret
      }
    ]
  }
  return ret
}

function getStoreData(opt) {
  return store.state[opt.model]
    ? JSON.parse(JSON.stringify(store.state[opt.model].KOData))
    : {}
}
function setStoreTimestamp(opt, timestamp) {
  store.commit(opt.model + '/saveState', {
    key: 'timestamp' + opt.timestampId + opt.url,
    value: timestamp
  })
}
