import TypeIs from '@/api/TypeIs.js'
import { ref } from 'vue'
export function tooltipStation(params, ret) {
  let html = ''
  let stationHtml = '<div>' + params[0].name + '</div>'
  for (let i = 0; i < params.length; i++) {
    html +=
      '<div>' +
      params[i].marker +
      params[i].seriesName +
      '：' +
      (params[i].value == '-' ? '-' : params[i].value + ret?.unit) +
      '</div>'
  }
  return stationHtml + html
}

export function getLinearColor(colors) {
  let colorStops = []
  for (let i = 0; i < colors.length; i++) {
    colorStops.push({
      offset: i,
      color: colors[i] // 0% 处的颜色
    })
  }
  return {
    type: 'linear',
    x: 0,
    y: 0,
    x2: 0,
    y2: 1,
    colorStops: colorStops,
    global: false // 缺省为 false
  }
}

export function calMax(arr, splitNumber = 4) {
  let min = Math.min.apply(null, arr)
  let max = Math.max.apply(null, arr)
  let interval = (max - min) / splitNumber // 平均值
  max = Math.ceil(max + interval) // 向上取整
  // console.log(min, max, interval);
  return max
}
//计算最小值
export function calMin(arr, splitNumber = 4) {
  let min = Math.min.apply(null, arr)
  let max = Math.max.apply(null, arr)
  let interval = (max - min) / splitNumber // 平均值
  min = min === 0 ? min : Math.floor(min - interval) // 向下取整
  // console.log(min, max, interval);
  return min
}

export function reSeriesData(data) {
  let list = []
  for (let item of data) {
    if (TypeIs.Object(item)) {
      list.push({ ...item, value: reElementValue(item.value) })
    } else {
      list.push(reElementValue(item))
    }
  }
  return list
}

export function reElementValue(value) {
  if (value || value === 0) {
    if (value >= 9999 || value <= -9999) {
      return '-'
    } else {
      return value
    }
  } else {
    return value
  }
}
let w = ref(document.documentElement.clientWidth / 1920)
window.addEventListener('resize', () => {
  w.value = document.documentElement.clientWidth / 1920
})
export function nowSize(val) {
  return val * w.value
}
