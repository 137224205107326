import _ from 'lodash'
import FeatureMultMarker from './FeatureMultMarker.js'
import TypeIs from '@/api/TypeIs.js'
class KOClass {
  mapPlug //必传：地图Plug
  markerLegend = [] //必传：点图例 [{key: "weatherStation",name: "站点",color: "#03A9FF"}]
  markerList = [] //必传：Array 所有点集合
  viewMarkerType = [] //必传：Array 需要显示的点类型["weatherStation"]
  markerDict = {} //点字典
  markerStyle = {} //点样式
  markerOption = {}
  /**
   * 组装原始marker列表数据为 MultMarker需要的数据
   * @param {Object} config.mapPlug //地图对象
   * @param {Array} config.markerLegend //地图图例集合
   * @param {Array} config.markerList //所有marker点集合
   * @param {Array} config.viewMarkerType //所有可见的markerType点集合
   */
  constructor(config) {
    _.merge(this, config)
    this.init()
  }
  init() {
    this.initMarkerDict()
    this.initMarkerFeature()
    this.setFeaturePoint()
  }
  update(config) {
    this.markerLegend = config.markerLegend
    this.markerList = config.markerList
    this.viewMarkerType = config.viewMarkerType
    this.init()
  }
  initMarkerDict() {
    this.markerDict = {}
    for (let item of this.markerLegend) {
      this.markerDict[item.key] = { ...item, data: [] }
    }
  }
  initMarkerFeature() {
    let list = this.markerList
    if (list?.length) {
      for (let item of list) {
        let key = item.markerType
        if (key) {
          if (this.markerDict[key]) {
            if (TypeIs.Array(this.viewMarkerType)) {
              if (
                this.viewMarkerType?.includes(key) && //viewMarkerType用于其他地方控制
                this.markerDict[key].visible //visible用于图例中取消显示点
              ) {
                this.markerDict[key].data.push(item)
              }
            } else {
              console.error(this.viewMarkerType, '展示的点类别数据结构不正确')
            }
          } else {
            console.error(this.markerDict, '字典对象中没有找到', key)
          }
        } else {
          console.error(
            this.markerDict,
            '列表中这个对象没有添加markerType:',
            item
          )
        }
      }
    }
  }
  setFeaturePoint() {
    //使用特征api展示点数据
    //require('@/assets/img/icon/icon-city-station.png')
    let options = {
      mapPlug: this.mapPlug,
      points: this.markerDict, //对象-数组：点经纬度数组，必有字段：lon，lat
      ...this.markerOption
    }
    if (this.PlugFeaturePoint) {
      this.PlugFeaturePoint.update(options)
    } else {
      this.PlugFeaturePoint = new FeatureMultMarker(options)
    }
  }
}
export default KOClass
