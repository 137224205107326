<template>
  <div class="home-layer-left">
    <div class="lump lump-1">
      <BoxServiceProduct></BoxServiceProduct>
    </div>
    <div class="lump lump-2"><BoxExpertList></BoxExpertList></div>
    <div class="lump-line"></div>
    <div class="lump lump-3">
      <div class="m-expert-erwei">
        <QrcodeVue
          level="L"
          class="img-ewm"
          :value="qrCode"
          :size="qrSize"
        ></QrcodeVue>
        <div class="cont">扫码查看更多专家建议</div>
      </div>
    </div>
  </div>
</template>
<script>
import BoxServiceProduct from './layerLeft/BoxServiceProduct.vue'
import BoxExpertList from './layerLeft/BoxExpertList.vue'
import QrcodeVue from 'qrcode.vue'
export default {
  inject: ['AppRoot'],
  components: { BoxServiceProduct, BoxExpertList, QrcodeVue },
  props: {},
  data() {
    return {
      imgEwm: require('@/assets/img/ewm.png'),
      qrCode:
        this.Api.host + 'wxqn/expertAsk?cropCode=' + this.AppRoot.cropCode,
      qrSize: 100
    }
  },
  watch: {},
  computed: {},
  mounted() {},
  methods: {}
}
</script>
<style lang="scss" scoped>
.home-layer-left {
  position: absolute;
  top: 20px;
  left: 20px;
  bottom: 50px;
  width: 380px;
  display: flex;
  flex-direction: column;
  .lump-1 {
    margin-bottom: 15px;
    flex-shrink: 0;
  }
  .lump-2 {
    flex-grow: 1;
  }
  .lump-3 {
    flex-shrink: 0;
  }
  .lump-line {
    margin: 0 15px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.1);
    flex-shrink: 0;
  }
  .m-expert-erwei {
    background: var(--ko-body-bg);
    padding: 15px 15px 15px 20px;
    display: flex;
    align-items: center;
    .img-ewm {
      padding: 5px;
      width: 50px !important;
      height: 50px !important;
      margin-right: 15px;
      background: #fff;
    }
    .cont {
      line-height: 1.6;
      font-size: 14px;
    }
  }
}
</style>
