<template>
  <div class="layer-right">
    <div class="lump lump-1">
      <BoxSuitability></BoxSuitability>
    </div>
    <div class="lump lump-2">
      <BoxCropInfo></BoxCropInfo>
    </div>
    <div class="lump lump-3">
      <BoxPriceList></BoxPriceList>
    </div>
  </div>
</template>
<script>
import BoxCropInfo from './layerRight/BoxCropInfo.vue'
import BoxSuitability from './layerRight/BoxSuitability.vue'
import BoxPriceList from './layerRight/BoxPriceList.vue'

export default {
  inject: [],
  components: { BoxCropInfo, BoxSuitability, BoxPriceList },
  props: {},
  data() {
    return {}
  },
  watch: {},
  computed: {},
  mounted() {},
  methods: {}
}
</script>
<style lang="scss" scoped>
.layer-right {
  position: absolute;
  top: 20px;
  right: 20px;
  bottom: 50px;
  width: 380px;
  display: flex;
  flex-direction: column;
  .lump-1 {
    margin-bottom: 15px;
    flex-shrink: 0;
  }
  .lump-2 {
    margin-bottom: 15px;
    flex-shrink: 0;
  }
  .lump-3 {
    height: 10px;
    flex-grow: 1;
  }
}
</style>
