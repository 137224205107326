<template>
  <Swiper
    class="m-news-list"
    :modules="modules"
    :slides-per-view="30"
    slidesPerView="auto"
    :freeMode="true"
    :mousewheel="true"
    :scrollbar="{ draggable: true, el: '.news-swiper-scrollbar' }"
    direction="vertical"
    @reachEnd="reachEnd"
  >
    <SwiperSlide
      v-for="(item, index) in list"
      class="li"
      :key="item"
      :class="currentIndex == index ? 'current' : ''"
      @click="EventClick(item, index)"
    >
      <div class="row-1"></div>
      <div class="row-2">
        <div class="name">{{ item.name }}</div>
        <div class="value">{{ item.date?.Format('MM-DD') }}</div>
      </div>
    </SwiperSlide>
  </Swiper>
  <div class="news-swiper-scrollbar"></div>
</template>
<script>
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Scrollbar, FreeMode, Mousewheel } from 'swiper'
import 'swiper/css/scrollbar'
import 'swiper/css/mousewheel'
export default {
  log: '',
  components: { Swiper, SwiperSlide },
  emits: ['change', 'update:currentIndex', 'scrollEnd'],
  props: {
    list: {
      type: Array,
      default: () => []
    },
    currentIndex: {
      type: [String, Number],
      default: -1
    }
  },
  data() {
    return {
      modules: [Scrollbar, FreeMode, Mousewheel]
    }
  },
  watch: {},
  computed: {},
  mounted() {},
  methods: {
    EventClick(item, index) {
      let data = false
      let curIndex = -1
      if (this.currentIndex != index) {
        data = this.list[index]
        curIndex = index
      }
      if (item.fileType == 'img') {
        this.$emit('update:currentIndex', curIndex)
      }
      this.$emit('change', data)
    },
    reachEnd() {
      this.$emit('scrollEnd')
    }
  }
}
</script>
<style lang="scss" scoped>
.m-news-list {
  height: 100%;
  --swiper-scrollbar-right: -4px;
  .li {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    cursor: pointer;
    font-size: 14px;
    height: auto;
    user-select: none;
    .row-1 {
      flex-shrink: 0;
    }
    .row-2 {
      display: flex;
      align-items: center;
      width: 10px;
      flex-grow: 1;
      .name {
        width: 10px;
        flex-grow: 1;
        word-break: keep-all;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-right: 10px;
      }
      .value {
        flex-shrink: 0;
      }
    }
    &.current {
      color: #0ff;
    }
    &:last-child {
      margin-bottom: 0;
    }
    &:hover {
      color: #0ff;
    }
  }
}
.news-swiper-scrollbar {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 5px;
}
</style>
