import TypeIs from '../TypeIs.js'
import DictLive from '../dict/DictLive.js'
class KOApi {
  /**
   * @param {传入对象数据：
   *  live: true,
   *  data:{winds: 2.4,rhu: 80},
   *  label:[{key:'winds',name:'风力',reData:fn},{key:['lon','lat']name:'经纬度',join:','}]
   * } ret
   * @label语法糖写法 label:['winds','rhu']
   * @returns {返回数组数据：
   *   [{name:'风速',value:2.4},{name:'湿度',value:80]
   * }
   */
  Obj2Arr(ret) {
    let def = {
      live: false, //是否是实况数据，true：去实况字典中查找key对应的name字段
      data: {}, //数据对象
      label: [] //标签数组集合
    }
    let opt = Object.assign({}, def, ret)
    let label = opt.label,
      data = opt.data,
      list = []
    if (data) {
      let key
      for (let i = 0; i < label.length; i++) {
        if (TypeIs.String(label[i])) {
          label[i] = { key: label[i] }
        }
        key = label[i].key
        if (TypeIs.Array(key)) {
          let newArr = []
          for (let item of key) {
            let obj = this.getLabelList(label[i], opt, item)
            newArr.push(obj)
          }
          let valueStr = newArr.map((e) => e.value).join(label[i].join ?? ',')
          let value = valueStr.replace(/(缺省)\1+/g, '缺省')
          if (value != '缺省' && value !== '') {
            list.push({
              ...newArr[0],
              value: this.reValue(value),
              fullValue: this.reValue(value) + label[i].unit
            })
          } else {
            list.push({
              ...newArr[0],
              value: value,
              fullValue: value
            })
          }
        } else {
          list.push(this.getLabelList(label[i], opt, key))
        }
      }
      return list
    } else {
      console.warn(
        'Common.js > Obj2Arr方法：未传入data参数，请传入data参数，你传入了：',
        opt
      )
      return []
    }
  }
  getLabelList(item, opt, key) {
    let liveObj,
      data = opt.data
    const obj = { ...item }
    if (data[key] || data[key] === 0 || data[key] === '') {
      if (typeof obj.name === 'undefined' && opt.live === true) {
        obj.name = DictLive.nameDict(key)
        if (obj.name) {
          liveObj = DictLive.live(obj.name)
          if (liveObj.icon) {
            obj.icon = liveObj.icon
          } else {
            console.error(
              '在DictLive.js的live方法字典中未找到[' +
                obj.name +
                ']对应的图标，请添加'
            )
          }
        } else {
          console.error(
            '在DictLive.js的nameDict方法字典中未找到[' +
              key +
              ']对应的单位和图标，请添加'
          )
        }
        if (data[key] !== '' && data[key] > -99999 && data[key] < 99999) {
          if (typeof obj.reData === 'function') {
            obj.fullValue = obj.value = obj.reData(data[key], key)
          } else {
            obj.value = this.reValue(data[key])
          }
          if (liveObj?.unit) {
            obj.unit = liveObj.unit
          }
          obj.fullValue = this.reValue(obj.value) + liveObj?.unit
        } else {
          obj.value = '-'
          if (liveObj?.unit) {
            obj.unit = liveObj.unit
          }
          obj.fullValue = '-'
        }
      } else {
        if (typeof obj.reData === 'function') {
          obj.fullValue = obj.value = obj.reData(data[key], key)
        } else {
          obj.value = this.reValue(data[key])
        }
      }
    } else {
      console.warn(
        'Common.js > Obj2Arr方法：label参数中的' +
          key +
          '在data中未找到，请检查：',
        opt
      )
    }
    return obj
  }
  reValue(value) {
    return this.keep2(value, 1)
  }
  filterArr(ret) {
    let def = {
      list: [],
      key: '',
      value: '', //可以为数组
      indexOf: false //value为string时启用，是否使用包含方式筛选
    }
    let opt = Object.assign({}, def, ret)
    let list = []
    if (opt.value && opt.value.length) {
      for (let item of opt.list) {
        if (TypeIs.Array(opt.value)) {
          if (opt.value.indexOf(item[opt.key]) >= 0) {
            list.push(item)
          }
        } else if (TypeIs.String(opt.value)) {
          if (opt.indexOf) {
            if (item[opt.key].indexOf(opt.value) >= 0) {
              list.push(item)
            }
          } else {
            if (item[opt.key] == opt.value) {
              list.push(item)
            }
          }
        }
      }
    } else {
      list = opt.list
    }
    return list
  }
  getDefaultLegend(ret) {
    let def = {
      jp: false, //是否是距平
      legendType: 'Temp', //图例类别
      type: '' //标准类别：默认国标，xun:旬，year:年
    }
    /*【legendType】:Temp:"气温",Rain:降水，Ssh：日照，Rhu：湿度，Wind：风 */
    let opt = Object.assign({}, def, ret)
    let chat = {
      Temp: ['tem_standard', 'temp_jp_1'],
      Ssh: ['ssh_', 'ssh_jp'],
      Rain: ['precipitation_day_standard', 'precipitation_jp_standard'],
      Rhu: ['rhu_', 'rhu_'],
      Wind: ['wind_', 'wind_']
    }
    if (opt.type == 'xun') {
      chat = {
        Temp: ['Product_Made_Xun_Tem', 'temp_jp_1'],
        Ssh: ['Product_Made_Xun_Ssh', 'Product_Made_Xun_Ssh_Jp_Val'],
        Rain: ['Product_Made_Xun_Pre', 'Product_Made_Xun_Pre_Jp_Rate'],
        Rhu: ['rhu_', 'rhu_'],
        Wind: ['wind_', 'wind_']
      }
    }
    if (opt.jp) {
      //距平
      if (chat[opt.legendType] && chat[opt.legendType][1]) {
        return chat[opt.legendType][1]
      } else {
        console.error('意料之外的图例类别', opt)
      }
    } else {
      //国标
      if (chat[opt.legendType]) {
        return chat[opt.legendType][0]
      } else {
        console.error('意料之外的图例类别', opt)
      }
    }
    return ''
  }
  //把所有的key不重复的取出来
  getListByKey(ret) {
    let def = {
      list: [],
      key: 'stationName'
    }
    let opt = Object.assign({}, def, ret)
    let stations = [],
      keys = [],
      stationObj = {}
    if (opt.list?.length) {
      for (let item of opt.list) {
        let dataKey = item[opt.key]
        if (typeof stationObj[dataKey] == 'undefined') {
          stationObj[dataKey] = {
            name: dataKey,
            data: []
          }
          stations.push(stationObj[dataKey])
          keys.push(dataKey)
        }
        stationObj[dataKey].data.push(item)
      }
    } else {
      console.error('未传入待转化的数据list', opt.list)
    }
    return { list: stations, keys: keys }
  }
  getSeriseByKey(ret) {
    //比对图例中的key
    let def = {
      list: [], //数据列表
      legend: [], //图例[{name:'',value:''}]
      key: '', //与legend中的value进行比对的key
      elementKey: '' //list中的要素key
    }
    let opt = Object.assign({}, def, ret)
    let series = []
    for (let i = 0; i < opt.legend.length; i++) {
      series[i] = {
        name: opt.legend[i].name,
        data: []
      }
      for (let j = 0; j < opt.list.length; j++) {
        if (opt.key) {
          if (opt.list[j][opt.key] == opt.legend[i].value) {
            series[i].data.push(opt.list[j][opt.elementKey])
          }
        } else {
          series[i].data.push(opt.list[j][opt.elementKey])
        }
      }
    }
    return series
  }
  getSeriseByLegendKeys(ret) {
    //直接使用图例中的key
    let def = {
      list: [], //数据列表
      legendKeys: [] //图例{name:'',key:''}
    }
    let opt = Object.assign({}, def, ret)
    let series = []
    for (let i = 0; i < opt.legendKeys.length; i++) {
      series[i] = {
        name: opt.legendKeys[i].name,
        data: []
      }
      for (let j = 0; j < opt.list.length; j++) {
        if (
          opt.list[j][opt.legendKeys[i].key] ||
          opt.list[j][opt.legendKeys[i].key] === 0
        ) {
          series[i].data.push(opt.list[j][opt.legendKeys[i].key])
        } else {
          series[i].data.push('-')
        }
      }
    }
    return series
  }
  xAxisStationLegendElement(ret) {
    //没有时间的情况，x轴是站点，图例是要素
    let def = {
      list: [], //数据列表
      legendKeys: [], //图例key，默认为站点名称
      xAxisKey: 'stationName' //x轴的key
    }
    let opt = Object.assign({}, def, ret)
    if (opt.list?.length && opt.legendKeys) {
      let series = this.getSeriseByLegendKeys({
        list: opt.list,
        legendKeys: opt.legendKeys // 图例
      })
      //把所有的key不重复的取出来
      let xAxisObj = this.getListByKey({
        list: opt.list,
        key: opt.xAxisKey //待转化为x轴的数据key
      })
      return {
        series: series,
        legendKeys: opt.legendKeys,
        xAxisData: xAxisObj.keys
      }
    } else {
      return { legendKeys: [], series: [], xAxisData: [] }
    }
  }
  xAxisDateLegendStation(ret) {
    //有时间的情况，x轴是时间，图例是站点
    let def = {
      list: [], //数据列表
      legendKey: 'stationName', //图例key，默认为站点名称
      elementKey: 'temp', //要素key
      xAxisKey: 'Datetime' //x轴的key
    }
    let opt = Object.assign({}, def, ret)
    if (opt.list?.length) {
      let legendStation = this.getListByKey({
        list: opt.list,
        key: opt.legendKey //待转化为legend的数据key
      })
      let reLegends = legendStation.keys.map((e) => {
        return { name: e, value: e }
      })
      //获取series，需要传入legend和待判断的key
      let series = this.getSeriseByKey({
        list: opt.list,
        legend: reLegends, // 图例
        key: opt.legendKey, //这里的key会与legend中的value进行关联
        elementKey: opt.elementKey //待转化为y轴的数据的key
      })
      let xAxisObj = this.getListByKey({
        list: opt.list,
        key: opt.xAxisKey //待转化为x轴的数据key
      })
      return {
        series: series,
        legendKeys: reLegends,
        xAxisData: xAxisObj.keys
      }
    } else {
      return { legendKeys: [], series: [], xAxisData: [] }
    }
  }

  dataCorrection(ret) {
    let def = {
      item: [],
      defaultStr: '缺省',
      disDict: false //false:禁用字典限制方式,true:无论是啥，直接处理
    }
    let opt = Object.assign({}, def, ret)
    let defStr = opt.defaultStr ?? '缺省'
    let obj = {}
    let item = opt.item
    for (let key in item) {
      if (TypeIs.Number(item[key])) {
        let name = DictLive.nameDict(key)
        if (name || opt.disDict) {
          if (item[key] > 9999 || item[key] < -9999) {
            obj[key] = defStr
          } else {
            obj[key] = item[key]
          }
        } else {
          obj[key] = item[key]
        }
      } else if (TypeIs.Object(item[key])) {
        if (TypeIs.Number(item[key].value)) {
          let name = DictLive.nameDict(key)
          if (name || opt.disDict) {
            if (item[key].value > 9999 || item[key].value < -9999) {
              obj[key] = item[key]
              obj[key].value = defStr
            } else {
              obj[key] = item[key]
            }
          } else {
            obj[key] = item[key]
          }
        }
      } else {
        obj[key] = item[key]
      }
    }
    return obj
  }
  /**
   * @数据验证
   */
  DataVer(data, keys) {
    if (keys) {
      for (let i in keys) {
        if (typeof data[i] === 'undefined' || data[i] === '') {
          this.msg({
            message: keys[i] + '为必填(选)项',
            type: 'warning',
            debounce: true
          })
          return false
        }
      }
    } else {
      for (let i in data) {
        if (data[i] === '') {
          this.msg({
            message: '表单未填写(选择)完整，请完善必填(选)项',
            type: 'warning',
            debounce: true
          })
          return false
        }
      }
    }
    return true
  }
  getItem(ret) {
    let def = {
      list: [],
      value: ''
    }
    let opt = Object.assign({}, def, ret)
    if (opt.value || opt.value === 0) {
      for (let item of opt.list) {
        if (item.value == opt.value) {
          return item
        }
      }
    } else {
      //console.error('getItem方法：你传入的value值为空')
      return false
    }
    return false
  }
  reData_warning(ret) {
    let def = {
      list: [],
      dateKey: 'updateDate'
    }
    let opt = Object.assign({}, def, ret)
    let list = []
    let timeDict = {}
    let newTime = ''
    for (let item of opt.list) {
      if (
        newTime == '' ||
        newTime.Format('YYYY-MM-DD hh:mm:00').Format('time') <
          item[opt.dateKey].Format('YYYY-MM-DD hh:mm:00').Format('time')
      ) {
        newTime = item[opt.dateKey].Format('YYYY-MM-DD hh:mm:00')
      }
    }
    for (let item of opt.list) {
      let obj = { ...item, new: false }
      if (newTime == item[opt.dateKey].Format('YYYY-MM-DD hh:mm:00')) {
        obj.new = true
      }
      let date = item[opt.dateKey].Format('YYYY-MM-DD')
      if (typeof timeDict[date] == 'undefined') {
        timeDict[date] = item
        obj.firstItem = true
      }
      list.push(obj)
    }
    return list
  }
}
export default KOApi
