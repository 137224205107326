<template>
  <div class="m-map-button">
    <slot name="top"></slot>
    <div class="zoom-add ui-button" @click="EventClick_button('add')">
      <i class="icon-jiajian02 iconfont"></i>
    </div>
    <div class="zoom-sub ui-button" @click="EventClick_button('sub')">
      <i class="icon-jian2 iconfont"></i>
    </div>
    <div class="zoom-resize ui-button" @click="EventClick_button('resize')">
      <i class="cb20-dingwei icon-cb"></i>
    </div>
    <slot name="bottom"></slot>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {}
  },
  watch: {},
  computed: {},
  mounted() {},
  methods: {
    EventClick_button(ret) {
      this.$emit('EventClick', ret)
    }
  }
}
</script>
<style scoped lang="scss">
.m-map-button {
  background: var(--ko-box-bg);
  padding: 5px 0;
  border-radius: 34px;
  ::v-deep(.ui-button) {
    height: 40px;
    width: 40px;
    line-height: 39px;
    text-align: center;
    background: transparent;
    color: #eee;
    border-radius: 50%;
    cursor: pointer;
    i {
      font-size: 20px;
    }
  }
  ::v-deep(.ui-button:hover) {
    color: #00b5ff;
  }
}
</style>
