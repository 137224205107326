import { Fill, Stroke } from 'ol/style'

// 地图轮廓样式定义
export const FeatureDict = {
  dark: {
    //黑色风格
    innerPoly: {
      stroke: new Stroke({
        color: 'rgba(129, 155, 183, 1)',
        width: 1
      }),
      fill: new Fill({
        color: 'rgba(22, 47, 86, 0.9)'
      })
    },
    outerPoly: {
      stroke: new Stroke({
        color: 'rgba(129, 155, 183, 1)',
        width: 1
      }),
      fill: new Fill({
        color: 'rgba(30, 120, 255, 0)'
      })
    }
  },
  light: {
    //白色风格
    innerPoly: {
      stroke: new Stroke({
        color: 'rgba(22, 127, 230, 1)',
        width: 1
      }),
      fill: new Fill({
        color: 'rgba(22, 127, 230, 0.1)'
      })
    },
    outerPoly: {
      stroke: new Stroke({
        color: 'rgba(22, 127, 230, 1)',
        width: 3
      }),
      fill: new Fill({
        color: 'rgba(22, 127, 230,0.1)'
      })
    }
  },
  red: {
    //红色风格
    innerPoly: {
      stroke: new Stroke({
        color: 'rgba(255, 0, 0, 1)',
        width: 1
      }),
      fill: new Fill({
        color: 'rgba(255, 0, 0, 0.1)'
      })
    },
    outerPoly: {
      stroke: new Stroke({
        color: 'rgba(255, 0, 0, 1)',
        width: 3
      }),
      fill: new Fill({
        color: 'rgba(255, 0, 0,0.1)'
      })
    }
  }
}

export const FeatureStyle = FeatureDict.light
//把接口返回的data数据格式化为geoJson格式
export function reData_info2geoJson(data) {
  let geoJson = { features: [], type: 'FeatureCollection' }
  if (data?.length) {
    for (let item of data) {
      geoJson.features.push({
        name: item.name,
        code: item.code,
        ...item.geoJson?.features[0]
      })
    }
  }
  return geoJson
}
//获取feature的item中的key
export function setAreaKey(item) {
  let areaNameArr = [
    'areaName',
    'AreaName',
    'NAME_1',
    'AREANAME',
    'name',
    'NAME'
  ]
  let areaCodeArr = [
    'areaCode',
    'AreaCode',
    'CODE_1',
    'AREACODE',
    'adcode',
    'FID'
  ]
  let areaNameKey = ''
  let areaCodeKey = ''
  for (let code of areaCodeArr) {
    if (item.properties[code] || item.properties[code] === 0) {
      areaCodeKey = code
    }
  }
  if (areaNameKey == '') {
    for (let name of areaNameArr) {
      if (item.properties[name]) {
        areaNameKey = name
      }
    }
  }
  /*if (areaCodeKey == '' || areaNameArr == '') {
    console.error(
      'MapAdminDiv.vue:未找到对应的areaCode或areaName，请检查item并加上对应的areaCode',
      item
    )
  }*/
  return { areaNameKey: areaNameKey, areaCodeKey: areaCodeKey }
}

//格式化轮廓feature的item
export function reData_featuresMarker(features) {
  let reData = []
  let reLocat = { 安阳市: [114.52, 36] }
  let areaCodeKey, areaNameKey
  if (features?.length) {
    for (let item of features) {
      let keyObj = setAreaKey(item)
      areaNameKey = keyObj.areaNameKey
      areaCodeKey = keyObj.areaCodeKey
      let center = ['', '']
      let areaName = item.properties[areaNameKey]
      if (reLocat[areaName]) {
        center = reLocat[areaName]
      } else if (item.properties?.center) {
        center = item.properties?.center
      }
      reData.push({
        id: center.join(','),
        areaCode: item.properties[areaCodeKey],
        name: item.properties[areaNameKey],
        lon: center[0],
        lat: center[1]
      })
    }
    return reData
  } else {
    return []
  }
}

//验证是否是无效值
export function verVoidValue(val) {
  let v = '-'
  if (val == -9999 || val == 9999) {
    return v
  }
  if (val >= 999990 || val <= -999990) {
    return v
  }
  return val
}

//组装流域feature的字典数据，key为流域code
export function getDict_featureData(list) {
  let dict = {}
  for (let item of list) {
    if (typeof dict[item.code] == 'undefined') {
      dict[item.code] = item
    }
  }
  return dict
}
