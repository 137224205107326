<template>
  <Swiper
    :modules="modules"
    :slides-per-view="30"
    slidesPerView="auto"
    :freeMode="true"
    :mousewheel="true"
    :scrollbar="{ draggable: true, el: '.price-swiper-scrollbar' }"
    direction="vertical"
    @reachEnd="reachEnd"
    @swiper="initSwiper"
    class="m-price-list"
  >
    <SwiperSlide
      v-for="(item, index) in list"
      :key="item"
      class="slide"
      @click="EventClick(index)"
    >
      <div class="dl" :class="currentIndex == index ? 'current' : ''">
        <dd class="row-1" :title="item.city">{{ item.city }}</dd>
        <dd class="row-2">{{ item.date?.Format(fmt) }}</dd>
        <dd class="row-3" :title="item.price">
          {{ parseInt(item.price) }}元/千克
        </dd>
      </div>
    </SwiperSlide>
  </Swiper>
  <div class="price-swiper-scrollbar"></div>
</template>
<script>
import { Swiper, SwiperSlide } from 'swiper/vue'
import { Scrollbar, FreeMode, Mousewheel } from 'swiper'
import 'swiper/css/scrollbar'
import 'swiper/css/mousewheel'
export default {
  log: '',
  emits: ['scrollEnd'],
  components: { Swiper, SwiperSlide },
  props: {
    head: {
      type: Array,
      default: () => []
    },
    list: {
      type: Array,
      default: () => []
    },
    currentIndex: {
      type: [String, Number],
      default: -1
    },
    fmt: {
      type: String,
      default: 'MM-DD'
    }
  },
  data() {
    return {
      modules: [Scrollbar, FreeMode, Mousewheel],
      swiper: false
    }
  },
  watch: {
    list: {
      handler() {
        this.$nextTick(() => {
          this.swiper.update()
        })
      }
    }
  },
  computed: {},
  mounted() {},
  methods: {
    initSwiper(ret) {
      this.swiper = ret
    },
    EventClick(index) {
      this.$emit('update:currentIndex', index)
      this.$emit('change', this.list[index])
    },
    reachEnd() {
      this.$emit('scrollEnd')
    }
  }
}
</script>
<style lang="scss" scoped>
.m-price-list {
  height: 100%;
  --swiper-scrollbar-right: -4px;
  .slide {
    height: auto;
    user-select: none;
  }
  .dl {
    display: flex;
    padding: 8px 0;
    align-items: center;
    border-bottom: 1px solid #153357;
  }
  dt {
    width: 10px;
    flex-grow: 1;
    font-size: 16px;
    text-align: center;
  }
  dd {
    text-align: center;
    word-break: keep-all;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .row-0 {
    position: relative;
    display: flex;
    align-items: center;
    padding-left: 20px;
    flex-shrink: 0;
    width: 70px;
    margin-right: 10px;
    &::before {
      position: absolute;
      top: 50%;
      left: 10px;
      content: '';
      width: 5px;
      height: 5px;
      margin-top: -2.5px;
      margin-right: 10px;
    }
    &:nth-child(even)::before {
      background: #32bebd;
    }
    &:nth-child(odd)::before {
      background: #019bfe;
    }
  }
  .row-1 {
    width: 10px;
    flex-grow: 1.5;
  }
  .row-2 {
    width: 10px;
    flex-grow: 0.7;
  }
  .row-3 {
    width: 10px;
    flex-grow: 1;
  }
}
.price-swiper-scrollbar {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 5px;
}
</style>
