import { createRouter, createWebHistory } from 'vue-router'
import VPageHome from '../views/pages/VPageHome.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    meta: {
      animate: true,
      title: '',
      element: 'prec',
      footerIndex: 0,
      keepAlive: true,
      routerIndex: 0
    },
    component: VPageHome
  },
  {
    path: '/home',
    name: 'home',
    meta: {
      animate: true,
      title: '',
      footerIndex: 1,
      keepAlive: true,
      routerIndex: 0
    },
    component: VPageHome
  },
  {
    path: '/homeCounty',
    name: 'homeCounty',
    meta: {
      animate: true,
      title: '',
      footerIndex: 1,
      keepAlive: true,
      routerIndex: 0
    },
    component: VPageHome
  },
  {
    path: '/garden',
    name: 'garden',
    meta: {
      animate: true,
      title: '',
      footerIndex: 3,
      keepAlive: true,
      routerIndex: 1
    },
    component: () => import('../views/pages/VPageGarden.vue')
  },
  {
    path: '/service',
    name: 'service',
    meta: {
      animate: true,
      title: '',
      footerIndex: 3,
      keepAlive: true,
      routerIndex: 1
    },
    component: () => import('../views/pages/VPagePdfRead.vue')
  },
  {
    path: '/view360',
    name: 'view360',
    meta: {
      animate: true,
      title: '全景预览',
      footerIndex: 4,
      keepAlive: true,
      routerIndex: 1
    },
    component: () => import('../views/pages/VPageView360.vue')
  },
  {
    path: '/test',
    name: 'test',
    meta: {
      animate: true,
      title: '全景预览',
      footerIndex: 4,
      keepAlive: true,
      routerIndex: 1
    },
    component: () => import('../views/pages/VTest.vue')
  },
  {
    path: '/:catchAll(.*)',
    name: 'home',
    meta: {
      animate: true,
      title: '',
      element: 'prec',
      footerIndex: 0,
      keepAlive: true,
      routerIndex: 0
    },
    component: VPageHome
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})
router.beforeEach((to, from, next) => {
  //beforeEach是router的钩子函数，在进入路由前执行
  //判断是否有标题
  if (to.meta.title) {
    document.title = to.meta.title
  }
  next() //执行进入路由，如果不写就不会进入目标页
})
export default router
