<template>
  <ul class="m-options-area">
    <li v-for="item in list" :key="item">
      <div class="box">
        <div
          class="name"
          :class="value == item.value ? 'current' : ''"
          @click="EventClick(item)"
        >
          {{ item.name }}
        </div>
      </div>
      <div :class="item.children?.length ? 'line' : ''"></div>
      <OptionAreas
        v-if="item.children?.length"
        :list="item.children"
        :value="value"
        @select="EventClick"
      ></OptionAreas>
    </li>
  </ul>
</template>
<script>
export default {
  name: 'OptionAreas',
  inject: [],
  components: {},
  props: {
    list: {
      type: Array,
      default: () => []
    },
    value: {
      type: String,
      default: ''
    }
  },
  data() {
    return {}
  },
  watch: {},
  computed: {},
  mounted() {},
  methods: {
    EventClick(item) {
      this.$emit('select', item)
    }
  }
}
</script>
<style lang="scss" scoped>
.m-options-area {
  display: flex;
  flex-wrap: wrap;
  padding: 10px 0;
  li {
    .box {
      display: flex;
      padding: 5px 0;
    }
    .name {
      padding: 7px 10px;
      margin-right: 10px;
      cursor: pointer;
      border: 1px solid rgba(255, 255, 255, 0.05);
      &.current {
        background: rgba(255, 255, 255, 0.1);
        color: #0ff;
      }
      &:hover {
        background: rgba(255, 255, 255, 0.1);
        color: #0ff;
      }
    }
    .line {
      margin-top: 10px;
      border-bottom: 1px solid rgba(255, 255, 255, 0.2);
    }
    &:last-child > .box > .name {
      margin-right: 0;
    }
  }
}
</style>
