<template>
  <LayoutMapTitle :title="title" :subTitle="subTitle"></LayoutMapTitle>
</template>
<script>
import LayoutMapTitle from '@/components/view/layout/LayoutMapTitle.vue'
export default {
  inject: ['MapRoot'],
  components: { LayoutMapTitle },
  props: {},
  data() {
    return {}
  },
  watch: {},
  computed: {
    title() {
      return this.MapRoot.mapImageIsogram.name
    },
    subTitle() {
      return this.MapRoot.mapImageIsogram.date?.Format('YYYY年MM月DD日')
    }
  },
  mounted() {},
  methods: {}
}
</script>
<style lang="scss" scoped></style>
