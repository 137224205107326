<template>
  <div class="cpn-timeline-group">
    <div class="column-3">
      <CTimeline
        :timeline="timelineList"
        v-model:index="modelTimelineIndex"
        :fmt="fmt"
        @change="EventChange_Timeline"
      ></CTimeline>
    </div>
    <div class="row-line" v-if="false"></div>
    <div class="column-1" v-if="false">
      <CTimelinePlay
        @go="EventGo_timeline"
        @onPlay="EventPlay_timeline"
        v-model:play="play"
      ></CTimelinePlay>
    </div>
  </div>
</template>
<script>
import CTimeline from './mapTimelineDay/CTimeline.vue'
import CTimelinePlay from './mapTimelineDay/CTimelinePlay.vue'
export default {
  components: {
    CTimeline,
    CTimelinePlay
  },
  props: {
    fmt: {
      type: String,
      default: ''
    },
    timelineList: {
      type: Array,
      default: () => [] //{date:''}
    },
    timelineIndex: {
      type: [Number, String],
      default: 0
    },
    allowPlay: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      modelTimelineIndex: 0,
      SelectTimeButton: {
        options: [
          { name: '3秒', value: 3 },
          { name: '2秒', value: 2 },
          { name: '1秒', value: 1 },
          { name: '0.5秒', value: 0.5 }
        ],
        value: 1
      },
      Picker: {
        type: 'date',
        startDate: '',
        endDate: ''
      },
      play: false
    }
  },
  watch: {
    timelineIndex: {
      handler(val) {
        this.modelTimelineIndex = val
      },
      immediate: true
    },
    allowPlay: {
      handler(val) {
        if (val) {
          this.EventPlay_timeline()
        } else {
          this.timeline_pause()
        }
      }
    }
  },
  computed: {},
  mounted() {
    this.modelTimelineIndex = this.timelineIndex
  },
  unmounted() {
    this.timeline_stop()
  },
  methods: {
    Emit_timelineIndex() {
      this.$emit('change', this.modelTimelineIndex)
      this.$emit('update:timelineIndex', this.modelTimelineIndex)
    },
    EventChange_Timeline() {
      //切换timeline
      this.Emit_timelineIndex()
      this.timeline_pause()
      this.EventPlay_timeline()
    },
    EventGo_timeline(ret) {
      //点击timeline
      this.modelTimelineIndex = this.modelTimelineIndex + ret
      if (this.modelTimelineIndex < 0) {
        this.modelTimelineIndex = this.timelineList.length - 1
      } else if (this.modelTimelineIndex > this.timelineList.length - 1) {
        this.modelTimelineIndex = 0
      }
      this.Emit_timelineIndex()
    },
    EventPlay_timeline() {
      if (this.play) {
        this.timeline_start()
      } else {
        this.timeline_stop()
      }
    },
    timeline_start() {
      this.TimeOut_Play = setTimeout(() => {
        if (this.allowPlay) {
          this.EventGo_timeline(1)
        }
        this.timeline_start()
      }, this.SelectTimeButton.value * 1000)
    },
    timeline_stop() {
      this.play = false
      this.timeline_pause()
    },
    timeline_pause() {
      clearTimeout(this.TimeOut_Play)
      clearTimeout(this.TimeOut_waitPlay)
    }
  }
}
</script>
<style scoped lang="scss">
.cpn-timeline-group {
  display: flex;
  .column-1 {
    flex-shrink: 0;
    display: flex;
  }
  .column-2 {
    flex-shrink: 0;
    display: flex;
    margin-left: 3px;
  }
  .row-line {
    height: 30px;
    margin-right: 15px;
    border-left: 1px solid rgba(255, 255, 255, 0.4);
  }
  .select-button {
    margin: 0 3px;
  }
}
</style>
