<template>
  <div
    class="m-header"
    v-if="AppRoot.name && !['view360', 'garden'].includes($route.name)"
  >
    <div class="m-logo">
      <div class="row-logo">
        <KOimg :src="imgLogo" />
      </div>
      <div class="row-text">
        <div class="name">
          {{ this.AppRoot.name }}
        </div>
        <SelectText
          v-if="['/', '/home'].includes($route.path)"
          :option="selectTextArea.options"
          v-model:value="selectTextArea.value"
          @change="EventChange_area"
        ></SelectText>
        <!--<div
          class="backHome"
          v-if="['/garden'].includes($route.path)"
          @click="EventBack"
        >
          返回首页
        </div>-->
      </div>
    </div>
    <ul class="m-nav" v-if="false">
      <li
        v-for="(item, index) in navs"
        :key="item.name"
        class="nav-li"
        :class="routerClass(item)"
        @click="EventClick_navLi(item, index)"
      >
        {{ item.name }}
      </li>
    </ul>
    <div class="m-login" v-if="false">
      <BoxLogin></BoxLogin>
    </div>
    <!--<div class="m-datetime" v-html="nowTime"></div>-->
  </div>
</template>

<script>
import SelectText from '@/components/content/select/SelectText.vue'
import BoxLogin from './VHead/BoxLogin.vue'
import { Info_areaTree } from '@/info/info.js'
export default {
  components: { BoxLogin, SelectText },
  inject: ['AppRoot', 'reload'],
  data() {
    return {
      nowTime: '',
      navs: [{ name: '实况预警', path: '/home' }],
      selectTextArea: {
        options: [],
        value: ''
      },
      imgLogo: require('@/assets/img/index/logo.png')
    }
  },
  computed: {},
  watch: {},
  created() {},
  mounted() {
    this.getData()
  },
  methods: {
    getData() {
      Info_areaTree().then((ret) => {
        if (ret?.data?.length) {
          this.selectTextArea.options = ret.data
          this.selectTextArea.value = this.$route.query.areaCode
            ? this.$route.query.areaCode
            : ret.data[0].value
        }
      })
    },
    routerClass(item) {
      if (item.path == '/home') {
        let navPaths = this.navs.map((e) => e.path)
        if (navPaths.indexOf(this.$route.path) <= 0) {
          return 'current'
        }
      } else {
        return this.$route.path == item.path ? 'current' : ''
      }
    },
    EventClick_navLi(item) {
      this.$router.push(item.path)
    },
    setTime() {
      this.nowTime = new Date().Format('YYYY年MM月DD日<br> hh:mm:ss')
      setTimeout(() => {
        this.setTime()
      }, 500)
    },
    EventChange_area(ret) {
      /*this.AppRoot.name = ret.name + '智慧农业气象云平台'
      document.title = this.AppRoot.name
      this.AppRoot.center = this.Api.jsonData(ret.remarks)
      this.AppRoot.areaName = ret.name
      this.AppRoot.areaCode = ret.value
      this.$router.push({
        path: this.$route.path,
        query: { areaCode: ret.value }
      })*/
      let url = this.Api.AppHost + '/homeCounty?areaCode=' + ret.value
      window.open(url)
    },
    EventBack() {
      if (this.$route.query?.from == 'homeCounty') {
        this.$router.push('/homeCounty')
      } else {
        this.$router.push('/')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.m-header {
  background: #061838 url('~@/assets/img/index/header-bg.png') center/100% 100%
    no-repeat;
  height: 83px;
  position: relative;
  &::before {
    content: '';
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    width: 200px;
    height: 70px;
    margin-bottom: -30px;
    background: url('@/assets/img/index/logo-z.png') center/100% 100% no-repeat;
  }
  .m-logo {
    position: absolute;
    left: 50%;
    transform: translate(-50%, 0px);
    top: 0;
    padding: 15px 0 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    .row-logo {
      .ko-img {
        height: 50px;
        width: 50px;
        margin-right: 15px;
      }
    }
    .row-text {
      display: flex;
      align-items: flex-end;
      .name {
        font-size: 32px;
        color: #fff;
        font-weight: bold;
        margin-right: 10px;
      }
      .backHome {
        cursor: pointer;
        &:hover {
          color: #0ff;
        }
      }
    }
  }
  .m-nav {
    position: absolute;
    left: 650px;
    top: 0;
    font-size: 22px;
    color: #d9e5fa;
    display: flex;
    li {
      height: 83px;
      width: 140px;
      margin-right: 10px;
      display: flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }
    li.current {
      color: #00ffff;
    }
  }
  .m-login {
    position: absolute;
    right: 30px;
    top: 25px;
  }
  .m-datetime {
    position: absolute;
    right: 30px;
    top: 25px;
    font-size: 14px;
    text-align: right;
  }
}
</style>
