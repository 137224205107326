<template>
  <el-image class="ko-img" v-bind="$attrs" :preview-teleported="true">
    <template #error>
      <div class="image-slot">
        <svg
          t="1657247953793"
          class="img-svg"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          p-id="38417"
        >
          <path
            d="M515.48928 642.61632l-233.4976-256.096L123.22432 689.3376l0 83.42912 788.6208 0c-100.43776-89.30816-312.25344-280.36992-312.25344-280.36992L515.48928 642.61632zM789.66144 449.87392c43.81056 0 79.29984-36.56192 79.29984-81.73056 0-45.12-35.48928-81.75232-79.29984-81.75232-43.6928 0-79.20384 36.63232-79.20384 81.75232C710.4576 413.312 745.96992 449.87392 789.66144 449.87392zM0 89.6l0 862.31168 1024 0L1024 89.6 0 89.6zM972.58496 897.08416 54.92352 897.08416l-0.04352-749.19808 860.3328 0 0 0.04864 57.37088 0L972.58368 897.08416z"
            p-id="38418"
            :fill="color"
          ></path>
        </svg>
      </div>
    </template>
    <template #placeholder>
      <div
        class="image-slot"
        v-loading="true"
        element-loading-background="rgba(0,0,0,0.4)"
      >
        <svg
          t="1657247953793"
          class="img-svg"
          viewBox="0 0 1024 1024"
          version="1.1"
          xmlns="http://www.w3.org/2000/svg"
          p-id="38417"
        >
          <path
            d="M515.48928 642.61632l-233.4976-256.096L123.22432 689.3376l0 83.42912 788.6208 0c-100.43776-89.30816-312.25344-280.36992-312.25344-280.36992L515.48928 642.61632zM789.66144 449.87392c43.81056 0 79.29984-36.56192 79.29984-81.73056 0-45.12-35.48928-81.75232-79.29984-81.75232-43.6928 0-79.20384 36.63232-79.20384 81.75232C710.4576 413.312 745.96992 449.87392 789.66144 449.87392zM0 89.6l0 862.31168 1024 0L1024 89.6 0 89.6zM972.58496 897.08416 54.92352 897.08416l-0.04352-749.19808 860.3328 0 0 0.04864 57.37088 0L972.58368 897.08416z"
            p-id="38418"
            :fill="color"
          ></path>
        </svg>
      </div>
    </template>
  </el-image>
</template>
<script>
export default {
  log: '',
  name: 'KOimg',
  components: {},
  props: {
    color: {
      type: String,
      default: 'rgba(255,255,255,.6)'
    }
  },
  data() {
    return {}
  },
  watch: {},
  computed: {},
  mounted() {},
  methods: {}
}
</script>
<style lang="scss" scoped>
.ko-img {
  display: block;
  ::v-deep(.el-image__wrapper) {
    .image-slot {
      height: 100%;
    }
    .el-loading-spinner {
      margin-top: 0;
      transform: translate(0, -50%);
      .path {
        stroke-linecap: round;
        stroke: rgba(255, 255, 255, 0.9);
      }
    }
    svg.img-svg {
      width: 100%;
      height: 100%;
      margin-left: auto;
      margin-right: auto;
      display: block;
    }
    font-size: 18px;
  }
}
</style>
