<template>
  <div
    class="index-body"
    v-loading="AppRoot.loading"
    element-loading-text="数据加载中..."
    element-loading-background="rgba(0, 0, 0, 0.5)"
  >
    <header class="index-header">
      <VHead></VHead>
    </header>
    <main class="index-main">
      <router-view v-slot="{ Component }" v-if="isShow">
        <component class="slide-view" :is="Component" />
      </router-view>
    </main>
    <footer class="index-footer" v-if="false">
      <VFoot></VFoot>
    </footer>
  </div>
</template>
<script>
import VHead from './index/VHead.vue'
import VFoot from './index/VFoot.vue'
export default {
  components: {
    VHead,
    VFoot
  },
  provide() {
    return {
      AppRoot: this.AppRoot,
      reload: this.reload
    }
  },
  data() {
    return {
      isShow: true,
      transitionName: '',
      AppRoot: {
        loading: false,
        name: '',
        areaName: '黔南州',
        areaCode: '',
        cropCode: '',
        cropName: '',
        varietiesId: '',
        center: []
      }
    }
  },
  watch: {
    $route: {
      deep: true,
      handler(to, from) {
        if (
          from &&
          to.meta.animate &&
          from.meta &&
          from.meta.footerIndex >= 0
        ) {
          if (to.meta.footerIndex > from.meta.footerIndex) {
            this.transitionName = 'slide-right'
          } else {
            this.transitionName = 'slide-left'
          }
        }
      }
    }
  },
  computed: {},
  created() {},
  mounted() {},
  methods: {
    reload() {
      this.isShow = false
      this.$nextTick(() => {
        this.isShow = true
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.m-nav {
  display: flex;
  li {
    height: 20px;
    font-size: 14px;
  }
}
.index-body {
  height: 100%;
  display: flex;
  flex-direction: column;
  .index-header {
    flex-shrink: 0;
  }
  .index-main {
    position: relative;
    flex-grow: 1;
    height: 10px;
    overflow: hidden;
  }
}
.index-header {
  position: relative;
  z-index: 10;
}
.index-main {
  z-index: 1;
}
.index-footer {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 2;
}
.slide-view {
  width: 100%;
  height: 100%;
}
.slide-right-enter-active,
.slide-right-leave-active {
  transition: all 0.4s;
  transform: translate3d(0, 0, 0);
}

.slide-right-enter-to {
  position: absolute;
  transform: translate3d(0, 0, 0);
}

.slide-right-enter-from {
  position: absolute;
  transform: translate3d(100%, 0, 0);
}

.slide-right-leave-to {
  position: absolute;
  transform: translate3d(-100%, 0, 0);
}

.slide-right-leave-from {
  position: absolute;
  transform: translate3d(0, 0, 0);
}

/**/
.slide-left-enter-active,
.slide-left-leave-active {
  transition: all 0.4s;
  transform: translate3d(0, 0, 0);
}

.slide-left-enter-to {
  position: absolute;
  transform: translate3d(0, 0, 0);
}

.slide-left-enter-from {
  position: absolute;
  transform: translate3d(-100%, 0, 0);
}

.slide-left-leave-to {
  position: absolute;
  transform: translate3d(100%, 0, 0);
}

.slide-left-leave-from {
  position: absolute;
  transform: translate3d(0, 0, 0);
}
</style>
