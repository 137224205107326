import reData_Date from './koapi/reData_Date.js' //数据重组-时间
import reData_Info from './koapi/reData_Info.js' //数据重组-数据
import elementPlug from './koapi/elementPlug.js' //element框架的扩展方法
import misc from './koapi/misc.js' //杂项
import func from './koapi/func.js' //功能
import project from './koapi/project.js' //项目
import { mix } from './func/mix.js' //混合类：用于把所有js中的类混入到KOApi中
class KOApi extends mix(
  reData_Date,
  reData_Info,
  elementPlug,
  misc,
  func,
  project
) {
  host = process.env.VUE_APP_GATEWAY_SERVICE
  weaHost = process.env.VUE_APP_GATEWAY_SERVICE
  AppHost =
    process.env.NODE_ENV === 'production'
      ? window.location.origin
      : window.location.origin
  areaCode = '520000'
  areaName = '贵州'
}
//+ '/' + process.env.VUE_APP_NAME
export default new KOApi()
