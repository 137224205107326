<template>
  <div ref="RMarkerPopup" class="cpn-marker-popup">
    <div
      class="popup-main"
      v-if="
        MapRoot.mapEvent.selectMultMarker &&
        MapRoot.mapEvent.markerType != 'locat' &&
        MapRoot.mapEvent.markerType
      "
      :class="getClass()"
    >
      <MPopupBase
        v-if="markerType == 'base'"
        :data="MapRoot.mapEvent.selectMultMarker"
      ></MPopupBase>
      <MPopupGardenBase
        v-if="markerType == 'gardenBase'"
        :data="MapRoot.mapEvent.selectMultMarker"
      ></MPopupGardenBase>
      <MPopupGardenBase
        v-if="['wuyuanSensor', 'farmlandSensor'].includes(markerType)"
        :data="MapRoot.mapEvent.selectMultMarker"
      ></MPopupGardenBase>
      <MPopupView360
        v-if="markerType == 'camera'"
        :data="MapRoot.mapEvent.selectMultMarker"
      ></MPopupView360>
    </div>
  </div>
</template>
<script>
import { Overlay } from 'ol'
import TypeIs from '@/api/TypeIs.js'
import MPopupBase from './markerPopup/MPopupBase.vue'
import MPopupGardenBase from './markerPopup/MPopupGardenBase.vue'
import MPopupView360 from './markerPopup/MPopupView360.vue'
export default {
  log: '',
  inject: ['AppRoot', 'MapRoot'],
  components: {
    MPopupBase,
    MPopupGardenBase,
    MPopupView360
  },
  props: {
    markerType: {
      type: String,
      default: ''
    }
  },
  data() {
    return {
      popupOverlay: false
    }
  },
  watch: {
    'MapRoot.mapEvent.selectMultMarker': {
      handler() {
        this.setPosition()
      }
    }
  },
  computed: {
    selectMultMarker() {
      return this.MapRoot.mapEvent.selectMultMarker
    }
  },
  mounted() {},
  methods: {
    init() {
      if (this.$refs?.RMarkerPopup && this.MapRoot?.mapPlug?.map) {
        if (this.popupOverlay == false) {
          this.popupOverlay = new Overlay({
            element: this.$refs.RMarkerPopup,
            positioning: 'bottom-center',
            stopEvent: true,
            autoPan: false, // 自动移动
            offset: [0, -10]
          })
          this.MapRoot.mapPlug.map.addOverlay(this.popupOverlay)
          let element = this.popupOverlay.getElement()
          element.addEventListener('click', (ret) => {
            let bool = ret?.target?.classList?.contains('j-btn-garden')
            if (bool) {
              let url =
                this.Api.AppHost +
                '/garden?id=' +
                this.selectMultMarker.id +
                '&cropCode=' +
                this.AppRoot.cropCode +
                '&varietiesId=' +
                this.AppRoot.varietiesId
              window.open(url)
            }
          })
        }
      }
    },
    /**
     * @param {Array,Object} coord //数组：[lon,lat],对象：{lon:100,lat:30}
     */
    setPosition() {
      if (this.popupOverlay) {
        let coord = []
        if (TypeIs.Array(this.selectMultMarker)) {
          coord = this.selectMultMarker
        } else if (TypeIs.Object(this.selectMultMarker)) {
          coord = [this.selectMultMarker.lon, this.selectMultMarker.lat]
        }
        let reCoord = this.MapRoot.mapPlug.fromLonLat(coord)
        this.popupOverlay.setPosition(reCoord)
      } else {
        this.init()
        this.setPosition()
      }
    },
    EventClose() {
      this.MapRoot.mapEvent.markerType = ''
    },
    getClass() {
      return this.selectMultMarker?.disable ? 'marker-disable' : ''
    }
  }
}
</script>
<style lang="scss" scoped>
.cpn-marker-popup {
  position: absolute;
  left: 50%;
  bottom: 30px;
  transform: translate(-50%, 0);
  .popup-main {
    border-radius: 3px;
    background: var(--ko-box-bg);
    box-shadow: 0 0 6px rgba(0, 255, 255, 0.5);
    &:after {
      content: '';
      display: block;
      position: absolute;
      left: 50%;
      top: 100%;
      margin-left: -8px;
      margin-top: -1px;
      border-width: 8px;
      display: block;
      width: 0;
      height: 0;
      border-style: solid;
      border-color: var(--ko-box-bg) transparent transparent transparent;
    }
  }
}
</style>
