<template>
  <div
    class="scroll-more-service-product"
    v-loading="loading"
    element-loading-text="数据加载中..."
    element-loading-background="rgba(0, 0, 0, 0.4)"
  >
    <NewsList
      v-if="news.list?.length"
      :list="news.list"
      v-model:currentIndex="news.currentIndex"
      @change="EventChange"
      @scrollEnd="EventScrollEnd"
    ></NewsList>
    <div v-else class="ui-no-data">暂无数据</div>
  </div>

  <el-dialog
    v-model="readDialog"
    class="dialog-pdf-read"
    :title="readName"
    top="20px"
    append-to-body
  >
    <template #default>
      <div class="dialog-content">
        <iframe
          v-if="fileType == 'pdf'"
          :src="readUrl"
          width="100%"
          height="100%"
          frameborder="0"
        ></iframe>
        <KOimg
          class="view-img"
          v-else-if="fileType == 'picture'"
          :src="readUrl"
          fit="contain"
        />
      </div>
    </template>
  </el-dialog>
</template>
<script>
import NewsList from '@/components/content/list/NewsList.vue'
import { Info_cropProductList } from '@/info/infoHome.js'
import { removeSameString } from '@/api/func/DataPro.js'
export default {
  inject: ['AppRoot', 'MapRoot'],
  components: { NewsList },
  props: {},
  emits: ['initEnd'],
  data() {
    return {
      loading: false,
      news: {
        list: [],
        currentIndex: '-1'
      },
      pageNo: 1,
      pageSize: 10,
      readDialog: false,
      readName: '',
      readUrl: '',
      fileType: '',
      productType: '',
      initEnd: false,
      params: {}
    }
  },
  watch: {},
  computed: {},
  mounted() {},
  methods: {
    init(ret) {
      //初始化插件 ScrollMore并加载
      this.params = ret
      if (this.params.type !== this.productType) {
        this.productType = this.params.type
        this.news.list = []
      }
      this.loading = true
      this.FnSM_getData().then((ret) => {
        this.loading = false
        this.EventSM_setList(ret)
      })
    },
    FnSM_getData() {
      //把事件传入scrollMore组件内
      return new Promise((resolve) => {
        this.MapRoot.mapImageIsogram = false
        //没有产品类别，则直接返回
        if (this.params.type == '') {
          return
        }
        Info_cropProductList({
          ...this.params,
          pageNo: this.pageNo,
          pageSize: this.pageSize
        })
          .then((ret) => {
            if (this.initEnd == false) {
              this.$emit('initEnd')
            }
            let list = []
            if (
              this.AppRoot.areaCode == this.params.areaCode &&
              this.AppRoot.varietiesId == this.params.varieties &&
              this.productType == this.params.type
            ) {
              //频繁请求的情况下，不在使用旧的请求数据
              if (ret.data?.list?.length) {
                for (let item of ret.data.list) {
                  if (item.fileType == 'img') {
                    let jsonImg = this.Api.jsonData(item.img)
                    if (jsonImg.imgUrl) {
                      let bounds = [
                        jsonImg.left,
                        jsonImg.bottom,
                        jsonImg.right,
                        jsonImg.top
                      ]
                      const title = removeSameString(item.title, item.cropName)
                      list.push({
                        ...item,
                        fileType: item.fileType,
                        name: title,
                        date: item.dateTime,
                        //imgBase64: img?.base64,
                        imgUrl: this.Api.host + jsonImg.imgUrl,
                        bounds: bounds,
                        legend: jsonImg.legendItem,
                        size: this.reData_size(jsonImg.size)
                      })
                    }
                  } else if (item.fileType == 'picture') {
                    if (item.picture) {
                      list.push({
                        ...item,
                        fileType: item.fileType,
                        name: this.Api.getSFileName(item.picture),
                        date: item.dateTime,
                        //imgBase64: img?.base64,
                        imgUrl: this.Api.host + item.picture
                      })
                    }
                  } else if (item.fileType == 'pdf') {
                    list.push({
                      fileType: item.fileType,
                      name: this.Api.getSFileName(item.pdf),
                      date: item.dateTime,
                      pdf: this.Api.host + item.pdf
                    })
                  } else {
                    console.error(
                      '意料之外的类型(目前只判断了pdf和img)：',
                      item
                    )
                  }
                }
              } else {
                list = []
              }
              resolve({ list: list, count: ret?.data?.count })
            } else {
              console.log('丢弃的请求', this.params)
            }
          })
          .catch((err) => {
            console.log(err)
            resolve({ list: [] })
          })
      })
    },
    reData_size(sizes) {
      let list = []
      for (let i in sizes) {
        list.push(sizes[i])
      }
      return list
    },
    EventSM_setList(ret) {
      this.news.list.push(...ret.list)
      this.news.currentIndex = -1
      for (let i in ret.list) {
        if (ret.list[i].fileType == 'img') {
          this.news.currentIndex = i
          this.EventChange(ret.list[i])
          break
        }
      }
      if (this.news.currentIndex == -1) {
        if (this.MapRoot.mapImageIsogram?.imgUrl) {
          this.MapRoot.mapImageIsogram.imgUrl = ''
          this.MapRoot.plantAreaChart = []
        }
      }
    },
    EventChange(item) {
      if (item.fileType == 'pdf') {
        this.readPdfProduct(item)
      } else if (item.fileType == 'picture') {
        this.readImgProduct(item)
      } else if (item.fileType == 'img') {
        this.MapRoot.mapImageIsogram = { ...item }
        this.MapRoot.plantAreaChart = item.size
      } else if (item == false) {
        if (this.MapRoot.mapImageIsogram?.imgUrl) {
          this.MapRoot.mapImageIsogram = false
          this.MapRoot.plantAreaChart = []
        }
      } else {
        console.error('意料之外的类型(目前只判断了pdf和img)：', item)
      }
    },
    readPdfProduct(item) {
      this.readName = item.name
      this.fileType = item.fileType
      this.readUrl =
        process.env.VUE_APP_GATEWAY_SERVICE + '/pdf/index.html?file=' + item.pdf
      this.readDialog = true
    },
    readImgProduct(item) {
      this.readName = item.name
      this.fileType = item.fileType
      this.readUrl = item.imgUrl
      this.readDialog = true
    },
    EventScrollEnd() {
      this.pageNo++
      this.loading = true
      this.FnSM_getData().then((ret) => {
        this.loading = false
        this.EventSM_setList(ret)
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.scroll-more-service-product {
  position: relative;
  height: 100%;
  padding-right: 20px;
  box-sizing: border-box;
}
.dialog-content {
  height: calc(100vh - 150px);
  .view-img {
    height: 100%;
  }
}
</style>
