import Store from '@/store'
class KOApi {
  /*
   *当前项目的处理
   */
  getKOStore() {
    return Store.state.info.KOStore
  }
  getAreaCode() {
    return this.getKOStore().GArea.value
  }
  getHostPath() {
    return process.env.VUE_APP_GATEWAY_SERVICE
  }
  getTFJSHost() {
    return process.env.VUE_APP_TFJS_SERVICE
  }
  getImgPath() {
    return process.env.VUE_APP_GATEWAY_SERVICE
  }
  getMGPath() {
    return process.env.VUE_APP_GATEWAY_SERVICE
  }
  imgPath(url) {
    if (url) {
      let reUrl = ''
      let hostUrl = process.env.VUE_APP_GATEWAY_SERVICE
      if (hostUrl) {
        if (
          hostUrl.charAt(hostUrl.length - 1) == '/' &&
          url?.charAt(0) == '/'
        ) {
          reUrl = url.substr(1)
        } else {
          reUrl = url
        }
      } else {
        console.error('没有定义.env.development的VUE_APP_IMG_SERVICE')
      }
      return hostUrl + reUrl
    } else {
      console.error('注意：图片路径为空：', url)
      return ''
    }
  }
  getPdf(ret) {
    let url = ret?.replace(/(.doc$)|(.docx$)/g, '.pdf')
    return this.getImgPath() + url
  }
  openOffice(productId) {
    let link = document.createElement('a') // 创建a标签
    link.style.display = 'none'
    const hostPath = this.getHostPath()
    link.href = `javascript:POBrowser.openWindowModeless('${hostPath}/api/office/editWord?officeId=${productId}&authToken=${localStorage.getItem(
      'authToken'
    )}','width=1200px;height=800px;')` // 设置下载地址
    document.body.appendChild(link)
    link.click()
  }
}
export default KOApi
