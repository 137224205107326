<template>
  <div class="m-footer">技术支持：北京华泰德丰技术有限公司</div>
</template>

<script>
export default {}
</script>

<style lang="scss" scoped>
.m-footer {
  text-align: center;
  font-size: 14px;
  padding: 10px 0;
}
</style>
