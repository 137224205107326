<template>
  <V3DrawerView title="设置" v-bind="$attrs">
    <div class="m-dl-items drawer-main">
      <dl v-if="false">
        <dt class="label">默认区域</dt>
        <dd>
          <SelectArea @change="EventChange_area"></SelectArea>
        </dd>
      </dl>
      <dl v-if="false">
        <dt class="label">模式选择</dt>
        <dd>
          <el-switch
            v-model="SwitchModel"
            active-color="#13ce66"
            inactive-color="#ff4949"
            active-text="完整"
            inactive-text="简洁"
            @change="EventChange_screen"
          />
        </dd>
      </dl>
      <dl v-if="false">
        <dt class="label">轮播设置</dt>
        <dd>
          <el-switch
            v-model="SwitchPoint"
            active-color="#13ce66"
            inactive-color="#ff4949"
            active-text="播放"
            inactive-text="停止"
            @change="EventChange_point"
          />
        </dd>
      </dl>
      <dl>
        <dt class="label">中心点(默认)</dt>
        <dd>
          <div class="m-coord">
            <el-input
              size="default"
              v-model="lon"
              @change="EventChange_coord"
              label="label"
              placeholder="经度"
            ></el-input>
            <el-input
              size="default"
              v-model="lat"
              @change="EventChange_coord"
              label="label"
              placeholder="纬度"
            ></el-input>
          </div>
        </dd>
      </dl>
      <dl v-if="address">
        <dd>
          <div class="m-address">
            <i class="icon icon-dingwei1 iconfont"></i>{{ address }}
          </div>
        </dd>
      </dl>
      <dl>
        <dt class="label">缩放(默认)</dt>
        <dd>
          <div class="m-zoom">
            <el-input-number
              size="default"
              v-model="zoom"
              @change="EventChange_zoom"
              label="label"
              :min="1"
              :max="18"
              placeholder="经度"
            ></el-input-number>
          </div>
        </dd>
      </dl>
    </div>
  </V3DrawerView>
</template>
<script>
import SelectArea from '@/components/content/select/SelectArea.vue'
import V3DrawerView from '@/components/content/drawer/V3DrawerView.vue'
export default {
  log: '',
  components: { SelectArea, V3DrawerView },
  inject: ['MapRoot'],
  props: {},
  data() {
    return {
      SwitchModel: true,
      SwitchPoint: true,
      lon: '',
      lat: '',
      address: '',
      zoom: 1
    }
  },
  watch: {
    mapZoom: {
      handler(val) {
        this.zoom = val
      },
      immediate: true
    },
    mapCenter: {
      handler(val) {
        this.lon = val[0]
        this.lat = val[1]
      },
      immediate: true
    }
  },
  computed: {
    KOStore() {
      return this.$store.state.info.KOStore
    },
    mapCenter() {
      return this.KOStore.mapCenter
    },
    mapZoom() {
      return this.KOStore.mapZoom
    }
  },
  mounted() {},
  methods: {
    EventChange_area() {},
    EventChange_screen() {},
    EventChange_point() {},
    EventChange_coord() {
      this.$store.commit('info/saveStore', {
        key: 'mapCenter',
        value: [this.lon, this.lat]
      })
    },
    EventChange_zoom(zoom) {
      this.$store.commit('info/saveStore', {
        key: 'mapZoom',
        value: zoom
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.drawer-main {
  padding: 0 20px;
}
.m-dl-items {
  font-size: 14px;
  dl {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    dt.label {
      flex-shrink: 0;
      margin-right: 20px;
    }
    dd {
      flex-grow: 1;
      text-align: right;
    }
  }
  .line {
    padding-top: 0;
    margin-bottom: 20px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  }
}
.m-address {
  padding: 6px;
  background: rgba(16, 23, 40, 0.5);
  border: 1px solid rgba(200, 200, 200, 0.5);
  color: #bbb;
  border-radius: 3px;
  text-align: justify;
}
.m-coord {
  display: flex;
  width: 250px;
  margin-left: auto;
  margin-right: 0;
  .el-input {
    margin-left: 10px;
  }
}
</style>
