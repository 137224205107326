import _ from 'lodash'
import '@/api/protoFn/Color.js'
import VectorLayer from 'ol/layer/Vector'
import VectorSource from 'ol/source/Vector'
import Collection from 'ol/Collection.js'
import GeoJSON from 'ol/format/GeoJSON'
import { Group as LayerGroup } from 'ol/layer'
import { Fill, Stroke, Style } from 'ol/style'
import Select from 'ol/interaction/Select'
import { click } from 'ol/events/condition'
import OMU from '@/plug/map/openLayer/utils.js'
class KOClass {
  mapPlug
  layerId = 'featurePolygon'
  data = {
    features: [
      {
        geometry: { coordinates: [], type: 'Polygon' },
        properties: { name: 'EPSG:3857' },
        type: 'Feature'
      }
    ],
    type: 'FeatureCollection'
  }
  defaultStyle = {
    stroke: new Stroke({
      color: 'rgba(22, 127, 230, 1)',
      width: 1
    }),
    fill: new Fill({
      color: 'rgba(22, 127, 230, 0.2)'
    })
  }
  selectStyle = {
    stroke: new Stroke({
      color: 'rgba(200, 240, 255, 0.8)',
      width: 1
    }),
    fill: new Fill({
      color: 'rgba(0, 146, 229, 0.1)'
    })
  }
  zIndex = 20
  callback = false
  constructor(config) {
    _.merge(this, config)
    this.init()
  }
  init() {
    this.initData()
    this.addLayerPolygon(this.data)
    this.initEvent()
  }
  update(ret) {
    let coll = this.source.getFeaturesCollection()
    coll.clear()
    if (ret.data?.features?.length) {
      let features = this.getFeaturesByGeoJson(ret.data)
      if (features?.length) {
        coll.extend(features)
      }
    }
  }
  initData() {
    this.polygonSelected = [new Style(this.selectStyle)]
    this.polygonDefault = [new Style(this.defaultStyle)]
  }
  initEvent() {
    if (this.callback) {
      const selectClick = new Select({
        condition: click,
        layers: (ret) => {
          let proper = ret.getProperties()
          if (proper.layerId && proper.layerId == this.layerId) {
            return true
          }
        },
        style: (feature) => {
          if (feature.selectEvent) {
            return feature.selectStyle
          } else {
            return feature.defaultStyle
          }
        }
      })
      selectClick.on('select', (ret) => {
        this.callback.call(this, ret.selected[0])
      })
      this.mapPlug.map.addInteraction(selectClick)
    }
  }
  /**
   * 事件：移上多边形变更多边形的颜色
   */
  initMouseHoverEvent() {
    this.selectHover = new Select({
      condition: (ret) => {
        if (ret.type == 'pointermove') {
          return true
        }
      },
      layers: (ret) => {
        let proper = ret.getProperties()
        if (proper.layerId && proper.layerId == this.layerId) {
          return true
        }
      },
      style: (feature) => {
        if (feature.selectEvent) {
          return feature.selectStyle
        } else {
          return feature.defaultStyle
        }
      }
    })
    /*
    使用此方法捕获事件对应的feature
    this.selectClick.on('select', (feature) => {
      console.log(feature)
    })*/
    this.mapPlug.map.addInteraction(this.selectHover)
  }
  getFeaturesByGeoJson(ret) {
    let geoJson
    if (this.mapPlug.projection == 'EPSG:4326') {
      //如果地图是国标，则不转换直接输出
      geoJson = OMU.getGeojson_4326(ret)
    } else {
      //如果地图是摩卡托，则需要把国标转换为摩卡托再输出
      geoJson = OMU.getGeojson_3857(ret)
    }
    return this.getGeoFeatures(geoJson)
  }
  addLayerPolygon(geoJson) {
    let feats = this.getFeaturesByGeoJson(geoJson)
    this.source = new VectorSource({
      features: new Collection(feats)
    })
    this.layer = new VectorLayer({
      properties: { layerId: this.layerId },
      source: this.source,
      style: this.polygonDefault,
      zIndex: this.zIndex
    })
    this.layerGroup = new LayerGroup({
      layers: [this.layer],
      zIndex: this.zIndex
    })
    this.mapPlug.map.addLayer(this.layerGroup)
  }
  getGeoFeatures(jsonObj) {
    let feats = new GeoJSON().readFeatures(jsonObj)
    for (let i = 0; i < feats.length; i++) {
      feats[i].index = i
      if (this.callback) {
        feats[i].selectEvent = true
      }
      feats[i].defaultStyle = this.polygonDefault
      feats[i].selectStyle = this.polygonSelected
      if (jsonObj.features[i].properties) {
        feats[i].data = jsonObj.features[i].properties
      }
      feats[i].featureType = this.layerId
    }
    return feats
  }
  remove(layerGroup) {
    if (layerGroup) {
      let layers = layerGroup.getLayers()
      if (layers) {
        layers.clear()
      }
    }
  }
}

export default KOClass
