<template>
  <div
    class="box-crop-info"
    v-loading="loading"
    element-loading-background="rgba(0, 0, 0, 0.6)"
    element-loading-text="数据加载中..."
  >
    <LayoutBoxTitle title="当前生育期服务方案"></LayoutBoxTitle>
    <div class="inner-box">
      <div class="crop-info" v-if="img || name || cont">
        <div class="row-img" v-if="img"><KOimg :src="img" fit="contain" /></div>
        <div class="box">
          <div class="name">{{ name }}</div>
          <div class="cont">{{ cont }}</div>
        </div>
      </div>
      <div class="suit-wrap">
        <div class="suit-content" v-if="cropContent" :title="cropContent">
          <div class="cont">{{ cropContent }}</div>
          <div class="date">发布时间：{{ cropDateTime }}</div>
        </div>
        <div v-else class="ui-no-data">{{ msg }}</div>
      </div>
    </div>
  </div>
</template>
<script>
import LayoutBoxTitle from '@/components/view/layout/LayoutBoxTitle.vue'
import { InterCropGrowth } from '@/inter/InterCropGrowth.js'
export default {
  inject: ['AppRoot', 'MapRoot'],
  components: { LayoutBoxTitle },
  props: {},
  data() {
    return {
      loading: false,
      name: '',
      img: '',
      cont: '',
      cropContent: '',
      cropDateTime: '',
      msg: '暂无数据'
    }
  },
  watch: {
    'MapRoot.mapEvent.selectLocat': {
      handler() {
        this.getData_cropGrowth()
      }
    }
  },
  computed: {},
  mounted() {},
  methods: {
    getData_cropGrowth() {
      if (this.MapRoot.mapEvent.selectLocat?.lon) {
        this.loading = true
        InterCropGrowth(
          this.AppRoot.areaCode,
          this.AppRoot.varietiesId,
          this.MapRoot.mapEvent.selectLocat?.lon,
          this.MapRoot.mapEvent.selectLocat?.lat
        )
          .then((ret) => {
            this.loading = false
            if (this.AppRoot.varietiesId !== ret.KOReq.params.varieties) {
              return
            }
            this.cropDateTime = ''
            this.name = ''
            this.cont = ''
            this.img = ''
            this.cropContent = ''
            if (ret.success) {
              this.name = ret.data.cropName
              this.cont =
                ret.data.growth.name +
                '（生长日期：' +
                ret.data.growth.start +
                '至' +
                ret.data.growth.end +
                '）'
              this.cropContent = ret.data.measuresAnniversary[0]?.measures ?? ''
              this.cropDateTime =
                ret.data.measuresAnniversary[0]?.updateDate?.Format(
                  'MM-DD hh:mm'
                ) ?? new Date().Format('MM-DD 09:00')
              this.img = this.Api.host + ret.data.images
            } else {
              this.msg = ret?.msg ?? '暂无数据'
            }
          })
          .catch((err) => {
            console.log(err)
            this.loading = false
          })
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.box-crop-info {
  padding-top: 10px;
  background: var(--ko-body-bg);
  .inner-box {
    padding: 10px 15px;
  }

  .crop-info {
    display: flex;
    align-items: center;
    padding: 10px 0;
    .row-img {
      width: 65px;
      margin-right: 15px;
      .ko-img {
        height: 55px;
        margin-left: auto;
        margin-right: auto;
      }
    }
    .box {
      .name {
        font-size: 18px;
        color: #0ff;
        margin-bottom: 10px;
      }
      .cont {
        color: #0ff;
      }
    }
  }
  .suit-wrap {
    padding: 10px 10px 10px 15px;
    background: rgba(0, 0, 0, 0.2);
  }
  .suit-content {
    line-height: 1.8;
    box-sizing: border-box;

    .cont {
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    .date {
      text-align: right;
      color: rgba(255, 255, 255, 0.6);
    }
  }
}
</style>
