import MapMultMarker from '@/plug/map/openLayer/marker/MapMultMarker.js'
import { getMarkerLegend, getMarkerList } from './HooksMapConfig.js'
/**
 * 请求接口绘制地图轮廓
 * @param {Object} mapPlug //地图plug
 * @returns
 */

/**
 * 在首页地图上绘制marker点
 * @param {Object} mapPlug //地图mapPlug
 */
export function HooksSetMapLocatMarker(mapPlug, ret) {
  let markerType = 'locat'
  if (mapPlug?.map) {
    let locatMarker = [
      {
        lon: ret.lon,
        lat: ret.lat,
        markerType: markerType,
        name: '',
        icon: require('@/assets/img/icon/icon-city-station.png')
      }
    ]
    let markerLegend = getMarkerLegend(locatMarker) //获取点列表的图例
    let locatMarkerList = getMarkerList({
      list: locatMarker,
      type: markerType
    })
    let options = {
      mapPlug: mapPlug,
      markerLegend: markerLegend,
      markerList: locatMarkerList,
      viewMarkerType: [markerType]
    }
    if (mapPlug.LocatMarker) {
      mapPlug.LocatMarker.update(options)
    } else {
      mapPlug.LocatMarker = new MapMultMarker(options)
    }
  }
}
