// service统一出口
import router from '@/router'
import KORequest from './request'
import { BASE_URL, TIME_OUT } from './request/config'

import cookieCache from '@/utils/cookie.js'
let interceptors = {
  requestInterceptor: (config) => {
    // 携带token的拦截
    const token = cookieCache.get('token')
    if (token) {
      config.headers.token = token
    }
    return config
  },
  requestInterceptorCatch: (err) => {
    //console.log('请求失败的拦截')
    return err
  },
  responseInterceptor: (res) => {
    //console.log('响应成功的拦截', res)
    return res
  },
  responseInterceptorCatch: (err) => {
    //响应失败的拦截
    //console.log(err)
    if (
      [401, 402].includes(err?.response?.data?.code) ||
      [401, 402].includes(err?.response?.status)
    ) {
      cookieCache.delete('token')
      router.push('login')
      return { msg: '请先登录' }
    }
    if (err.response) {
      return err.response
    } else if (err.msg || err.message) {
      let msg = err.msg || err.message
      if (msg.indexOf('timeout') >= 0) {
        msg = '请求超时，请检查网络是否正常。'
      }
      return { ...err, msg: msg, code: 'timeout' }
    } else {
      return { msg: '请求超时，服务器无响应。', success: false }
    }
  }
}
let reqObj = {
  baseURL: BASE_URL,
  timeout: TIME_OUT
}

const KOPost = (ret) => {
  let KOReq
  if (ret.load == 'newUrl') {
    KOReq = new KORequest({
      timeout: TIME_OUT,
      baseURL: ret.baseURL,
      interceptors: ret?.ic ?? interceptors
    })
  } else {
    KOReq = new KORequest({ ...reqObj, interceptors: interceptors })
  }
  return KOReq.request(ret)
}
export default KOPost
