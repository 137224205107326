import store from '@/store'
import TypeIs from '@/api/TypeIs.js'
class KOApi {
  setTemp(key, value) {
    store.commit('home/saveTemp', {
      key: key,
      value: value
    })
  }
  encode(t) {
    if (t) {
      return encodeURI(t)
    } else {
      return t
    }
  }
  decode(t) {
    if (t) {
      return decodeURI(t)
    } else {
      return t
    }
  }
  obj2para(json, slice = '&') {
    return Object.keys(json)
      .reduce((acc, item) => {
        return String(acc) + item + '=' + json[item] + slice
      }, '')
      .slice(0, -1)
  }
  isNumber(str) {
    if (!isNaN(parseFloat(str))) {
      return true
    } else {
      return false
    }
  }
  base64(url) {
    if (url) {
      return 'data:image/png;base64,' + url
    } else {
      console.error('注意：图片路径为空：', url)
      return ''
    }
  }
  getBase64File(fileBase64) {
    let fileHeader = new Map()
    //获取不同文件的文件头前3个字作为判断依据
    fileHeader.set('/9j', 'JPG')
    fileHeader.set('iVB', 'PNG')
    fileHeader.set('Qk0', 'BMP')
    fileHeader.set('SUk', 'TIFF')
    fileHeader.set('JVB', 'PDF')
    fileHeader.set('UEs', 'OFD')
    let res = ''
    //遍历map中所提及的文件头特征
    fileHeader.forEach((v, k) => {
      if (k == fileBase64.substr(0, 3)) {
        res = v
      }
    })
    //如果不在map中返回unknown file
    if (res == '') {
      res = false
    }
    //否则返回map中的value值
    return res
  }
  /**
   * 保留2位小数
   * @param {String或Number} input //字符或数字
   * @param {Number} decimalPlaces //保留几位小数
   * @param {Boolean} shouldRound //是否需要四舍五入
   * @returns
   */
  keep2(input, decimalPlaces = 2, shouldRound) {
    if (typeof decimalPlaces !== 'number' || decimalPlaces < 0) {
      //console.error('参数2：小数位数无效')
      return input
    }
    const number = parseFloat(input)
    if (isNaN(number)) {
      //console.error('参数1：请传入正确的数字')
      return input
    }
    let roundedNumber
    if (shouldRound) {
      roundedNumber = parseFloat(number.toFixed(decimalPlaces))
    } else {
      const factor = 10 ** decimalPlaces
      roundedNumber = Math.floor(number * factor) / factor
    }
    return roundedNumber
  }
  removeZero(ret) {
    var areaCode
    if (ret && TypeIs.Number(ret)) {
      areaCode = ret.toString()
      return areaCode.replace(/(00$)|(0000$)/g, '')
    } else if (TypeIs.String(ret)) {
      areaCode = ret
      return areaCode.replace(/(00$)|(0000$)/g, '')
    } else {
      console.log('areaCode未定义或不是有效行政编码格式:', areaCode)
      return ret
    }
  }
  removeArrItem(arr, val) {
    //按值来删除arr对应的对象
    var index = arr.indexOf(val)
    if (index > -1) {
      arr.splice(index, 1)
    }
  }
  clearStyle(str) {
    //清除html标签的style
    return str.replace(/style\s*?=\s*?(['"])[\s\S]*?\1/g, '')
  }
  clearHtml(str) {
    //清除html标签和注释
    if (str) {
      str = this.escape2Html(str)
      return str
        .replace(/<\/?[^>]*>/g, '')
        .replace(/[ | ]*\n/g, '\n')
        .replace(/ /gi, '')
    } else {
      return ''
    }
  }
  jsonData(ret) {
    if (ret) {
      if (TypeIs.String(ret)) {
        if (this.isJSON(ret)) {
          return new Function('return ' + ret)()
        } else {
          return ret
        }
      }
    }
    return ret
  }
  randomString(len) {
    let rdmString = ''
    for (
      ;
      rdmString.length < len;
      rdmString += Math.random().toString(36).substring(2).toUpperCase()
    );
    return rdmString.substring(0, len)
  }
  isJSON(str) {
    if (TypeIs.Object(str) || TypeIs.Array(str)) {
      return true
    } else {
      let obj = null
      try {
        obj = eval('(' + str + ')')
      } catch (e) {
        return false
      }
      if (TypeIs.Object(obj) || TypeIs.Array(obj)) {
        return true
      } else {
        return false
      }
    }
  }
  sortASCII(obj, isSort = true) {
    let arr = []
    Object.keys(obj).forEach((item) => arr.push(item))
    let sortArr = isSort ? arr.sort() : arr.sort().reverse()
    let sortStr = ''
    for (let i = 0; i < sortArr.length; i++) {
      if (i == 0) {
        sortStr += sortArr[i] + '=' + obj[sortArr[i]]
      } else {
        sortStr += '&' + sortArr[i] + '=' + obj[sortArr[i]]
      }
    }
    return sortStr
  }
  colors() {
    return [
      '#ff0000',
      '#FFA200',
      '#00C9AA',
      '#0090FF',
      '#ddaa00',
      '#001eff',
      '#7800ff',
      '#f600ff',
      '#875876'
    ]
  }
  elementDict(key) {
    let dict = {
      prec: {
        name: '强降水',
        unit: 'mm'
      }
    }
    if (dict[key]) {
      return dict[key]
    } else {
      console.error(
        '没有找到element为：' + key + '对应的要素信息，将返回空字符对象'
      )
      return { name: '', unit: '' }
    }
  }
}
export default KOApi
