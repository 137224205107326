<template>
  <div class="box-price-list">
    <LayoutBoxTitle>
      <ul class="m-price-tab-label">
        <li
          :class="index == currentIndex ? 'current' : ''"
          v-for="(item, index) in tabs"
          :key="item"
          @click="EventChange(item, index)"
        >
          {{ item.name }}
        </li>
      </ul>
    </LayoutBoxTitle>
    <div class="m-price-content">
      <dl class="head">
        <dt :class="'dt-' + index" v-for="(item, index) in head" :key="item">
          {{ item }}
        </dt>
      </dl>
      <ScrollMorePriceList
        v-if="currentIndex == 0"
        class="content"
      ></ScrollMorePriceList>
      <div v-else class="ui-no-data">暂无数据</div>
    </div>
  </div>
</template>
<script>
import LayoutBoxTitle from '@/components/view/layout/LayoutBoxTitle.vue'
import ScrollMorePriceList from './boxPriceList/ScrollMorePriceList.vue'

export default {
  inject: [],
  components: { LayoutBoxTitle, ScrollMorePriceList },
  props: {},
  data() {
    return {
      tabs: [{ name: '价格' }, { name: '供应' }, { name: '求购' }],
      currentIndex: 0,
      head: ['市场', '日期', '价格']
    }
  },
  watch: {},
  computed: {},
  mounted() {},
  methods: {
    EventChange(item, index) {
      this.currentIndex = index
    }
  }
}
</script>
<style lang="scss" scoped>
.box-price-list {
  padding: 10px 0 0 0;
  background: var(--ko-body-bg);
  height: 100%;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  .m-price-tab-label {
    display: flex;
    li {
      padding-right: 10px;
      margin-right: 10px;
      border-right: 1px solid #5c7494;
      color: #819bc0;
      font-size: 16px;
      cursor: pointer;
      &.current {
        color: #fff;
      }
      &:last-child {
        border-right: 0 solid #000;
      }
    }
  }
  .m-price-content {
    padding: 10px;
    height: 10px;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    dl.head {
      display: flex;
      padding: 10px 0;
      margin-bottom: 10px;
      align-items: center;
      flex-shrink: 0;
      background: #0c264b;
    }
    dt {
      width: 10px;
      flex-grow: 1;
      font-size: 16px;
      text-align: center;
      &.dt-0 {
        flex-grow: 1.6;
      }
    }
    .content {
      height: 10px;
      flex-grow: 1;
    }
  }
}
</style>
