import _ from 'lodash'
import VectorSource from 'ol/source/Vector'
import Collection from 'ol/Collection.js'
import { Group as LayerGroup } from 'ol/layer'
import Feature from 'ol/Feature'
import { Vector as VectorLayer } from 'ol/layer.js'
import { Icon, Style, Fill, Stroke, Text, Circle } from 'ol/style.js'
import Point from 'ol/geom/Point'
import TypeIs from '@/api/TypeIs.js'
/**
 * 使用feature点的方式绘制点到地图上-用于绘制多边形的名称
 */
class KOClass {
  mapPlug
  layerId = 'featureName'
  labels = []
  fontSize = 12
  vectorLayer = {}
  textStyle = 'light'
  zIndex = 30
  /**
   * @param {Object} config.mapPlug //地图组件
   * @param {Array} config.labels //label点对象集合,必有字段：lon,lat,name
   */
  constructor(config) {
    _.merge(this, config)
    this.init()
  }
  init() {
    if (this.mapPlug?.map) {
      this.vectorLayer = this.getVectorLayer()
      this.addLayer()
    }
  }
  getIcon(ret) {
    if (TypeIs.String(ret)) {
      if (ret.length > 30) {
        return new Icon({
          crossOrigin: 'anonymous',
          src: ret,
          imgSize: this.markerSize
        })
      } else {
        return new Circle({
          radius: 5,
          fill: new Fill({
            //圆填充色
            color: ret
          })
        })
      }
    } else {
      console.error('地图marker传入的icon不是颜色也不是图片路径')
    }
  }
  getStyle(item) {
    return new Style({
      text: new Text({
        font: this.fontSize + 'px sans-serif',
        text: item.text,
        textAlign: 'center',
        justify: 'center',
        offsetY: 0,
        fill: new Fill({
          color: this.textStyle == 'light' ? '#0ff' : '#000'
        }),
        stroke: new Stroke({
          color: this.textStyle == 'light' ? '#000' : '#FFF',
          width: 2
        })
      })
    })
  }
  getVectorLayer() {
    let feats = this.getFeatures(this.labels)
    this.source = new VectorSource({
      features: new Collection(feats)
    })
    return new VectorLayer({
      source: this.source,
      zIndex: this.zIndex
    })
  }
  getFeatures(labels) {
    let features = []
    for (let item of labels) {
      if (item.lon && item.lat) {
        let feature = new Feature({
          geometry: new Point(this.mapPlug.fromLonLat([item.lon, item.lat]))
        })
        feature.text = item.name || item.Name || item.areaName || item.AreaName
        feature.data = item
        feature.featureType = this.layerId
        feature.setStyle(this.getStyle(feature))
        features.push(feature)
      }
    }
    return features
  }
  addLayer() {
    let groupLayer = [this.vectorLayer]
    if (this.layerGroup) {
      this.remove(this.layerGroup)
    }
    this.layerGroup = new LayerGroup({
      layers: groupLayer,
      zIndex: 59
    })
    this.mapPlug.map?.addLayer(this.layerGroup)
  }
  update(config) {
    let coll = this.source.getFeaturesCollection()
    coll.clear()
    if (config.labels?.length) {
      let features = this.getFeatures(config.labels)
      if (features?.length) {
        coll.extend(features)
      }
    }
  }
  remove(layerGroup) {
    if (layerGroup) {
      let layers = layerGroup.getLayers()
      if (layers) {
        layers.clear()
      }
    }
  }
}

export default KOClass
